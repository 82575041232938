import "./styles/weekly-supply.css";
import { IconContext } from "react-icons";
import {
  MdOutlineKeyboardArrowUp,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";
import { ImAttachment } from "react-icons/im";
import whatsAppLogo from "../images/whatsAppLogo.png";
import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
const WeeklySupply = () => {
  const mobile = useMediaQuery({ maxWidth: 800 });
  const [modalOpen, setModalOpen] = useState(false);
  const [checkedIngredients, setCheckedIngredients] = useState([]);
  const [weeklySupplies, setWeeklySupplies] = useState([]);
  const [
    weeklyCalenderContainPersonalRecipe,
    setWeeklyCalenderContainPersonalRecipe,
  ] = useState(false);
  const [
    weeklyCalenderContainsGeneralRecipe,
    setWeeklyCalenderContainsGeneralRecipe,
  ] = useState(false);
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  let counter = 1;
  const weeklySuppliesRedux = useSelector(
    (state) => state.mainSlice.weeklySupplies
  );
  const weeklyRecipesRedux = useSelector(
    (state) => state.mainSlice.weeklyRecipes
  );
  useEffect(() => {
    let weeklyRecipesContainPersonalRecipe = false;
    let weeklyRecipesContainGeneralRecipe = false;
    if (weeklySuppliesRedux?.length) {
      // Check if the weekly recipes contain a personal recipe
      weeklyRecipesRedux.forEach((recipe) => {
        if (recipe.type === "personal") {
          weeklyRecipesContainPersonalRecipe = true;
        } else {
          weeklyRecipesContainGeneralRecipe = true;
        }
      });

      // Remove duplicates from weekly supplies
      const updated = weeklySuppliesRedux.reduce((accumulator, supply) => {
        const existingIndex = accumulator.findIndex(
          (item) => item.id === supply.id
        );
        if (existingIndex === -1) {
          accumulator.push({
            id: supply.id,
            ingredient:
              i18n.language === "he" ? supply.name_in_hebrew : supply.name,
          });
        }
        return accumulator;
      }, []);
      setWeeklySupplies(updated);
    } else {
      setWeeklySupplies([]);
    }

    setWeeklyCalenderContainPersonalRecipe(weeklyRecipesContainPersonalRecipe);
    setWeeklyCalenderContainsGeneralRecipe(weeklyRecipesContainGeneralRecipe);
  }, [weeklySuppliesRedux, weeklyRecipesRedux]);

  const handleCheckboxChange = useCallback((ingredientId) => {
    setCheckedIngredients((prevCheckedIngredients) => {
      if (prevCheckedIngredients.includes(ingredientId)) {
        // If already checked, remove it
        return prevCheckedIngredients.filter((id) => id !== ingredientId);
      } else {
        // If not checked, add it
        return [...prevCheckedIngredients, ingredientId];
      }
    });
  }, []);
  const createWhatsAppShoppingList = useCallback(() => {
    if (!weeklySupplies.length) return;
    const checkedIngredientNames = weeklySupplies
      .filter((ingredient) => checkedIngredients.includes(ingredient.id))
      .map((ingredient) => ingredient.ingredient);

    let shoppingList = `${t("This Week's Shopping List:")}\n`;
    checkedIngredientNames.forEach((ingredientName) => {
      shoppingList += ingredientName + "\n";
    });

    const directionMark = languageDirection === "rtl" ? "\u200F" : "\u200E";
    shoppingList = directionMark + shoppingList;

    const encodedMessage = encodeURIComponent(shoppingList);
    const whatsappLink = `https://wa.me/?text=${encodedMessage}`;

    // Add event listener to prevent default behavior
    const openWhatsApp = (e) => {
      e.preventDefault();
      const href = e.target.getAttribute("href");
      // Open the link in a new tab dont use window.open
      window.location.href = href;
      // window.open(href, "_blank", "noopener,noreferrer");
    };

    const dummyLink = document.createElement("a");
    dummyLink.setAttribute("href", whatsappLink);
    dummyLink.setAttribute("target", "_blank");
    dummyLink.style.display = "none";
    document.body.appendChild(dummyLink);

    dummyLink.addEventListener("click", openWhatsApp);
    dummyLink.click();

    document.body.removeChild(dummyLink);
  });

  return (
    <>
      <div
        className={
          mobile ? "weekly-supply-container" : "weekly-supply-container desktop"
        }
      >
        <div
          className={mobile ? "weekly-title" : "weekly-title desktop"}
          style={{
            maxWidth: mobile ? "100%" : "270px",
            marginBottom: mobile ? "" : "5px",
            direction: !mobile ? "ltr" : languageDirection,
            paddingRight:
              languageDirection === "rtl" && mobile ? "20px" : "0px",
          }}
        >
          <div>
            <IconContext.Provider value={{ size: "25px", color: "#3AA7D5" }}>
              <ImAttachment className="attach-icon" />
            </IconContext.Provider>
          </div>
          <span>{t("go_to_supermarket")}</span>
        </div>
        {mobile ? (
          /* mobile view */
          <div
            className="weekly"
            style={{
              minHeight: modalOpen ? "none" : "110px",
              marginTop: mobile ? "" : "5px",
            }}
          >
            <div
              className="weekly-trigger"
              style={{
                paddingBlockEnd: modalOpen ? "10px" : "0",
                direction: languageDirection,
              }}
            >
              <span>{t("weekly_supply")} </span>
              <span onClick={() => setModalOpen(!modalOpen)}>
                <IconContext.Provider value={{ size: "20px" }}>
                  {modalOpen ? (
                    <MdOutlineKeyboardArrowUp className="arrow-icon" />
                  ) : (
                    <MdOutlineKeyboardArrowDown className="arrow-icon" />
                  )}
                </IconContext.Provider>
              </span>
            </div>

            {modalOpen && (
              <>
                {weeklyCalenderContainPersonalRecipe &&
                  !weeklyCalenderContainsGeneralRecipe && (
                    <div className="weekly-disclaimer">
                      {t("ingredients_can_be_added")}
                    </div>
                  )}

                <div
                  style={{ direction: languageDirection }}
                  className={
                    weeklySupplies.length
                      ? "weekly-ingredients"
                      : "no-ingredients-mobile"
                  }
                >
                  {weeklySupplies.length ? (
                    <>
                      {weeklySupplies.map((ingredient) => {
                        return (
                          <label
                            key={ingredient.id}
                            htmlFor={`ingredient_${ingredient.id}`}
                            className="ingredient"
                          >
                            <input
                              type="checkbox"
                              onChange={() =>
                                handleCheckboxChange(ingredient.id)
                              }
                              id={`ingredient_${ingredient.id}`}
                            />
                            <span className="supplies">
                              {ingredient.ingredient}
                            </span>
                          </label>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {!weeklyCalenderContainPersonalRecipe &&
                        !weeklyCalenderContainsGeneralRecipe && (
                          <div className="choose-recipe">
                            {t("choose_recipe")}
                          </div>
                        )}
                    </>
                  )}
                </div>
              </>
            )}

            {modalOpen && weeklySupplies.length > 0 && (
              <>
                <div
                  className="whatsApp-btn"
                  onClick={createWhatsAppShoppingList}
                >
                  <span>
                    <img src={whatsAppLogo} alt="" />
                  </span>
                  <span>{t("share_whatsapp")}</span>
                </div>
              </>
            )}
          </div>
        ) : (
          <>
            {/* desktop view */}

            <div className="weekly-desktop">
              <div
                className="weekly-desktop-title"
                style={{ direction: languageDirection }}
              >
                {t("weekly_supply")}
              </div>
              {weeklyCalenderContainPersonalRecipe &&
                !weeklyCalenderContainsGeneralRecipe && (
                  <div
                    className="weekly-desktop-disclaimer"
                    style={{ direction: languageDirection }}
                  >
                    {t("ingredients_can_be_added")}
                  </div>
                )}
              <div
                className="weekly-ingredients"
                style={{ direction: languageDirection }}
              >
                {weeklySupplies.length ? (
                  <>
                    {weeklySupplies.map((ingredient) => {
                      return (
                        <label
                          key={ingredient.id}
                          htmlFor={`ingredient_${ingredient.id}`}
                          className="ingredient"
                          // style={{
                          //   flexDirection:
                          //     languageDirection === "rtl"
                          //       ? "row-reverse"
                          //       : "row",
                          // }}
                        >
                          <input
                            onChange={() => handleCheckboxChange(ingredient.id)}
                            type="checkbox"
                            id={`ingredient_${ingredient.id}`}
                          />
                          <span className="supplies">
                            {ingredient.ingredient}
                          </span>
                        </label>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {!weeklyCalenderContainPersonalRecipe &&
                      !weeklyCalenderContainsGeneralRecipe && (
                        <div className="no-ingredients">
                          {t("choose_recipe")}
                        </div>
                      )}
                  </>
                )}
              </div>
              <div
                className="whatsApp-btn"
                onClick={createWhatsAppShoppingList}
              >
                <span>
                  <img src={whatsAppLogo} alt="" />
                </span>

                <span>{t("share_whatsapp")}</span>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default WeeklySupply;
