import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import homePageTranslationEN from "./home-page/en/translation.json";
import homePageTranslationHE from "./home-page/he/translation.json";
const resources = {
  en: {
    translation: homePageTranslationEN,
  },
  he: {
    translation: homePageTranslationHE,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "he",

  interpolation: {
    escapeValue: false,
  },
});
export default i18n;
