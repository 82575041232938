import lemon from "../images/avatar/001-lemon-new.png";
import summer from "../images/avatar/007-summer-1.png";
import whale from "../images/avatar/008-whale-1.png";
import lemonade from "../images/avatar/009-lemonade-1.png";
import watermelon from "../images/avatar/012-watermelon-1.png";
import orange from "../images/avatar/014-orange-1.png";
import pineapple from "../images/avatar/015-pineapple-1.png";
import strawberry from "../images/avatar/016-strawberry-1.png";
import crab from "../images/avatar/crab.png";
const localAvatarImages = [
  { id: 1, name: `lemon`, img: lemon },
  { id: 2, name: "summer", img: summer },
  { id: 3, name: "whale", img: whale },
  { id: 4, name: "lemonade", img: lemonade },
  { id: 5, name: "watermelon", img: watermelon },
  { id: 6, name: "orange", img: orange },
  { id: 7, name: "pineapple", img: pineapple },
  { id: 8, name: "strawberry", img: strawberry },
  { id: 9, name: "crab", img: crab },
];
export default localAvatarImages;
