import logo from "../images/logo_new.png";
import Notification from "./notification";
import profileDetails from "../images/mobile-menu.png";
import "./styles/header-desktop.css";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import localAvatarImages from "../utils/avatars-images-array";
import {
  setCurrentChildProfileRedux,
  setWeeklyRecipesRedux,
  setWeeklySuppliesRedux,
  setPersonalRecipes,
  setGeneralRecipes,
  setBoxColor,
  setDontFetchUserDetails,
  setRecipePanelEditMode,
} from "../redux/reducer";
import { FaRegNewspaper, FaUsersLine } from "react-icons/fa";
import { MdAssignmentAdd } from "react-icons/md";
import { IconContext } from "react-icons";
import profileService from "../services/profileService";
import recipeService from "../services/recipeService";
import { boxImages } from "../utils/box-images";
import { useTranslation } from "react-i18next";
const HeaderDesktop = () => {
  const [notifications, setNotifications] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [currentProfile, setCurrentProfile] = useState({});
  const [amountOfNotifications, setAmountOfNotifications] = useState(0);
  const [roleIsAdmin, setRoleIsAdmin] = useState(false);
  const reduxNotifications = useSelector(
    (state) => state.mainSlice.notifications
  );
  const childProfilesRedux = useSelector(
    (state) => state.mainSlice.childProfiles
  );
  const currentProfileRedux = useSelector(
    (state) => state.mainSlice.currentChildProfile
  );
  const { t } = useTranslation();
  const roleRedux = useSelector((state) => state.mainSlice.role);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    // check if user is admin
    if (roleRedux === 2) {
      setRoleIsAdmin(true);
    }

    if (reduxNotifications.length) {
      setAmountOfNotifications(reduxNotifications.length);
      setNotifications(reduxNotifications);
    } else {
      setAmountOfNotifications(0);
      setNotifications([]);
    }
    if (childProfilesRedux.length) {
      const updated = childProfilesRedux.map((profile) => {
        const avatar = localAvatarImages.find(
          (image) =>
            image.name.toLowerCase() === profile.avatarName.toLowerCase()
        );
        if (!avatar) return profile;
        return { ...profile, avatar: avatar.img };
      });
      setProfiles(updated);
    }
  }, [reduxNotifications, childProfilesRedux]);

  useEffect(() => {
    if (profiles.length) {
      setCurrentProfile(currentProfileRedux);
    }
  }, [profiles]);
  useEffect(() => {
    if (currentProfileRedux?.id) {
      setCurrentProfile(currentProfileRedux);
    }
  }, [currentProfileRedux]);
  const handleProfileChange = async (profile) => {
    try {
      const changeBox = await profileService.getBoxColor(profile.id);
      if (changeBox.message === "success") {
        const boxColorNumber = changeBox.box_identifier.slice(0, 3);

        dispatch(setBoxColor(boxImages[boxColorNumber]));
      }

      // also set the new profile as the current profile in local storage
      localStorage.setItem("profileId", profile.id);
      const response = await profileService.getProfileSelections(profile.id);
      if (response.message === "success") {
        dispatch(setCurrentChildProfileRedux(response.profile));
        dispatch(setWeeklyRecipesRedux(response.profile.weeklyRecipes));
        dispatch(setWeeklySuppliesRedux(response.profile.weeklySupplies));

        // fetch new profile recipes

        const getRecipes = await recipeService.getRecipes(profile.id);
        if (getRecipes.message === "success") {
          const personalRecipes = getRecipes.personalRecipes || []; // Ensure it's an array or an empty array
          const generalRecipes = getRecipes.generalRecipes || []; // Ensure it's an array or an empty array
          personalRecipes.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );

          generalRecipes.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );

          dispatch(setGeneralRecipes(generalRecipes));
          dispatch(setPersonalRecipes(personalRecipes));
          dispatch(setDontFetchUserDetails(true));
        }
      } else {
        console.log("error changing profile header desktop else");
      }
    } catch (err) {
      console.log(err, "error changing profile header desktop catch");
    }
  };

  return (
    <>
      <div className="header-container-desktop">
        <div className="header-desktop-left">
          <div className="logo" onClick={() => navigate("/")}>
            <img src={logo} alt="" />
          </div>
          <div className="change-profile">
            {profiles.length && (
              <>
                {profiles.map((profile) => {
                  return (
                    <div
                      className={
                        profile.id === currentProfile.id
                          ? "profile-image-wrapper active"
                          : "profile-image-wrapper inactive"
                      }
                      key={profile.id}
                      onClick={() => handleProfileChange(profile)}
                    >
                      <div className="img-circle">
                        <img
                          src={profile.avatar}
                          style={{
                            padding: profile.avatar.startsWith("data")
                              ? "0"
                              : "3px 3px 4px 2px",
                          }}
                          alt=""
                        />
                      </div>
                      <div className="profile-name">{profile.first_name}</div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
        {roleIsAdmin && (
          <>
            <div className="header-desktop-center">
              <div
                className="create-recipe-wrapper"
                onClick={() => {
                  dispatch(setRecipePanelEditMode(false));
                  navigate("/create-recipe");
                }}
              >
                <IconContext.Provider
                  value={{
                    color: "#000000",
                    size: "1rem",
                    className: "create-recipe-icon",
                  }}
                >
                  <MdAssignmentAdd />
                </IconContext.Provider>
                <div className="create-recipe-text">{t("recipe")} </div>
              </div>
              <div
                className="create-article-wrapper"
                onClick={() => navigate("/create-article")}
              >
                <IconContext.Provider
                  value={{
                    color: "#000000",
                    size: "1rem",
                    className: "create-recipe-icon",
                  }}
                >
                  <FaRegNewspaper />
                </IconContext.Provider>
                <div className="create-recipe-text ">{t("panel")} </div>
              </div>
              <div
                className="user-list"
                onClick={() => navigate("/users-list")}
              >
                <IconContext.Provider
                  value={{
                    color: "#000000",
                    size: "1rem",
                    className: "user-icon",
                  }}
                >
                  <FaRegNewspaper />
                </IconContext.Provider>

                <div className="create-recipe-text "> {t("users")} </div>
              </div>
            </div>
          </>
        )}
        <div className="header-desktop-right">
          {notifications.length > 0 && (
            <>
              <div className="desktop-notification">
                <Notification notification={notifications[0]} />
              </div>
              <div className="amount-of-notifications">
                {amountOfNotifications}
              </div>
            </>
          )}
          <div
            className="profile-edit"
            onClick={() => navigate("/edit-profile")}
          >
            <div className="img-circle">
              <img src={profileDetails} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderDesktop;
