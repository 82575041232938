import { useSelector, useDispatch } from "react-redux";
import HeaderMobile from "./header-mobile";
import "./styles/change-profile-mobile.css";
import { useState, useEffect } from "react";
import {
  setCurrentChildProfileRedux,
  setWeeklyRecipesRedux,
  setWeeklySuppliesRedux,
  setPersonalRecipes,
  setGeneralRecipes,
  setDontFetchUserDetails,
  setBoxColor,
} from "../redux/reducer";
import localAvatarImages from "../utils/avatars-images-array";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { boxImages } from "../utils/box-images";

import profileService from "../services/profileService";
import recipeService from "../services/recipeService";
import { toast } from "react-toastify";
const ChangeProfileMobile = () => {
  const mobile = useMediaQuery({ maxWidth: 800 });
  const [profiles, setProfiles] = useState([]);
  const [currentProfile, setCurrentProfile] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [profileChanged, setProfileChanged] = useState(false);
  const childProfilesRedux = useSelector(
    (state) => state.mainSlice.childProfiles
  );
  const currentProfileRedux = useSelector(
    (state) => state.mainSlice.currentChildProfile
  );

  useEffect(() => {
    if (currentProfileRedux?.id) {
      setCurrentProfile(currentProfileRedux);
    }

    if (childProfilesRedux.length) {
      setProfiles(childProfilesRedux);
    }
  }, [childProfilesRedux, currentProfileRedux]);

  useEffect(() => {
    if (!mobile) {
      // if desktop redirect to homepage
      navigate("/");
    }
  }, [mobile]);
  useEffect(() => {
    if (profileChanged) {
      // profile change is done, navigate to homepage
      navigate("/");
      setProfileChanged(false);
    }
  }, [profileChanged]);

  const handleProfileChange = async (profile) => {
    console.log("Handling profile change for profile:", profile);
    try {
      const changeBox = await profileService.getBoxColor(profile.id);
      if (changeBox.message === "success") {
        const boxColorNumber = changeBox.box_identifier.slice(0, 3);
        dispatch(setBoxColor(boxImages[boxColorNumber]));
      }

      localStorage.setItem("profileId", profile.id);
      const response = await profileService.getProfileSelections(profile.id);
      if (response.message === "success") {
        dispatch(setCurrentChildProfileRedux(response.profile));
        if (response.profile?.weeklyRecipes?.length) {
          dispatch(setWeeklyRecipesRedux(response.profile.weeklyRecipes));
          dispatch(setWeeklySuppliesRedux(response.profile.weeklySupplies));
        }

        const getRecipes = await recipeService.getRecipes(profile.id);
        if (getRecipes.message === "success") {
          const personalRecipes = getRecipes.personalRecipes || [];
          const generalRecipes = getRecipes.generalRecipes || [];
          personalRecipes.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );
          generalRecipes.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );

          dispatch(setGeneralRecipes(generalRecipes));
          dispatch(setPersonalRecipes(personalRecipes));
          dispatch(setDontFetchUserDetails(true));
        }
        // trigger useEffect to navigate to homepage
        setProfileChanged(true);
      } else {
        toast.error("Error changing profile mobile");
      }
    } catch (error) {
      console.error("Profile change error:", error);
      toast.error("Error changing profile mobile");
    }
  };

  return (
    <>
      <HeaderMobile location="change-profile-mobile" />
      <div className="change-profile-mobile-container">
        <div className="profile-wrapper-mobile">
          {profiles.length > 0 && (
            <>
              {profiles.map((profile) => {
                return (
                  <div className="choose-profile-mobile" key={profile.id}>
                    <div
                      onClick={() => handleProfileChange(profile)}
                      className={
                        profile.id === currentProfile.id
                          ? "img-circle active"
                          : "img-circle"
                      }
                      style={{ backgroundImage: `url('${profile.avatar}')` }}
                    ></div>
                    <div className="profile-name">{profile.first_name}</div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ChangeProfileMobile;
