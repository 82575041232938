import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import HeaderDesktop from "./header-desktop";
import FooterDesktop from "./footer-desktop";
import Select from "react-select";
import generalData from "../utils/static-data";
import { FaPlus } from "react-icons/fa6";
import { IoMdRemove } from "react-icons/io";
import { BsUpload } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import { toast } from "react-toastify";
import { setGeneralRecipes, setPersonalRecipes } from "../redux/reducer";
import { IconContext } from "react-icons";
import recipeService from "../services/recipeService";
import profileService from "../services/profileService";
import "./styles/recipe-panel.css";
import HeaderMobile from "./header-mobile";
import { useTranslation } from "react-i18next";
const RecipePanel = () => {
  const mobile = useMediaQuery({ maxWidth: 800 });
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const BreakPoint600 = useMediaQuery({ minWidth: 501, maxWidth: 600 });
  const breakPoint500 = useMediaQuery({ minWidth: 400, maxWidth: 500 });
  const breakPoint370 = useMediaQuery({ maxWidth: 370 });
  const navigate = useNavigate();
  const [amountOfIngredients, setAmountOfIngredients] = useState([1]);
  const [amountOfSteps, setAmountOfSteps] = useState([1]);
  const [nutritionalValues, setNutritionalValues] = useState([1]);
  const [recipeImage, setRecipeImage] = useState(null);
  const [lifeStyleOptions, setLifeStyleOptions] = useState([]);
  const [allergyOptions, setAllergyOptions] = useState([]);
  const [householdProfiles, setHouseholdProfiles] = useState([]);
  const [ingredientsForSupermarketList, setIngredientsForSupermarketList] =
    useState([]);

  const dispatch = useDispatch();
  // for edit mode only show previously selected profiles
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [oldImage, setOldImage] = useState(null);
  const [form, setForm] = useState({
    recipeName: "",
    shortName: "",
    description: "",
    cookingTime: "",
    prepTime: "",
    calories: "",
    healthRate: "",
    ingredients: {
      ingredient_1: "",
    },
    ingredientsForSupermarketList: [],
    preparation: {
      step_1: "",
    },
    nutritionalValues: [
      {
        nutrient: "",
        amount: "",
        percent: "",
      },
    ],
    mainDish: {},
    lifestyle: [],
    allergies: [],
    allergens_may_contain: "",
    profiles: null,
    notes: "",
    language: {},
  });
  const uploadInputRef = useRef(null);
  const currentProfileRedux = useSelector(
    (state) => state.mainSlice.currentChildProfile
  );
  const roleRedux = useSelector((state) => state.mainSlice.role);
  const currentRecipeRedux = useSelector(
    (state) => state.mainSlice.currentRecipe
  );
  const recipePanelEditMode = useSelector(
    (state) => state.mainSlice.recipePanelEditMode
  );
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "none",
      width: "70vw",
      maxWidth: "400px",
      minHeight: "50px",
      borderRadius: "4px",
      borderBottom: "2px solid #3C4964",
      marginBottom: "10px",
      "&:hover": {
        borderBottom: "2px solid #3C4964", // Remove the border on hover
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none", // Hide the indicators separator (the vertical line)
    }),
    ValueContainer: (provided) => ({
      ...provided,
      border: "none",
      fontSize: "2rem",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#3C4964",
      fontSize: BreakPoint600 ? "0.9rem" : breakPoint500 ? "0.7rem" : "1rem",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#3C4964",
    }),
    menu: (provided) => ({
      ...provided,
      width: "70vw", // Adjust the width of the menu if needed
      maxWidth: "400px", // Adjust the maximum width of the menu if needed
      zIndex: 9999, // Adjust z-index if necessary
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "200px", // Set a fixed max height for the dropdown menu
      overflowY: "auto", // Enable vertical scrolling
    }),
  };

  useEffect(() => {
    const fetchIngredients = async () => {
      try {
        const response = await recipeService.getIngredients();
        if (response.message === "success") {
          const optionsForSelect = response.ingredients.map((ingredient) => {
            return {
              label:
                i18n.language === "he"
                  ? ingredient.name_in_hebrew
                  : ingredient.name,
              value: ingredient.id,
            };
          });
          setIngredientsForSupermarketList(optionsForSelect);
        } else {
          setIngredientsForSupermarketList([]);
          console.log(response, "error fetching ingredients");
        }
      } catch (err) {
        console.log(err, "error fetching ingredients");
      }
    };
    fetchIngredients();
    /*  remove None from allergy options handle none as placeholder 
    and add free to the end of each allergy to show in select  
    */

    const removeNoneFromAllergies = generalData.allergies
      .filter((allergy) => allergy.label !== "None")
      .map((allergy) => {
        if (i18n.language === "he") {
          return {
            label: `ללא ${t(`${allergy.label}`)} `,
            value: allergy.value,
          };
        } else {
          return {
            label: `${t(`${allergy.label}`)} Free`,
            value: allergy.value,
          };
        }
      });
    setAllergyOptions(removeNoneFromAllergies);

    /*  remove Eats everything from lifestyle options handle Eats everything as placeholder   */
    const removeEatsEverythingFromLifestyle = generalData.diets
      .filter((diet) => diet.label !== "Eats everything")
      .map((diet) => {
        return { label: t(`${diet.label}`), value: diet.value };
      });

    setLifeStyleOptions(removeEatsEverythingFromLifestyle);

    /*  if NOT admin fetch all household profiles names and ids to assign a recipe to  */
    if (roleRedux === 1) {
      const fetchProfileNames = async () => {
        try {
          const response = await profileService.getHouseholdNames(
            currentProfileRedux.household_id
          );
          if (response.message === "success") {
            const updated = response.profiles.map((profile) => {
              return { label: profile.first_name, value: profile.id };
            });
            setHouseholdProfiles(updated);
          }
        } catch (err) {
          console.log(err, "error fetching profile names");
        }
      };
      fetchProfileNames();
    }
  }, []);

  const translatedData = {
    mainDish: generalData.foods.map((dish) => {
      return { label: t(dish.label), value: dish.value };
    }),
    allergies: generalData.allergies.map((allergy) => {
      return { label: t(allergy.label), value: allergy.value };
    }),
    lifestyle: generalData.diets.map((lifestyle) => {
      return { label: t(lifestyle.label), value: lifestyle.value };
    }),
    language: [
      { label: t("Hebrew"), value: "he" },
      { label: t("English"), value: "en" },
    ],
    ingredientsForSupermarketList: ingredientsForSupermarketList.map(
      (ingredient) => {
        return { label: t(ingredient.label), value: ingredient.value };
      }
    ),
  };
  useEffect(() => {
    if (recipePanelEditMode) {
      // Check if the recipe is in edit mode
      // If it is, populate the form with the recipe data
      const populateForm = () => {
        const updatedForm = { ...form };

        setRecipeImage(currentRecipeRedux.image);
        setOldImage(currentRecipeRedux.image);
        updatedForm.recipeName = currentRecipeRedux.name;
        updatedForm.shortName = currentRecipeRedux.short_name;
        updatedForm.description = currentRecipeRedux.description;
        updatedForm.cookingTime = currentRecipeRedux.cooking_time;
        updatedForm.calories = currentRecipeRedux.calories;
        updatedForm.healthRate = currentRecipeRedux.health_rate;
        updatedForm.notes = currentRecipeRedux.notes;
        updatedForm.prepTime = currentRecipeRedux.prep_time;
        updatedForm.allergens_may_contain =
          currentRecipeRedux.allergens_may_contain;
        const selectedLanguageOption = translatedData.language.find(
          (option) => option.value === currentRecipeRedux.recipe_language
        );

        // Set the value of the language in your local state
        updatedForm.language = selectedLanguageOption
          ? selectedLanguageOption
          : "";

        // handle main dish

        const mainDishId = currentRecipeRedux.main_dish_id;
        const selectedDish = translatedData.mainDish.find(
          (dish) => dish.value === parseInt(mainDishId)
        );
        if (selectedDish?.value) {
          updatedForm.mainDish = selectedDish;
        }

        // handle lifestyle
        const currentLifestyle = currentRecipeRedux.life_style;
        if (currentLifestyle !== null) {
          if (currentLifestyle.includes(",")) {
            const lifestyleIds = currentLifestyle
              .split(",")
              .map((id) => parseInt(id.trim()));
            const selectedLifestyles = translatedData.lifestyle.filter(
              (lifestyle) => lifestyleIds.includes(lifestyle.value)
            );
            updatedForm.lifestyle = selectedLifestyles;
          } else {
            // if only one lifestyle is selected
            let selectedLifestyle = null;

            selectedLifestyle = translatedData.lifestyle.find(
              (lifestyle) => lifestyle.value === parseInt(currentLifestyle)
            );

            updatedForm.lifestyle = [selectedLifestyle];
          }
        } else {
          updatedForm.lifestyle = currentLifestyle;
        }

        // handle allergies
        const currentAllergies = currentRecipeRedux.allergy_free;
        if (currentAllergies !== null) {
          if (currentAllergies.includes(",")) {
            const allergyIds = currentAllergies
              .split(",")
              .map((id) => parseInt(id.trim()));

            const selectedAllergies = translatedData.allergies
              .filter((allergy) => allergyIds.includes(allergy.value))
              .map((allergy) => {
                if (i18n.language === "he") {
                  return {
                    label: `ללא ${t(`${allergy.label}`)}`,
                    value: allergy.value,
                  };
                } else {
                  return {
                    label: `${t(`${allergy.label}`)} Free`,
                    value: allergy.value,
                  };
                }
              });

            updatedForm.allergies = selectedAllergies;
          } else {
            // if only one allergy is selected
            const selectedAllergy = translatedData.allergies.find(
              (allergy) => allergy.value === parseInt(currentAllergies)
            );
            if (i18n.language === "he") {
              updatedForm.allergies = [
                {
                  label: `ללא ${t(`${selectedAllergy.label}`)}`, // Add 'Free' to the label
                  value: selectedAllergy.value,
                },
              ];
            } else {
              updatedForm.allergies = [
                {
                  label: `${t(`${selectedAllergy.label}`)} Free`, // Add 'Free' to the label
                  value: selectedAllergy.value,
                },
              ];
            }
          }
        } else {
          // null
          updatedForm.allergies = currentAllergies;
        }

        // Extract keys from recipe_ingredients.ingredients and preparation.steps objects
        const ingredientKeys = Object.keys(
          currentRecipeRedux.recipe_ingredients.ingredients
        );
        const stepKeys = Object.keys(currentRecipeRedux.preparation.steps);

        // Populate the form with data
        updatedForm.ingredients = {};
        updatedForm.preparation = {};

        // Populate ingredients
        ingredientKeys.forEach((key) => {
          const ingredientNumber = parseInt(key);
          updatedForm.ingredients[`ingredient_${ingredientNumber}`] =
            currentRecipeRedux.recipe_ingredients.ingredients[key];
        });

        // Populate preparation steps
        stepKeys.forEach((key) => {
          const stepNumber = parseInt(key);
          updatedForm.preparation[`step_${stepNumber}`] =
            currentRecipeRedux.preparation.steps[key];
        });

        // handle nutritional values
        const currentNutritionalValues = currentRecipeRedux.nutritional_values;
        if (currentNutritionalValues && currentNutritionalValues.length > 0) {
          const nutritionalValues = [];
          for (let i = 0; i < currentNutritionalValues.length; i += 3) {
            const nutrients = currentNutritionalValues.slice(i, i + 3);
            nutritionalValues.push(...nutrients);
          }
          updatedForm.nutritionalValues = nutritionalValues;
        } else {
          updatedForm.nutritionalValues = [];
        }

        // Set the populated form in the state
        setForm(updatedForm);

        // Update the amountOfIngredients and amountOfSteps arrays
        setAmountOfIngredients(ingredientKeys.map((key) => parseInt(key)));
        setAmountOfSteps(stepKeys.map((key) => parseInt(key)));

        // update ingrtedients for supermarket list select
        const ingredient_list = currentRecipeRedux.ingredient_list;
        let updatedList;
        if (ingredient_list && ingredient_list.length > 0) {
          const split = ingredient_list.includes(",")
            ? ingredient_list.split(",")
            : [ingredient_list];

          updatedList = ingredientsForSupermarketList
            .filter((ingredient) => split.includes(ingredient.value.toString()))
            .map((ingredient) => ({
              label: t(ingredient.label.trim()),
              value: ingredient.value,
            }));

          // its a string that may contain comma separated values
          updatedForm.ingredientsForSupermarketList = updatedList;
        }
      };
      populateForm();

      const fetchRecipeMembers = async () => {
        try {
          const response = await recipeService.getRecipeMembers(
            currentRecipeRedux.id
          );

          if (response.message === "success") {
            let updated = [];
            if (response.profiles.profile_ids.includes(",")) {
              updated = response.profiles.profile_ids
                .split(",")
                .map((id) => parseInt(id.trim()));
            } else {
              updated = [parseInt(response.profiles.profile_ids)];
            }

            setSelectedProfiles(updated);
          }
        } catch (err) {
          console.log(err, "error fetching recipe members");
        }
      };
      if (roleRedux === 1) {
        fetchRecipeMembers();
      }
    }
  }, [recipePanelEditMode, ingredientsForSupermarketList]);
  useEffect(() => {
    if (!currentProfileRedux?.id) {
      navigate("/");
    }
  }, [currentProfileRedux]);
  useEffect(() => {
    // If the recipe is in edit mode, we need to update the selected profiles
    if (selectedProfiles.length && householdProfiles.length) {
      const updated = householdProfiles.filter((profile) =>
        selectedProfiles.includes(profile.value)
      );
      if (updated.length) {
        setForm({ ...form, profiles: updated });
      } else {
        return;
      }
    }
  }, [selectedProfiles]);

  const handleAddField = (context) => {
    if (context === "preparation") {
      /* preparation */
      const lastNumber = amountOfSteps.length;
      setAmountOfSteps((prevAmountOfSteps) => {
        const newNumber = lastNumber + 1;
        return [...prevAmountOfSteps, newNumber];
      });
    } else if (context === "ingredient") {
      /* ingredients */
      const lastNumber = amountOfIngredients.length;
      setAmountOfIngredients((prevAmountOfIngredients) => {
        const newNumber = lastNumber + 1;
        return [...prevAmountOfIngredients, newNumber];
      });
    } else if (context === "nutritionalValues") {
      const updated = [...form.nutritionalValues];
      updated.push({
        nutrient: "",
        amount: "",
        percent: "",
      });
      setForm({ ...form, nutritionalValues: updated });
    }
  };

  const handleRemoveField = (context, index = null) => {
    // index is only relevant in nutritional values
    if (context === "preparation") {
      const numberOfSteps = amountOfSteps.length;
      setAmountOfSteps((prevAmountOfSteps) => {
        const newAmountOfSteps = prevAmountOfSteps.filter(
          (stepNumber) => stepNumber !== numberOfSteps
        );
        return newAmountOfSteps;
      });
    } else if (context === "ingredient") {
      const numberOfIngredients = amountOfIngredients.length;
      setAmountOfIngredients((prevAmountOfIngredients) => {
        const newAmountOfIngredients = prevAmountOfIngredients.filter(
          (ingredientNumber) => ingredientNumber !== numberOfIngredients
        );
        return newAmountOfIngredients;
      });
    } else if (
      context === "nutritionalValues" &&
      form.nutritionalValues.length > 1
    ) {
      const updated = [...form.nutritionalValues];
      updated.splice(index, 1);
      setForm({ ...form, nutritionalValues: updated });
    }
  };
  const handleFileUpload = (e) => {
    if (recipeImage) return;
    const file = e.target.files[0];
    setRecipeImage(file);
  };
  const handleFileRemove = () => {
    // remove file from input and reset image state
    uploadInputRef.current.value = "";
    setRecipeImage(null);
  };
  const handleInputChange = (e, context = null) => {
    console.log(e.target.name, "e.target.name");
    console.log(e.target.value, "e.target.value");
    if (e.target.name === "healthRate") {
      /*  limit input to 2 characters */
      const regex = /^(\d)\.{0,1}(\d)?$/g;
      if (regex.test(e.target.value) || e.target.value === "") {
        // Update the state only if the input is valid
        setForm({ ...form, [e.target.name]: e.target.value });
      }
      // If the input doesn't match the pattern, do not update the state
      return;
    }

    if (e.target.name === "cookingTime" || e.target.name === "prepTime") {
      /*  limit input to 3 characters */

      const inputValue = e.target.value.toString().slice(0, 3);
      setForm({ ...form, [e.target.name]: inputValue });
      return;
    }
    if (e.target.name === "calories") {
      /*  limit input to 4 characters */

      const inputValue = e.target.value.toString().slice(0, 6);
      setForm({ ...form, [e.target.name]: inputValue });
      return;
    }
    if (context === null) {
      /*  handle regular input fields */
      setForm({ ...form, [e.target.name]: e.target.value });
    } else {
      const updated = {
        ...form[context],
        [e.target.name]: e.target.value,
      };
      setForm({ ...form, [context]: updated });
    }
  };
  const handleNutritionalValueChange = (e, index) => {
    const { value, name } = e.target;

    // Make a deep copy of the nutritionalValues array
    const updatedNutritionalValues = [...form.nutritionalValues];

    // Make a deep copy of the object at the specified index
    const updatedNutrient = { ...updatedNutritionalValues[index] };

    // Update the specified field within the copied object
    updatedNutrient[name] = value;

    // Update the copied object back into the array
    updatedNutritionalValues[index] = updatedNutrient;

    // Update the form state with the updated nutritionalValues array
    setForm({ ...form, nutritionalValues: updatedNutritionalValues });
  };

  const handleSelectChanges = (selections, formProperty) => {
    setForm({ ...form, [formProperty]: selections });
  };

  const submitRecipe = async (e) => {
    e.preventDefault();
    if (roleRedux === 2) {
      if (!form.language?.value) {
        toast.error(`${t("Please select a recipe language")}`);
        return;
      }
    }

    if (!form.recipeName) {
      toast.error(`${t("Please enter recipe name")}`);
      return;
    }
    if (!form.shortName) {
      toast.error(`${t("Please enter short name")}`);
      return;
    }

    if (!form.description) {
      toast.error(`${t("Please enter description")}`);
      return;
    }
    if (!form.cookingTime) {
      toast.error(`${t("Please enter cooking time")}`);
      return;
    }
    if (!form.prepTime) {
      toast.error(`${t("Please enter preparation time")}`);
      return;
    }
    if (!form.mainDish?.value) {
      toast.error(`${t("Please select main dish")}`);
      return;
    }
    if (roleRedux === 1) {
      if (
        form.profiles === null ||
        (Array.isArray(form.profiles) && !form.profiles.length)
      ) {
        toast.error(`${t("please select a member to assign recipe to")}`);
        return;
      }
    }
    if (roleRedux === 2) {
      // if admin check health rate and calories
      if (!form.healthRate) {
        toast.error(`${t("Please enter health rate")}`);
        return;
      }
      if (!form.calories) {
        toast.error(`${t("Please enter calories")}`);
        return;
      }
    }

    if (!form.ingredients.ingredient_1) {
      toast.error(`${t("Please enter at least 1 ingredient")}`);
      return;
    }
    if (!form.preparation.step_1) {
      toast.error(`${t("Please enter at least 1 step of preparation")}`);
      return;
    }
    if (!recipeImage) {
      toast.error(`${t("Please upload recipe image")}`);
      return;
    }

    try {
      /*  handle ingredients and preparation objects */
      const arrangeJsonObjectForDataBAse = (object, context) => {
        const transformed = {};
        for (const key in object) {
          const stepNumber = key.split("_")[1];
          transformed[stepNumber] = object[key];
        }
        const finalObject = {
          [context]: transformed,
        };
        return finalObject;
      };
      const preparation = arrangeJsonObjectForDataBAse(
        form.preparation,
        "steps"
      );
      const ingredients = arrangeJsonObjectForDataBAse(
        form.ingredients,
        "ingredients"
      );

      const formData = new FormData();
      const type = roleRedux === 2 ? "general" : "personal";
      formData.append("profileId", currentProfileRedux.id);
      formData.append("type", type);
      formData.append("recipeName", form.recipeName);
      formData.append("shortName", form.shortName);
      formData.append("description", form.description);
      formData.append("cookingTime", form.cookingTime);
      formData.append("prepTime", form.prepTime);
      formData.append("ingredients", JSON.stringify(ingredients));
      formData.append("preparation", JSON.stringify(preparation));
      formData.append("mainDish", JSON.stringify(form.mainDish));
      formData.append("context", "recipe");

      if (roleRedux === 2) {
        // if admin add health rate calories and nutritional values
        formData.append("healthRate", form.healthRate);
        formData.append("language", form.language.value);
        formData.append("calories", form.calories);
        formData.append(
          "allergensMayContain",
          form.allergens_may_contain ? form.allergens_may_contain : null
        );
        formData.append(
          "nutritionalValues",
          JSON.stringify(form.nutritionalValues)
        );
        // if ingredients for supermarket list is not empty add it to form data
        if (form.ingredientsForSupermarketList.length) {
          formData.append(
            "ingredientsForSupermarketList",
            JSON.stringify(form.ingredientsForSupermarketList)
          );
        } else {
          formData.append("ingredientsForSupermarketList", null);
        }
      } else {
        // if regular user add null values
        formData.append("calories", null);
        formData.append("healthRate", null);
        formData.append("nutritionalValues", null);
        formData.append("ingredientsForSupermarketList", null);
        formData.append("allergensMayContain", null);
        formData.append("language", null);
      }

      if (form.lifestyle !== null && form.lifestyle.length > 0) {
        formData.append("lifestyle", JSON.stringify(form.lifestyle));
      } else {
        formData.append("lifestyle", null);
      }

      if (form.allergies !== null && form.allergies.length) {
        formData.append("allergies", JSON.stringify(form.allergies));
      } else {
        formData.append("allergies", null);
      }
      formData.append("notes", form.notes);
      formData.append("profiles", JSON.stringify(form.profiles));
      formData.append("recipeImage", recipeImage);

      let response;
      if (recipePanelEditMode) {
        // If the recipe is in edit mode, we need to add the recipe id to the form data
        formData.append("recipeId", currentRecipeRedux.id);
        // if the image is changed so we send the old image name to delete from folder
        formData.append("oldImage", oldImage);
        response = await recipeService.editRecipe(formData);
        if (response.message === "success") {
          // recipe changed fetch recipes again
          // profileId

          const recipes = await recipeService.getRecipes(
            currentProfileRedux.id
          );
          if (recipes.message === "success") {
            const personalRecipes = recipes.personalRecipes || []; // Ensure it's an array or an empty array
            const generalRecipes = recipes.generalRecipes || []; // Ensure it's an array or an empty array
            personalRecipes.sort(
              (a, b) => new Date(b.created_at) - new Date(a.created_at)
            );

            generalRecipes.sort(
              (a, b) => new Date(b.created_at) - new Date(a.created_at)
            );

            dispatch(setGeneralRecipes(generalRecipes));
            dispatch(setPersonalRecipes(personalRecipes));
          }

          toast.success(`${t("Recipe edited")}`);
          navigate("/");
        } else {
          toast.error(`${t("Something went wrong, please try again later")}`);
          console.log(response, "response from submit edit or create recipe");
        }
      } else {
        // create a new recipe
        response = await recipeService.createRecipe(formData);
        if (response.message === "success") {
          toast.success(`${t("Recipe created")}`);
          navigate("/");
        } else {
          toast.error(`${t("Something went wrong, please try again later")}`);
          console.log(response, "response from submit recipe");
        }
      }
    } catch (err) {
      console.log(err, "error in submit recipe");
    }
  };

  return (
    <>
      {mobile ? <HeaderMobile /> : <HeaderDesktop />}

      <div className="admin-recipe-creation-wrapper">
        <h3> {t("create_recipe")} </h3>
        <div className="main">
          <form>
            {roleRedux === 2 && (
              <div
                className="input-wrapper select"
                style={{ direction: languageDirection }}
              >
                <label htmlFor="language">{t("recipe language")}</label>
                <Select
                  onChange={(e) => handleSelectChanges(e, "language")}
                  options={translatedData.language}
                  styles={customStyles}
                  placeholder={t("select-language")}
                  value={form.language?.value ? form.language : ""}
                />
              </div>
            )}

            <div
              className="input-wrapper"
              style={{ direction: languageDirection }}
            >
              <label htmlFor="recipeName">{t("recipe_name")} </label>
              <input
                type="text"
                id="recipeName"
                name="recipeName"
                onChange={handleInputChange}
                value={form.recipeName}
              />
            </div>
            <div
              className="input-wrapper"
              style={{ direction: languageDirection }}
            >
              <label htmlFor="shortName">{t("short_name")} </label>
              <input
                type="text"
                id="shortName"
                name="shortName"
                onChange={handleInputChange}
                value={form.shortName}
              />
            </div>
            <div
              className="input-wrapper"
              style={{ direction: languageDirection }}
            >
              <label htmlFor="description">{t("description")}</label>
              <textarea
                id="description"
                name="description"
                onChange={handleInputChange}
                value={form.description}
              />
            </div>
            <div
              className="input-wrapper"
              style={{ direction: languageDirection }}
            >
              <label htmlFor="cookingTime">{t("cooking_time")} </label>

              <input
                type="number"
                id="cookingTime"
                name="cookingTime"
                onChange={handleInputChange}
                value={form.cookingTime}
              />
            </div>

            <div
              className="input-wrapper"
              style={{ direction: languageDirection }}
            >
              <label htmlFor="cookingTime">{t("prep_time")}</label>

              <input
                type="number"
                id="prepTime"
                name="prepTime"
                onChange={handleInputChange}
                value={form.prepTime}
              />
            </div>
            <div
              className="input-wrapper select"
              style={{ direction: languageDirection }}
            >
              <label htmlFor="main-dish">{t("main_dish")}</label>
              <Select
                onChange={(e) => handleSelectChanges(e, "mainDish")}
                options={translatedData.mainDish}
                styles={customStyles}
                value={form.mainDish}
              />
            </div>
            <div
              className="input-wrapper"
              style={{ direction: languageDirection }}
            >
              <label htmlFor="life-style"> {t("life_style")}</label>
              <Select
                onChange={(e) => handleSelectChanges(e, "lifestyle")}
                options={lifeStyleOptions}
                styles={customStyles}
                placeholder={t("Eats everything")}
                value={form.lifestyle}
                isMulti
              />
            </div>
            <div
              className="input-wrapper"
              style={{ direction: languageDirection }}
            >
              <label>{t("allergy_free")}</label>
              <Select
                onChange={(e) => handleSelectChanges(e, "allergies")}
                options={allergyOptions}
                styles={customStyles}
                value={form.allergies}
                placeholder={t("None")}
                isMulti
              />
            </div>
            {roleRedux === 2 && (
              <div
                className="input-wrapper"
                style={{ direction: languageDirection }}
              >
                <label htmlFor="allergens_may_contain">
                  {t("allergen_may_contain")}
                </label>
                <input
                  type="text"
                  id="allergens_may_contain"
                  name="allergens_may_contain"
                  placeholder={t("allergy_example")}
                  onChange={handleInputChange}
                  value={form.allergens_may_contain}
                />
              </div>
            )}
            {roleRedux === 2 && (
              <>
                <div
                  className="input-wrapper"
                  style={{ direction: languageDirection }}
                >
                  <label htmlFor="healthRate">{t("health_rate")}</label>
                  <input
                    type="text"
                    value={form.healthRate}
                    onChange={handleInputChange}
                    id="healthRate"
                    name="healthRate"
                    pattern="^(10|[1-9](\.\d)?)$"
                  />
                </div>
                <div
                  className="input-wrapper"
                  style={{ direction: languageDirection }}
                >
                  <label htmlFor="calories">{t("calories")} </label>
                  <input
                    type="text"
                    id="calories"
                    name="calories"
                    onChange={handleInputChange}
                    value={form.calories}
                  />
                </div>
              </>
            )}
            <div
              className="input-wrapper"
              style={{ direction: languageDirection }}
            >
              <h5>
                {roleRedux === 2
                  ? ` ${t("ingredients_recipe_page")}`
                  : ` ${t("ingredients")}`}
              </h5>
              {roleRedux === 1 && (
                <div
                  style={{
                    fontSize: breakPoint370 ? "0.8rem" : "1rem",
                    marginBottom: "10px",
                  }}
                >
                  {t("ingredients_list")}
                </div>
              )}
              {amountOfIngredients.map((ingredientNumber) => {
                return (
                  <div className="ingredient" key={ingredientNumber}>
                    <label htmlFor={`ingredient_${ingredientNumber}`}>{`${t(
                      "ingredient"
                    )} ${ingredientNumber}:`}</label>
                    <input
                      type="text"
                      id={`ingredient_${ingredientNumber}`}
                      name={`ingredient_${ingredientNumber}`}
                      value={form.ingredients[`ingredient_${ingredientNumber}`]}
                      onChange={(e) => handleInputChange(e, "ingredients")}
                    />
                    <div
                      className={
                        ingredientNumber > 1
                          ? "handle-ingredient"
                          : "handle-first-ingredient"
                      }
                    >
                      <span
                        className="add-ingredient"
                        onClick={() => handleAddField("ingredient")}
                      >
                        <IconContext.Provider
                          value={{ className: "add-ingredient-icon" }}
                        >
                          <FaPlus />
                        </IconContext.Provider>
                      </span>

                      {ingredientNumber > 1 && (
                        <>
                          <span
                            className="remove-ingredient"
                            onClick={() => handleRemoveField("ingredient")}
                          >
                            <IconContext.Provider
                              value={{ className: "add-ingredient-icon" }}
                            >
                              <IoMdRemove />
                            </IconContext.Provider>
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              className="input-wrapper"
              style={{ direction: languageDirection }}
            >
              <label htmlFor="amount-of-ingredients">
                {t("ingredient_total")}
              </label>
              <input
                type="number"
                id="amount-of-ingredients"
                className="amount-of-ingredients"
                readOnly
                value={amountOfIngredients.length}
              />
            </div>
            {roleRedux === 2 && (
              <>
                <div
                  className="input-wrapper"
                  style={{ direction: languageDirection }}
                >
                  <h5> {t("ingredients_super")} </h5>
                  <Select
                    onChange={(e) =>
                      handleSelectChanges(e, "ingredientsForSupermarketList")
                    }
                    options={translatedData.ingredientsForSupermarketList}
                    styles={customStyles}
                    placeholder={t("choose_ingredients")}
                    isMulti
                    value={form.ingredientsForSupermarketList}
                  />
                </div>
              </>
            )}

            <div
              className="input-wrapper"
              style={{ direction: languageDirection }}
            >
              <h5>{t("preparation")}</h5>

              {amountOfSteps.map((stepNumber) => {
                return (
                  <div className="step" key={stepNumber}>
                    <label htmlFor={`step_${stepNumber}`}>{`${t(
                      "step"
                    )}  ${stepNumber}:`}</label>
                    <textarea
                      id={`step_${stepNumber}`}
                      name={`step_${stepNumber}`}
                      value={form.preparation[`step_${stepNumber}`]}
                      onChange={(e) => handleInputChange(e, "preparation")}
                    />
                    <div style={{ marginBottom: "20px" }}>
                      <span
                        className="add-step"
                        onClick={() => handleAddField("preparation")}
                      >
                        <IconContext.Provider
                          value={{ className: "add-step-icon" }}
                        >
                          <FaPlus />
                        </IconContext.Provider>
                      </span>

                      {stepNumber > 1 && (
                        <>
                          <span
                            className="remove-step"
                            onClick={() => handleRemoveField("preparation")}
                          >
                            <IconContext.Provider
                              value={{ className: "add-step-icon" }}
                            >
                              <IoMdRemove />
                            </IconContext.Provider>
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            {roleRedux === 2 && (
              <>
                {/*  nutritional values */}
                <div
                  className="input-wrapper"
                  style={{ direction: languageDirection }}
                >
                  <h5>{t("nutritional_values")}</h5>

                  {form?.nutritionalValues.map((nutritionalValue, index) => {
                    return (
                      <div className="nutrient" key={index}>
                        <input
                          type="text"
                          name="nutrient"
                          value={nutritionalValue.nutrient}
                          onChange={(e) =>
                            handleNutritionalValueChange(e, index)
                          }
                          placeholder={t("nutrient")}
                        />
                        <input
                          type="text"
                          name="amount"
                          value={nutritionalValue.amount}
                          onChange={(e) =>
                            handleNutritionalValueChange(e, index)
                          }
                          placeholder={t("amount")}
                        />
                        <input
                          type="text"
                          name="percent"
                          value={nutritionalValue.percent}
                          onChange={(e) =>
                            handleNutritionalValueChange(e, index)
                          }
                          maxLength={3}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            ); // Allow only numbers
                          }}
                          placeholder={t("percentage")}
                        />
                        <div style={{ marginBottom: "20px" }}>
                          <span
                            className="add-step"
                            onClick={() => handleAddField("nutritionalValues")}
                          >
                            <IconContext.Provider
                              value={{ className: "add-step-icon" }}
                            >
                              <FaPlus />
                            </IconContext.Provider>
                          </span>

                          {form?.nutritionalValues?.length > 1 && (
                            <>
                              <span
                                className="remove-step"
                                onClick={() =>
                                  handleRemoveField("nutritionalValues", index)
                                }
                              >
                                <IconContext.Provider
                                  value={{ className: "add-step-icon" }}
                                >
                                  <IoMdRemove />
                                </IconContext.Provider>
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
            <div
              className="input-wrapper upload"
              style={{ direction: languageDirection }}
            >
              <label htmlFor="recipe-image">{t("recipe_image")} </label>

              <div className="upload-icon-wrapper">
                <input
                  type="file"
                  hidden
                  ref={uploadInputRef}
                  id="recipe-image"
                  onChange={handleFileUpload}
                />
                <span
                  onClick={() => {
                    uploadInputRef.current.click();
                  }}
                >
                  <IconContext.Provider
                    value={{
                      className: "upload-icon",
                      color: "#000",
                    }}
                  >
                    <BsUpload />
                  </IconContext.Provider>
                </span>

                {recipeImage && (
                  <span onClick={handleFileRemove}>
                    <IconContext.Provider
                      value={{ className: "remove-icon", color: "red" }}
                    >
                      <RiDeleteBin6Line />
                    </IconContext.Provider>
                  </span>
                )}
              </div>
            </div>
            {/* only regular users assign recipe */}
            {roleRedux === 1 && (
              <div
                className="input-wrapper"
                style={{ direction: languageDirection }}
              >
                <label htmlFor="life-style">{t("assign_recipe")}</label>
                <Select
                  onChange={(e) => handleSelectChanges(e, "profiles")}
                  options={householdProfiles}
                  styles={customStyles}
                  value={form.profiles}
                  placeholder={t("choose_profile")}
                  isMulti
                />
              </div>
            )}

            <div
              className="input-wrapper"
              style={{ direction: languageDirection }}
            >
              <label htmlFor="notes">{t("notes")}</label>
              <textarea
                id="notes"
                name="notes"
                onChange={handleInputChange}
                value={form.notes}
              />
            </div>
          </form>
          <div className="submit-form">
            <button type="submit" onClick={submitRecipe}>
              {t("submit")}
            </button>
          </div>
        </div>
      </div>
      <FooterDesktop />
    </>
  );
};

export default RecipePanel;
