import left from "../images/arrow-left.png";
import right from "../images/arrow-right.png";
import avatar from "../images/carousel/2.png";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { IconContext } from "react-icons";
import carousel from "../images/carousel/1.jpg";
import "./styles/carousel.css";
import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import sliceTextToMaxLength from "../utils/sliceTextToMaxLength";
import { useSelector, useDispatch } from "react-redux";
import { TiEdit } from "react-icons/ti";
import { MdOutlineDeleteForever } from "react-icons/md";
import {
  setDeletionPopupOpenRedux,
  setCurrentArticle,
  setItemForDeletion,
  setDeletionPopupContext,
} from "../redux/reducer";
import imageBaseUrl from "../config";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const CarouselComponent = () => {
  const mobile = useMediaQuery({ maxWidth: 800 });
  const [articles, setArticles] = useState([]);

  const articlesRedux = useSelector((state) => state.mainSlice.articles);
  const roleRedux = useSelector((state) => state.mainSlice.role);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  useEffect(() => {
    if (articlesRedux?.length) {
      if (mobile) {
        setArticles(articlesRedux.slice(0, 1));
      } else {
        setArticles(articlesRedux.slice(0, 3));
      }
    } else {
      setArticles([]);
    }
  }, [articlesRedux]);

  const handleCarouselMobile = (direction, currentItemId) => {
    const currentIndex = articlesRedux.findIndex(
      (item) => item.id === currentItemId
    );

    if (currentIndex === -1) {
      // Handle the case where the item with the currentItemId is not found
      console.log("Item not found");
      return;
    }

    if (direction === "right") {
      // If currentIndex is at the last index, move to the first element
      if (currentIndex === articlesRedux.length - 1) {
        setArticles([articlesRedux[0]]);
      } else {
        // Otherwise, move to the next element
        setArticles([articlesRedux[currentIndex + 1]]);
      }
    } else {
      // If currentIndex is at the first index, move to the last element
      if (currentIndex === 0) {
        setArticles([articlesRedux[articlesRedux.length - 1]]);
      } else {
        // Otherwise, move to the previous element
        setArticles([articlesRedux[currentIndex - 1]]);
      }
    }
  };

  const handleEdit = (article) => {
    const item = { ...article, context: "article" };
    dispatch(setCurrentArticle(item));
    navigate("/edit-article");
  };
  const handleDelete = (article) => {
    dispatch(setDeletionPopupContext("article"));
    dispatch(setItemForDeletion(article));
    dispatch(setDeletionPopupOpenRedux(true));
  };
  return (
    <>
      {mobile ? (
        <div className="carousel-container">
          <div className="carousel-title"> {t("diet_zone")} </div>

          {articles?.length > 0 && (
            <>
              {articles?.map((article) => {
                return (
                  <div className="carousel-main" key={article.id}>
                    <div
                      className="arrow-left"
                      onClick={() => handleCarouselMobile("left", article.id)}
                    >
                      <img src={left} alt="" />
                    </div>
                    <div className="carousel-content">
                      <div className="blog-image">
                        <img
                          src={`${imageBaseUrl}article-image/${article.main_image}`}
                          alt=""
                          className="blog-main-image"
                        />
                        <img
                          src={`${imageBaseUrl}article-image/${article.secondary_image}`}
                          alt=""
                          className="blog-secondary-image"
                        />
                      </div>

                      <div
                        className="blog-bottom"
                        style={{ direction: languageDirection }}
                      >
                        <div className="blog-title">{article.title}</div>
                        <div className="line"></div>
                        <div className="blog-content">
                          {article.content.length > 140
                            ? `${sliceTextToMaxLength(article.content, 140)}...`
                            : article.content}
                        </div>
                        <div className="read-more">
                          <span
                            className="go-to-post"
                            onClick={() => window.open(article.link)}
                          >
                            {t("read_more")}
                          </span>
                          <span>
                            <IconContext.Provider
                              value={{ className: "arrow-icon" }}
                            >
                              {languageDirection === "rtl" ? (
                                <BiChevronLeft style={{ fontSize: "16px" }} />
                              ) : (
                                <BiChevronRight style={{ fontSize: "16px" }} />
                              )}
                            </IconContext.Provider>
                          </span>
                        </div>
                        {roleRedux === 2 && (
                          <>
                            <div className="admin-article mobile">
                              <span onClick={() => handleEdit(article)}>
                                <IconContext.Provider
                                  value={{ className: "edit-icon" }}
                                >
                                  <TiEdit style={{ fontSize: "20px" }} />
                                </IconContext.Provider>
                              </span>
                              <span onClick={() => handleDelete(article)}>
                                <IconContext.Provider
                                  value={{ className: "delete-icon" }}
                                >
                                  <MdOutlineDeleteForever
                                    style={{ fontSize: "20px" }}
                                  />
                                </IconContext.Provider>
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div
                      className="arrow-right"
                      onClick={() => handleCarouselMobile("right", article.id)}
                    >
                      <img src={right} alt="" />
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      ) : (
        <>
          <div className="carousel-container-desktop">
            {articles?.length > 0 && (
              <>
                {articles?.map((article) => {
                  return (
                    <div className="carousel-content" key={article.id}>
                      <div className="blog-image desktop">
                        <img
                          src={`${imageBaseUrl}article-image/${article.main_image}`}
                          alt=""
                          className="blog-main-image-desktop"
                        />
                        <img
                          src={`${imageBaseUrl}article-image/${article.secondary_image}`}
                          alt=""
                          className="blog-secondary-image-desktop"
                        />
                      </div>

                      <div
                        className="blog-bottom desktop"
                        style={{ direction: languageDirection }}
                      >
                        <div
                          className="blog-title desktop"
                          style={{ padding: "0 10px " }}
                        >
                          {sliceTextToMaxLength(article.title, 40)}
                        </div>
                        <div className="line"></div>
                        <div className="blog-content desktop">
                          {article.content.length > 100
                            ? `${sliceTextToMaxLength(article.content, 100)}...`
                            : article.content}
                        </div>
                        <div className="read-more desktop">
                          <span
                            className="go-to-post"
                            onClick={() => window.open(article.link)}
                          >
                            {t("read_more")}
                          </span>
                          <span>
                            <IconContext.Provider
                              value={{ className: "arrow-icon" }}
                            >
                              {languageDirection === "rtl" ? (
                                <BiChevronLeft />
                              ) : (
                                <BiChevronRight />
                              )}
                            </IconContext.Provider>
                          </span>
                        </div>
                        {roleRedux === 2 && (
                          <>
                            <div className="admin-article">
                              <span onClick={() => handleEdit(article)}>
                                <IconContext.Provider
                                  value={{ className: "edit-icon" }}
                                >
                                  <TiEdit style={{ fontSize: "20px" }} />
                                </IconContext.Provider>
                              </span>
                              <span onClick={() => handleDelete(article)}>
                                <IconContext.Provider
                                  value={{ className: "delete-icon" }}
                                >
                                  <MdOutlineDeleteForever
                                    style={{ fontSize: "20px" }}
                                  />
                                </IconContext.Provider>
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default CarouselComponent;
