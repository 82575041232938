import { useMediaQuery } from "react-responsive";
import HeaderDesktop from "./header-desktop";
import HeaderMobile from "./header-mobile";
import "./styles/search.css";
import YellowSeparator from "./yellow-separator";
import startSearch from "../images/desktop/search.png";
import { useState, useEffect, useRef } from "react";
import { IconContext } from "react-icons";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import {
  MdOutlineKeyboardArrowUp,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setSearchResultsRedux,
  setAllergyDisclaimerPopupOpenRedux,
} from "../redux/reducer";
import Select from "react-select";
import generalData from "../utils/static-data";
import { AiOutlineCloseCircle } from "react-icons/ai";
import recipeService from "../services/recipeService";
import FooterDesktop from "./footer-desktop";
import { useTranslation } from "react-i18next";
const Search = () => {
  const mobile = useMediaQuery({ maxWidth: 800 });
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const [searchStep, setSearchStep] = useState(1);
  const [searchContext, setSearchContext] = useState("");
  const [secondSelection, setSecondSelection] = useState("");
  const [secondSelectionOptions, setSecondSelectionOptions] = useState([]);

  const [firstSelectOpen, setFirstSelectOpen] = useState(false);
  const [secondSelectOpen, setSecondSelectOpen] = useState(false);
  const [submitBtn, setSubmitBtn] = useState(false);
  const [rangeValue, setRangeValue] = useState({
    minimum: 5,
    maximum: 100,
  });
  const [error, setError] = useState("");
  const [currentProfile, setCurrentProfile] = useState(null);
  const [profileAllergies, setProfileAllergies] = useState([]);
  const [allergyDisclaimer, setAllergyDisclaimer] = useState(false);
  const currentProfileRedux = useSelector(
    (state) => state.mainSlice.currentChildProfile
  );

  const translatedAllergies = generalData.allergies.map((item) => {
    return {
      value: item.value,
      label: `${t(item.label)}`,
    };
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const secondSelectRef = useRef(null);
  useEffect(() => {
    if (!currentProfileRedux?.id) {
      navigate("/");
    } else {
      // check language

      setCurrentProfile(currentProfileRedux);
      setProfileAllergies([]);
      // check if profile has allergies
      if (
        currentProfileRedux.childAllergies !== null &&
        currentProfileRedux.childAllergies !== ""
      ) {
        let allergies = [];
        if (currentProfileRedux.childAllergies.includes(",")) {
          // if more than one allergy
          allergies = currentProfileRedux.childAllergies.split(",");

          // setProfileAllergies(allergies);
        } else {
          // if only one allergy
          allergies.push(currentProfileRedux.childAllergies);
          // setProfileAllergies([currentProfileRedux.allergies]);
        }
        // map allergies to general data to extract the id of the allergy
        if (allergies.length) {
          let temp = [];
          allergies.map((allergy) => {
            generalData.allergies.forEach((item) => {
              if (item.label === allergy) {
                temp.push(item);
              }
            });
          });
          if (temp.length) {
            // set allergies
            setProfileAllergies(temp);
          }
        }
      }
    }
  }, [currentProfileRedux]);

  useEffect(() => {
    if (searchContext !== "") {
      if (searchContext === "cookingTime") {
        setSearchStep(3);
        setSubmitBtn(true);
      } else {
        /*  assign the data to display in the second select  */
        console.log(generalData[searchContext], "generalData[searchContext]");

        setSecondSelectionOptions(generalData[searchContext]);
        setSearchStep(2);
      }
    }
  }, [searchContext]);

  useEffect(() => {
    if (secondSelection !== "") {
      setSubmitBtn(true);
    }
  }, [secondSelection]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "none",
      width: "50vw",
      maxWidth: mobile ? "220px" : "270px",
      borderRadius: "4px",
      borderBottom: "2px solid #3C4964",
      marginBottom: "10px",
      "&:hover": {
        borderBottom: "2px solid #3C4964", // Remove the border on hover
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none", // Hide the indicators separator (the vertical line)
    }),
    ValueContainer: (provided) => ({
      ...provided,
      border: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#3C4964",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#3C4964",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#F8F9FA", // Set your desired background color for the dropdown menu
      color: "#3C4964", // Set the text color for options in the dropdown menu
    }),
  };

  const handleAddAllergy = (e) => {
    const selectedValue = e.value;

    if (selectedValue === 8) {
      // Set state to "None" alone
      setProfileAllergies([e]);
      return;
    } else {
      // Check if the selected value already exists in profileAllergies
      const valueExists = profileAllergies.some(
        (allergy) => allergy.value === selectedValue
      );

      if (!valueExists) {
        // Remove "None" if it exists and add the selected value to profileAllergies
        const updatedProfileAllergies = profileAllergies.filter(
          (allergy) => allergy.value !== selectedValue && allergy.value !== 8
        );

        // Add the selected value to profileAllergies

        setProfileAllergies([...updatedProfileAllergies, e]);
      }
    }
  };
  const handleRemoveAllergy = (allergy) => {
    if (!allergyDisclaimer) {
      dispatch(setAllergyDisclaimerPopupOpenRedux(true));
      setAllergyDisclaimer(true);
      return;
    }

    if (profileAllergies.length === 1) {
      /* removed all we add None */

      setProfileAllergies([{ label: "None", value: 8 }]);
    } else {
      const updatedProfileAllergies = profileAllergies.filter(
        (item) => item.value !== allergy.value
      );
      setProfileAllergies(updatedProfileAllergies);
    }
  };

  const handleRangeChange = (e) => {
    const name = e.target.name;
    const value = parseInt(e.target.value, 10);

    if (name === "minimum") {
      // Ensure that minimum is at least 5 minutes less than maximum
      if (value + 5 <= rangeValue.maximum) {
        setRangeValue({ ...rangeValue, minimum: value });
      }
    } else if (name === "maximum") {
      // Ensure that maximum is at least 5 minutes more than minimum
      if (value - 5 >= rangeValue.minimum) {
        setRangeValue({ ...rangeValue, maximum: value });
      }
    }
  };
  const handleSearch = async () => {
    console.log(searchContext, "searchContext");
    console.log(secondSelection, "secondSelection");
    console.log(profileAllergies, "profileAllergies");

    if (!submitBtn) return;
    setError("");
    if (searchContext !== "cookingTime" && secondSelection === "") {
      setError(t("Please select an option"));
      return;
    }

    try {
      let search = null;
      let context = null;
      if (searchContext === "foods") {
        context = "main_dish_id";
        search = secondSelection;
      } else if (searchContext === "diets") {
        context = "life_style";
        search = secondSelection;
      } else {
        context = "cooking_time";
        search = rangeValue;
      }
      const allergies = profileAllergies;
      const language = i18n.language;
      const searchProperties = {
        context,
        search,
        allergies,
        language,
      };

      const searchResponse = await recipeService.searchRecipes(
        searchProperties
      );
      console.log(searchResponse, "searchResponse");
      if (searchResponse.message === "success") {
        dispatch(setSearchResultsRedux(searchResponse.recipes));
        navigate("/search-results");
      } else if (searchResponse.message === "No recipes match your search") {
        setError(t(searchResponse.message));
        return;
      } else {
        setError(t("Something went wrong, please try again later"));
        console.log(searchResponse, "searchResponse");
      }
    } catch (err) {
      console.log(err, "error searching recipes");
    }
  };
  return (
    <>
      <div
        className="search-container"
        style={{ alignItems: mobile ? "center" : "initial" }}
      >
        {mobile ? (
          <>
            <HeaderMobile location="search" />
            <YellowSeparator />
          </>
        ) : (
          <HeaderDesktop />
        )}
        <div className="title" style={{ direction: languageDirection }}>
          {t("Search recipe")}
        </div>

        <div className="search-steps">
          {searchStep > 0 && (
            <>
              <div
                className="select-wrapper"
                style={{
                  maxWidth: mobile ? "290px" : "350px",
                  // flexDirection: "row-reverse",
                }}
              >
                <select
                  style={{ direction: languageDirection }}
                  name=""
                  id="step1"
                  value={searchContext}
                  onClick={() => setFirstSelectOpen(!firstSelectOpen)}
                  onChange={(e) => setSearchContext(e.target.value)}
                >
                  <option value="" disabled>
                    {t("Select")}
                  </option>
                  <option value="diets">{t("Alternative diet option")}</option>
                  <option value="foods">{t("Food type")}</option>
                  {/* <option value="Allergies">Allergies</option> */}
                  <option value="cookingTime">{t("Cooking time")}</option>
                </select>
                <div
                  className="select-arrow"
                  style={{
                    left: languageDirection === "rtl" ? "10%" : "85%",
                    // right: languageDirection === "ltr" ? "-90%" : "0",
                  }}
                >
                  <span>
                    <IconContext.Provider value={{ size: "25px" }}>
                      {firstSelectOpen ? (
                        <MdOutlineKeyboardArrowUp
                          style={{ color: "#3C4964" }}
                        />
                      ) : (
                        <MdOutlineKeyboardArrowDown
                          style={{ color: "#3C4964" }}
                        />
                      )}
                    </IconContext.Provider>
                  </span>
                </div>
              </div>
            </>
          )}

          {searchStep === 2 && (
            <>
              <div
                className="select-wrapper"
                style={{ maxWidth: mobile ? "290px" : "350px" }}
              >
                <select
                  style={{ direction: languageDirection }}
                  name=""
                  ref={secondSelectRef}
                  id="step2"
                  onChange={(e) => {
                    setError("");
                    console.log(e.target.value, "e.target.value");
                    setSecondSelection(e.target.value);
                  }}
                  onClick={() => setSecondSelectOpen(!secondSelectOpen)}
                >
                  <option value="">{t("Select")} </option>
                  {secondSelectionOptions?.length > 0 && (
                    <>
                      {secondSelectionOptions.map((item) => {
                        return (
                          <option key={item.value} value={item.value}>
                            {t(item.label)}
                          </option>
                        );
                      })}
                    </>
                  )}
                </select>
                <div
                  className="select-arrow"
                  style={{
                    left: languageDirection === "rtl" ? "10%" : "85%",
                  }}
                >
                  <span>
                    <IconContext.Provider value={{ size: "25px" }}>
                      {secondSelectOpen ? (
                        <MdOutlineKeyboardArrowUp
                          style={{ color: "#3C4964" }}
                        />
                      ) : (
                        <MdOutlineKeyboardArrowDown
                          style={{ color: "#3C4964" }}
                        />
                      )}
                    </IconContext.Provider>
                  </span>
                </div>
              </div>
            </>
          )}
          {searchStep === 3 && (
            <>
              <div className="cooking-wrapper">
                <label htmlFor="minimum">
                  <div style={{ direction: languageDirection }}>{`${
                    rangeValue.minimum
                  } ${t("min")}`}</div>
                  <input
                    type="range"
                    value={rangeValue.minimum}
                    onChange={handleRangeChange}
                    name="minimum"
                    id="minimum"
                    min="5"
                    step="5"
                  />
                </label>
                <label htmlFor="maximum">
                  <input
                    type="range"
                    value={rangeValue.maximum}
                    onChange={handleRangeChange}
                    name="maximum"
                    id="maximum"
                    min="5"
                    max="100"
                    step="5"
                  />
                  <div style={{ direction: languageDirection }}>{`${
                    rangeValue.maximum
                  } ${t("min")}`}</div>
                </label>
              </div>
            </>
          )}

          <div
            className="allergies-search"
            style={{
              maxWidth: mobile ? "290px" : "350px",
              direction: languageDirection,
            }}
          >
            <h2 style={{ direction: languageDirection }}>{t("Allergies")}</h2>

            <span>
              <Select
                options={translatedAllergies}
                styles={customStyles}
                onChange={handleAddAllergy}
                placeholder={t("Select")}
              />
            </span>
            <div className="allergies-display">
              {profileAllergies.length > 0 &&
                profileAllergies.map((allergy) => {
                  return (
                    <div
                      className="allergy"
                      key={allergy.value}
                      onClick={() => handleRemoveAllergy(allergy)}
                    >
                      <span>
                        <IconContext.Provider
                          value={{
                            className: "close-icon",
                            style: { color: "#fff" },
                          }}
                        >
                          <AiOutlineCloseCircle />
                        </IconContext.Provider>
                      </span>
                      <span>{t(`${allergy.label}`)}</span>
                    </div>
                  );
                })}
            </div>
          </div>
          {/* <div className="start-search">
            <img src={startSearch} alt="" />
          </div> */}

          {error && <div className="error">{error}</div>}
          <div
            className="buttons-wrapper"
            style={{
              marginTop: "50px",
              direction: languageDirection,
              marginBottom: mobile ? "50px" : "100px",
            }}
            onClick={handleSearch}
          >
            <button className={submitBtn ? "search-btn" : "disabled"}>
              {t("search")}
            </button>
            <button className="return-btn" onClick={() => navigate("/")}>
              {t("close")}
            </button>
          </div>
        </div>
      </div>
      {!mobile && <FooterDesktop />}
    </>
  );
};

export default Search;
