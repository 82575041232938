const handleInstagramClick = (url) => {
  try {
    const sendToInstagram = (e) => {
      e.preventDefault();
      window.location.href = e.target.getAttribute("href");
    };
    const dummy = document.createElement("a");
    dummy.setAttribute("href", url);
    dummy.setAttribute("target", "_blank");
    dummy.style.display = "none";
    document.body.appendChild(dummy);
    dummy.addEventListener("click", sendToInstagram);
    dummy.click();
    document.body.removeChild(dummy);
  } catch (error) {
    console.error("Error opening URL:", error);
  }
};

const stories = [
  {
    url: "https://www.instagram.com/p/Cy3GztvqTmS/media/?size=l",
    duration: 3000,
    seeMore: () =>
      handleInstagramClick("https://www.instagram.com/p/Cy3GztvqTmS/?hl=en"),
  },
  {
    url: "https://www.instagram.com/p/CxtUX72qIuU/media/?size=l",
    duration: 3000,
    seeMore: () =>
      handleInstagramClick("https://www.instagram.com/p/CxtUX72qIuU/"),
  },
  {
    url: "https://www.instagram.com/p/CxNZsbAKA1e/media/?size=l",
    duration: 3000,
    seeMore: () =>
      handleInstagramClick(
        "https://www.instagram.com/p/CxNZsbAKA1e/?hl=en&img_index=1"
      ),
  },
  {
    url: "https://www.instagram.com/p/Cw64WUFKCoY/media/?size=l",
    duration: 3000,
    seeMore: () =>
      handleInstagramClick(
        "https://www.instagram.com/p/Cw64WUFKCoY/?hl=en&img_index=1"
      ),
  },
  {
    url: "https://www.instagram.com/p/CuKxAX-qtSg/media/?size=l",
    duration: 3000,
    seeMore: () =>
      handleInstagramClick(
        "https://www.instagram.com/p/CuKxAX-qtSg/?hl=en&img_index=1"
      ),
  },
];

export default stories;
