import HeaderDesktop from "./header-desktop";
import HeaderMobile from "./header-mobile";
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BiMessageSquareError } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import { CiStar } from "react-icons/ci";
import { IoMdShare } from "react-icons/io";
import { CiHeart } from "react-icons/ci";
import { IoChevronForwardOutline, IoChevronBackOutline } from "react-icons/io5";
import { FaRedoAlt } from "react-icons/fa";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { FaFireFlameCurved } from "react-icons/fa6";
import { BsExclamationCircleFill } from "react-icons/bs";
import data from "../utils/static-data";
import { MdRemoveCircleOutline } from "react-icons/md";
import recipeImageDesktop from "../images/recipes/recipe-main-desktop.png";
import recipeImagMobile from "../images/recipes/recipe-main-mobile.png";
import StarRatings from "react-star-ratings";
import apple from "../images/apple.png";
import mixer from "../images/mixer.png";
import mainDish from "../images/recipes/main-dish-icon/maindish.png";
import { useSelector, useDispatch } from "react-redux";
import "./styles/recipe-review.css";
import { useNavigate } from "react-router-dom";
import FooterDesktop from "./footer-desktop";
import recipeService from "../services/recipeService";
import {
  setWeeklyRecipesPopupOpenRedux,
  setDeleteRecipePopupOpenRedux,
  setCurrentRecipeRedux,
  setRecipePanelEditMode,
  setGeneralRecipes,
  setPersonalRecipes,
  setNutritionalValuesPopupRedux,
} from "../redux/reducer";

import HealthRateChart from "./healthRate";
import imageBaseUrl from "../config";
import { useTranslation } from "react-i18next";
const RecipeReview = () => {
  const mobile = useMediaQuery({ maxWidth: 800 });
  const mobileBreakPoint500 = useMediaQuery({ maxWidth: 500 });
  const desktopBreakPoint = useMediaQuery({
    query: "(min-width: 801px) and (max-width: 1160px)",
  });
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const [rating, setRating] = useState(0);
  const [recipeHasNutritionalValue, setRecipeHasNutritionalValue] =
    useState(false);
  const [ratingOpen, setRatingOpen] = useState(false);
  const [currentRecipe, setCurrentRecipe] = useState({});
  const [recipeIngredients, setRecipeIngredients] = useState([]);
  const [amountOfIngredients, setAmountOfIngredients] = useState(0);
  const [recipePreparation, setRecipePreparation] = useState([]);
  const [selectedButton, setSelectedButton] = useState("");
  const [averageRating, setAverageRating] = useState(0);
  const [recipeIsInFavorites, setRecipeIsInFavorites] = useState(false);
  const [recipeType, setRecipeType] = useState("");
  const [showEditButtons, setShowEditButtons] = useState(false);
  const [healthScoreInWords, setHealthScoreInWords] = useState(null);
  const [allergens, setAllergens] = useState([]);
  const [lockRating, setLockRating] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentRecipeRedux = useSelector(
    (state) => state.mainSlice.currentRecipe
  );
  const currentChildProfile = useSelector(
    (state) => state.mainSlice.currentChildProfile
  );
  const currentProfileRedux = useSelector(
    (state) => state.mainSlice.currentChildProfile
  );
  const myCreatedRecipesRedux = useSelector(
    (state) => state.mainSlice.myCreatedRecipes
  );
  const roleRedux = useSelector((state) => state.mainSlice.role);

  useEffect(() => {
    if (currentRecipeRedux?.id) {
      const fetchRating = async () => {
        const response = await recipeService.getRatings(
          currentChildProfile.id,
          currentRecipeRedux.id
        );
        console.log(response, "fetch rating response");
        if (response.message === "success") {
          // if user already rated this recipe set the rating to that value

          if (response?.rating?.myRating) {
            setRating(response.rating.myRating);
            setLockRating(true);
          } else {
            setRating(0);
            setLockRating(false);
          }
          if (response?.rating?.averageRating) {
            const formattedNumber = Number(
              response?.rating?.averageRating
            ).toFixed(1); // Set the desired number of decimal places (1 in this case)
            const result = parseFloat(formattedNumber); // Convert the formatted string back to a floating-point number

            setAverageRating(result);
          }
        } else {
          setRating(0);
        }
      };

      if (
        (currentRecipeRedux.type === "general" && roleRedux === 2) ||
        (roleRedux === 1 && currentRecipeRedux.type === "personal")
      ) {
        // show edit and delete buttons
        setShowEditButtons(true);
      } else {
        setShowEditButtons(false);
      }

      setRecipeType(currentRecipeRedux.type);
      if (currentRecipeRedux.type === "personal") {
        // if personal recipe disable the rating button
        setRatingOpen(null);
      } else {
        // if general recipe fetch rating
        fetchRating();
      }

      setCurrentRecipe(currentRecipeRedux);
    } else {
      navigate("/");
    }
  }, [currentRecipeRedux]);

  const generateUniqueId = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  useEffect(() => {
    if (currentRecipe?.id) {
      // if recipe has health rate set health rate in words
      const healthScore = parseFloat(currentRecipe.health_rate);
      if (!Number.isNaN(healthScore)) {
        if (healthScore >= 8) {
          setHealthScoreInWords("High");
        } else if (healthScore >= 6) {
          setHealthScoreInWords("Medium");
        } else {
          setHealthScoreInWords("Low");
        }
      } else {
        console.log("healthScore is not a valid number");
        setHealthScoreInWords(null);
      }

      if (
        currentRecipe?.allergies &&
        currentRecipe.allergies !== null &&
        typeof currentRecipe.allergies !== "undefined"
      ) {
        const allergensArray = currentRecipe.allergies.split(",");
        if (Array.isArray(allergensArray) && allergensArray.length > 1) {
          // Multiple allergens
          const allergyNames = data.allergies.filter((allergy) => {
            allergensArray.includes(allergy.value);
          });
          setAllergens(allergyNames);
        } else {
          // Single allergen or no allergens
          const allergyName = data.allergies.find(
            (allergy) => allergy.value === parseFloat(currentRecipe.allergies)
          );
          setAllergens([allergyName]);
        }
      }

      const ingredients = Object.values(
        currentRecipe.recipe_ingredients.ingredients
      );
      if (ingredients.length) {
        setAmountOfIngredients(ingredients.length);
        const addIdToIngredients = ingredients.map((ingredient) => {
          return { id: generateUniqueId(), ingredient: ingredient };
        });
        setRecipeIngredients(addIdToIngredients);
      }

      const preparation = Object.values(currentRecipe.preparation.steps);
      const addIdToPreparation = preparation.map((step) => {
        return { id: generateUniqueId(), step: step };
      });

      if (preparation.length) setRecipePreparation(addIdToPreparation);
    }
  }, [currentRecipe]);

  useEffect(() => {
    if (recipeType === "general") {
      const fetchFavorites = async () => {
        const response = await recipeService.getFavorites(
          currentChildProfile.id
        );

        if (response.message === "success") {
          if (response.favorites.length) {
            const temp = response.favorites.some(
              (favorite) => favorite.recipe_id === currentRecipe.id
            );
            if (temp) {
              setRecipeIsInFavorites(true);
            } else {
              setRecipeIsInFavorites(false);
            }
          }
        } else if (response.message === "No favorites found") {
          // favorites is empty from db
          setRecipeIsInFavorites(false);
        }
      };
      fetchFavorites();
    }
  }, [recipeType]);

  useEffect(() => {
    if (
      currentRecipe?.nutritional_values?.length > 0 &&
      currentRecipe?.nutritional_values[0]?.amount !== ""
    ) {
      setRecipeHasNutritionalValue(true);
    } else {
      setRecipeHasNutritionalValue(false);
    }
  }, [currentRecipe]);
  const handleRatingChange = async () => {
    if (!rating || lockRating) return;

    try {
      const rate = await recipeService.rateRecipe(
        currentChildProfile.id,
        currentRecipe.id,
        rating
      );
      console.log(rate, "rate recipe");
      if (rate.message === "success") {
        // dispatch(setCurrentRecipeRedux(rate.recipe));

        const response = await recipeService.getRatings(
          currentChildProfile.id,
          currentRecipe.id
        );
        if (response.message === "success") {
          // if user already rated this recipe set the rating to that value
          setLockRating(true);

          if (response?.rating?.myRating) {
            setRating(response.rating.myRating);
          }
          if (response?.rating?.averageRating) {
            const formattedNumber = Number(
              response?.rating?.averageRating
            ).toFixed(1); // Set the desired number of decimal places (1 in this case)
            const result = parseFloat(formattedNumber); // Convert the formatted string back to a floating-point number

            setAverageRating(result);
          }
        }
      } else if (rate.message === "User already rated this recipe") {
        setLockRating(true);
        console.log("User already rated this recipe");
      } else {
        console.log(rate, "error rating recipe");
      }
    } catch (err) {
      console.log(err, "error rating recipe");
    }
  };

  const handleBoxIt = () => {
    dispatch(setWeeklyRecipesPopupOpenRedux(true));
  };
  const resetRating = async () => {
    if (rating === 0) return;
    console.log(rating, "rating");
    try {
      const response = await recipeService.resetRating(
        currentChildProfile.id,
        currentRecipe.id
      );
      console.log(response, "reset rating response");
      if (response.message === "success") {
        // dispatch(setCurrentRecipeRedux(response.recipe));
        setRating(0);
        setLockRating(false);
      } else if (response.message === "Only Admin Can Reset Rating") {
        setLockRating(true);
        console.log(response, "Only Admin Can Reset Rating");
      }
    } catch (err) {
      console.log(err, "error reset rating");
    }
  };
  const handleRatingButton = () => {
    if (currentRecipe.type === "personal") return;

    setSelectedButton("rating");
    setRatingOpen(!ratingOpen);
  };
  const handleEditRecipe = () => {
    dispatch(setRecipePanelEditMode(true));
    navigate("/edit-recipe");
  };
  const handleFavoritesClick = async () => {
    if (currentRecipe.type === "personal") return;
    setSelectedButton("favorites");
    setRatingOpen(false);

    // if general recipe add / remove from favorites
    try {
      const response = await recipeService.handleFavorites(
        currentProfileRedux.id,
        currentRecipe.id,
        !recipeIsInFavorites
      );
      if (response.message === "success") {
        const getRecipes = await recipeService.getRecipes(
          currentProfileRedux.id
        );

        if (getRecipes.message === "success") {
          getRecipes.personalRecipes.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );

          getRecipes.generalRecipes.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );

          if (myCreatedRecipesRedux.length) {
            getRecipes.generalRecipes = getRecipes.generalRecipes.filter(
              (recipe) => {
                return !myCreatedRecipesRedux.some(
                  (myRecipe) => myRecipe.id === recipe.id
                );
              }
            );
          }

          dispatch(setGeneralRecipes(getRecipes.generalRecipes));
          dispatch(setPersonalRecipes(getRecipes.personalRecipes));
        }

        setRecipeIsInFavorites(!recipeIsInFavorites);
      }
    } catch (err) {
      console.log(err, "error add / remove from favorite");
    }

    try {
    } catch (err) {
      console.log(err, "error adding favorite");
    }
  };

  const shareRecipe = () => {
    setRatingOpen(false);
    setSelectedButton("share");

    let recipe = `${t("Yoopi Recipe")} \n\n`;
    recipe += `${currentRecipe.name} \n\n`;
    recipe += `${currentRecipe.description} \n\n`;
    recipe += `${t("ingredients_with_colon")}\n`;

    const ingredients = Object.values(
      currentRecipe.recipe_ingredients.ingredients
    );
    ingredients.forEach((ingredient) => {
      recipe += `${ingredient}\n`;
    });

    recipe += `\n`;
    recipe += `${t("Preparation")}\n\n`;

    const preparation = Object.values(currentRecipe.preparation.steps);
    preparation.forEach((step, idx) => {
      recipe += `${t("step")}`;
      recipe += `${idx + 1}:\n${step}\n\n`;
    });
    if (currentRecipe.notes) {
      recipe += `${t("important_note")}\n`;
      recipe += `${currentRecipe.notes}\n`;
    }
    recipe += `\n`;
    recipe += `${t("Enjoy")}`;
    const directionMark = languageDirection === "rtl" ? "\u200F" : "\u200E";
    recipe = directionMark + recipe;

    const whatsappLink = `https://wa.me/?text=${encodeURIComponent(recipe)}`;

    // Add event listener to prevent default behavior
    const openWhatsApp = (event) => {
      event.preventDefault();
      window.location.href = whatsappLink;
    };

    // Create a dummy link element to trigger the event
    const dummyLink = document.createElement("a");
    dummyLink.setAttribute("href", whatsappLink);
    dummyLink.setAttribute("target", "_blank"); // Open in a new window
    dummyLink.style.display = "none";
    document.body.appendChild(dummyLink);

    // Trigger the event
    dummyLink.addEventListener("click", openWhatsApp);
    dummyLink.click();

    // Clean up
    document.body.removeChild(dummyLink);
  };
  useEffect(() => {
    console.log("rating", rating);
    console.log("lockRating", lockRating);
  }, [lockRating]);
  return (
    <>
      <div className="recipe-review-container">
        {mobile ? (
          <>
            <HeaderMobile location="recipe-review" />
            <div className="recipe-review-main">
              <div className="review-image">
                <img
                  src={`${imageBaseUrl}recipe-image/${currentRecipe.image}`}
                  alt=""
                />
              </div>
              <div className="review-title">{currentRecipe.name} </div>

              <div className="review-cooking-prep">
                {` ${t("cooking_time")} ${currentRecipe.cooking_time} ${t(
                  "min"
                )}  |  ${t("prep_time")} ${currentRecipe.prep_time} ${t(
                  "min"
                )}  `}
              </div>

              <div className="review-buttons">
                <button
                  style={{
                    cursor:
                      recipeType === "personal" ? "not-allowed" : "pointer",
                  }}
                  className={
                    selectedButton === "rating"
                      ? "btn-review active"
                      : "btn-review"
                  }
                  onClick={handleRatingButton}
                >
                  <span>
                    <IconContext.Provider value={{ className: "star-icon" }}>
                      {currentRecipe.type === "personal" ? (
                        <MdRemoveCircleOutline className="icon-btn" />
                      ) : (
                        <CiStar className="icon-btn" />
                      )}
                    </IconContext.Provider>
                  </span>
                  <span>{t("rate1")}</span>
                </button>
                <button
                  style={{
                    cursor:
                      recipeType === "personal" ? "not-allowed" : "pointer",
                  }}
                  className={
                    selectedButton === "favorites"
                      ? "btn-review active"
                      : "btn-review"
                  }
                  onClick={() => {
                    setRatingOpen(false);
                    setSelectedButton("favorites");
                    handleFavoritesClick();
                  }}
                >
                  <span>
                    <IconContext.Provider
                      value={{
                        className: "heart-icon",
                        color:
                          mobileBreakPoint500 && recipeIsInFavorites
                            ? "red"
                            : "white",
                      }}
                    >
                      <CiHeart className="icon-btn" />
                    </IconContext.Provider>
                  </span>

                  <span>
                    {`${
                      recipeIsInFavorites
                        ? `${t("remove_from_favorites")}`
                        : `${t("add_to_favorites")}`
                    }  `}
                  </span>
                </button>
                <button
                  className={
                    selectedButton === "share"
                      ? "btn-review active"
                      : "btn-review"
                  }
                  onClick={shareRecipe}
                >
                  <span>
                    <IconContext.Provider value={{ className: "share-icon" }}>
                      <IoMdShare className="icon-btn" />
                    </IconContext.Provider>
                  </span>
                  <span>{t("share")}</span>
                </button>
              </div>
              {ratingOpen && (
                <div className="current-recipe-rate">
                  <StarRatings
                    rating={rating}
                    starRatedColor="#3AA7D5"
                    changeRating={lockRating ? null : setRating}
                    numberOfStars={5}
                    name="rating"
                    value={rating}
                    starDimension={mobileBreakPoint500 ? "20px" : "40px"} // Adjust the size of the stars
                    starSpacing="4px" // Adjust the spacing between stars
                    starFullColor="#3AA7D5" // Color for the selected star
                    starEmptyColor="#ccc" // Color for the empty stars
                    starHoverColor="#3AA7D5" // Color for stars on hover
                    className="star-ratings" // Apply your custom CSS class here
                  />

                  <div className="average-rating">
                    {/* fetch average score to display  */}
                    {`${t("average_recipe_score")} ${averageRating}`}
                  </div>
                  <div
                    style={{
                      direction: languageDirection,
                      backgroundColor: lockRating ? "#71BC00" : "",
                    }}
                    className="rate-recipe"
                    onClick={handleRatingChange}
                  >
                    {lockRating ? `${t("thanks")}` : `${t("rate recipe")}`}
                  </div>

                  {roleRedux === 2 && (
                    <>
                      <div className="reset-rating" onClick={resetRating}>
                        {/* <span>
                          <IconContext.Provider
                            value={{ className: "reset-icon", size: "0.8rem" }}
                          >
                            <FaRedoAlt className="icon-btn" />
                          </IconContext.Provider>
                        </span> */}
                        <div>{t("reset_rating")}</div>
                      </div>
                    </>
                  )}
                </div>
              )}
              <div
                className="recipe-description"
                style={{ direction: languageDirection }}
              >
                {currentRecipe.description}
              </div>

              {currentRecipe.type === "general" && (
                <>
                  <div className="recipe-health-rate">
                    <div>
                      <HealthRateChart
                        value={currentRecipe.health_rate}
                        chartColor={
                          healthScoreInWords === "High"
                            ? "#95bf3e"
                            : healthScoreInWords === "Medium"
                            ? "#f6dd38"
                            : "#fdb72c"
                        }
                      />
                    </div>
                    <div className="score-in-words">
                      {healthScoreInWords ? (
                        <>
                          <span>{t("health_score")}</span>
                          <span
                            className="health-score-in-words"
                            style={{
                              backgroundColor:
                                healthScoreInWords === "High"
                                  ? "#95bf3e"
                                  : healthScoreInWords === "Medium"
                                  ? "#f6dd38"
                                  : "#fdb72c",
                            }}
                          >
                            {t(`${healthScoreInWords}`)}
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="health-data">
                    {currentRecipe?.calories && (
                      <div
                        className="calories"
                        style={{
                          flexDirection:
                            languageDirection === "rtl" ? "row-reverse" : "row",
                          margin:
                            languageDirection === "rtl"
                              ? "0 10% 0 0"
                              : "0 0 0 10%",
                        }}
                      >
                        <span>
                          <IconContext.Provider
                            value={{ className: "calories-icon" }}
                          >
                            <FaFireFlameCurved
                              className="icon-btn"
                              style={{
                                marginBottom: "3px",
                                color: "#fdb813",
                              }}
                            />
                          </IconContext.Provider>
                        </span>
                        <span style={{ direction: languageDirection }}>
                          {`${parseFloat(currentRecipe?.calories)} ${t(
                            "calories_per_serving"
                          )}`}
                        </span>
                      </div>
                    )}

                    {currentRecipe?.allergens_may_contain !== null &&
                      currentRecipe?.allergens_may_contain !== "null" && (
                        <div
                          className="allergens"
                          style={{
                            flexDirection:
                              languageDirection === "rtl"
                                ? "row-reverse"
                                : "row",
                            margin:
                              languageDirection === "rtl"
                                ? "0 10% 0 0"
                                : "0 0 0 10%",
                          }}
                        >
                          <span>
                            <IconContext.Provider
                              value={{ className: "allergens-icon" }}
                            >
                              <BsExclamationCircleFill
                                className="icon-btn"
                                style={{
                                  marginBottom: "3px",
                                  color: "#FD0303",
                                }}
                              />
                            </IconContext.Provider>
                          </span>

                          <>
                            <span
                              style={{ direction: languageDirection }}
                            >{`${t("recipe_may_contain")} ${
                              currentRecipe?.allergens_may_contain
                            }`}</span>
                          </>
                        </div>
                      )}
                  </div>
                  <div className="nutrition-info">
                    {recipeHasNutritionalValue && (
                      <>
                        <div
                          className="recipe-nutritional-value"
                          onClick={() =>
                            dispatch(setNutritionalValuesPopupRedux(true))
                          }
                        >
                          {t("more_nutrition_information")}
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}

              {currentRecipe.type === "personal" && (
                <>
                  <div
                    className="recipe-main-dish"
                    style={{ direction: languageDirection }}
                  >
                    <div className="title">
                      <img src={mainDish} alt="" />

                      <h2>{t("main_dish")}</h2>
                    </div>
                    <ul
                      className="main-dish"
                      style={{
                        padding:
                          languageDirection === "rtl"
                            ? "0 1em 0 0"
                            : "0 0 0 1rem",
                      }}
                    >
                      <li>{currentRecipe.main_dish_name}</li>
                    </ul>
                  </div>
                </>
              )}

              <div
                className="recipe-ingredients"
                style={{ direction: languageDirection }}
              >
                <div className="title">
                  <img src={apple} alt="" />

                  <h2>
                    {`${amountOfIngredients} 
                     ${t("ingredients_with_colon")}`}
                  </h2>
                </div>
                <ul
                  className="recipe-ingredients-list"
                  style={{
                    padding:
                      languageDirection === "rtl" ? "0 1em 0 0" : "0 0 0 1rem",
                  }}
                >
                  {recipeIngredients.length > 0 && (
                    <>
                      {recipeIngredients.map((ingredient) => {
                        return (
                          <li key={ingredient.id}>
                            <span>{ingredient.ingredient}</span>
                          </li>
                        );
                      })}
                    </>
                  )}
                </ul>
              </div>
              <div
                className="recipe-preparation"
                style={{ direction: languageDirection }}
              >
                <div className="title">
                  <img src={mixer} alt="" />

                  <h2>{t("how_to")}</h2>
                </div>
                <ol className="preparation-steps">
                  {recipePreparation.length > 0 && (
                    <>
                      {recipePreparation.map((step, idx) => {
                        return (
                          <li className="step" key={step.id}>
                            <span className="step-number">{`${t("STEP")} ${
                              idx + 1
                            }:`}</span>
                            <br />
                            <span
                              dangerouslySetInnerHTML={{
                                __html: step.step.replace(/\n/g, "<br/>"),
                              }}
                            ></span>
                          </li>
                        );
                      })}
                    </>
                  )}
                </ol>
              </div>
              {currentRecipe.notes && (
                <div
                  className="recipe-notes"
                  style={{
                    marginBottom:
                      currentRecipe.type === "personal" || roleRedux > 1
                        ? "30px"
                        : "100px",
                    direction: languageDirection,
                  }}
                >
                  <div className="title">
                    <span>
                      <IconContext.Provider value={{ className: "notes-icon" }}>
                        <BiMessageSquareError
                          className="icon-btn"
                          style={{
                            fontSize: "25px",
                            marginBottom: "7px",
                            color: "#3AA7D5",
                          }}
                        />
                      </IconContext.Provider>
                    </span>
                    <h2>{t("important_note")}</h2>
                  </div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: currentRecipe.notes.replace(/\n/g, "<br/>"),
                    }}
                  ></p>
                </div>
              )}

              <div className="recipe-buttons-mobile">
                {showEditButtons && (
                  <>
                    <div
                      className="delete-recipe"
                      onClick={() =>
                        dispatch(setDeleteRecipePopupOpenRedux(true))
                      }
                    >
                      <span>
                        <IconContext.Provider
                          value={{ className: "delete-icon" }}
                        >
                          <RiDeleteBin6Line
                            className="icon-btn"
                            style={{ marginBottom: "3px" }}
                          />
                        </IconContext.Provider>
                      </span>
                      <span>{t("delete")} </span>
                    </div>
                    <div className="edit-recipe" onClick={handleEditRecipe}>
                      <span>
                        <IconContext.Provider
                          value={{ className: "edit-icon" }}
                        >
                          <FaEdit
                            className="icon-btn"
                            style={{ marginBottom: "3px" }}
                          />
                        </IconContext.Provider>
                      </span>
                      <span>{t("edit")} </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <HeaderDesktop />
            <div className="recipe-review-container-desktop">
              <div
                className="recipe-review-desktop-title"
                style={{ direction: languageDirection }}
              >
                {currentRecipe.name}
              </div>
              <div
                className="recipe-review-desktop-cooking-prep"
                style={{
                  marginLeft: languageDirection === "rtl" ? "7%" : "0",
                  direction: languageDirection,
                }}
              >
                {` ${t("cooking_time")} ${currentRecipe.cooking_time} ${t(
                  "min"
                )}  |  ${t("prep_time")} ${currentRecipe.prep_time} ${t(
                  "min"
                )}  `}
              </div>
              <div
                className="recipe-review-main-desktop"
                style={{ direction: languageDirection }}
              >
                <div className="left">
                  <div className="buttons-wrapper-desktop">
                    <div className="review-buttons-desktop">
                      <button
                        style={{
                          cursor:
                            recipeType === "personal"
                              ? "not-allowed"
                              : "pointer",
                        }}
                        className={
                          selectedButton === "rating"
                            ? "btn-review active"
                            : "btn-review"
                        }
                        onClick={handleRatingButton}
                      >
                        <span>
                          <IconContext.Provider
                            value={{ className: "star-icon" }}
                          >
                            {currentRecipe.type === "personal" ? (
                              <MdRemoveCircleOutline className="icon-btn" />
                            ) : (
                              <CiStar className="icon-btn" />
                            )}
                          </IconContext.Provider>
                        </span>
                        <span>{t("rate1")}</span>
                      </button>
                      <button
                        style={{
                          cursor:
                            recipeType === "personal"
                              ? "not-allowed"
                              : "pointer",
                        }}
                        className={
                          selectedButton === "favorites"
                            ? "btn-review active"
                            : "btn-review"
                        }
                        onClick={handleFavoritesClick}
                      >
                        <span>
                          <IconContext.Provider
                            value={{ className: "heart-icon" }}
                          >
                            <CiHeart className="icon-btn" />
                          </IconContext.Provider>
                        </span>
                        <span>
                          {`${
                            recipeIsInFavorites
                              ? `${t("remove_from_favorites")}`
                              : `${t("add_to_favorites")}`
                          }  `}
                        </span>
                      </button>
                      <button
                        className={
                          selectedButton === "share"
                            ? "btn-review active"
                            : "btn-review"
                        }
                        onClick={shareRecipe}
                      >
                        <span>
                          <IconContext.Provider
                            value={{ className: "share-icon" }}
                          >
                            <IoMdShare className="icon-btn" />
                          </IconContext.Provider>
                        </span>
                        <span>{t("share")}</span>
                      </button>
                    </div>
                    <div className="box-it-desktop" onClick={handleBoxIt}>
                      <span>{t("box_it")}</span>
                      <span>
                        <IconContext.Provider
                          value={{ className: "arrow-icon" }}
                        >
                          {languageDirection === "rtl" ? (
                            <IoChevronBackOutline />
                          ) : (
                            <IoChevronForwardOutline />
                          )}
                        </IconContext.Provider>
                      </span>
                    </div>
                  </div>
                  {ratingOpen && (
                    <div className="current-recipe-rate-desktop">
                      <StarRatings
                        rating={rating}
                        starRatedColor="#3AA7D5"
                        changeRating={lockRating ? null : setRating}
                        numberOfStars={5}
                        value={rating}
                        name="rating"
                        starDimension={desktopBreakPoint ? "25px" : "40px"} // Adjust the size of the stars
                        starSpacing="4px" // Adjust the spacing between stars
                        starFullColor="#3AA7D5" // Color for the selected star
                        starEmptyColor="#ccc" // Color for the empty stars
                        starHoverColor="#3AA7D5" // Color for stars on hover
                        className="star-ratings" // Apply your custom CSS class here
                      />
                      <div className="average-rating">
                        {/* fetch average score to display  */}
                        {`${t("average_recipe_score")} ${averageRating}`}
                      </div>
                      <div
                        style={{
                          direction: languageDirection,
                          backgroundColor: lockRating ? "#71BC00" : "",
                        }}
                        className="rate-recipe"
                        onClick={handleRatingChange}
                      >
                        {lockRating ? `${t("thanks")}` : `${t("rate recipe")}`}
                      </div>
                      {roleRedux === 2 && (
                        <>
                          <div className="reset-rating" onClick={resetRating}>
                            {/* <span>
                              <IconContext.Provider
                                value={{
                                  className: "reset-icon",
                                  size: "0.7rem",
                                }}
                              >
                                <FaRedoAlt className="icon-btn" />
                              </IconContext.Provider>
                            </span> */}
                            <div> {t("reset_rating")}</div>
                          </div>
                        </>
                      )}
                    </div>
                  )}

                  <div
                    className="recipe-description-desktop"
                    style={{
                      direction: languageDirection,
                      textAlign: languageDirection === "rtl" ? "right" : "left",
                    }}
                  >
                    {currentRecipe.description}
                  </div>
                  {currentRecipe.type === "general" && (
                    <>
                      <div className="recipe-health-rate-desktop">
                        <div>
                          <HealthRateChart
                            value={currentRecipe.health_rate}
                            chartColor={
                              healthScoreInWords === "High"
                                ? "#95bf3e"
                                : healthScoreInWords === "Medium"
                                ? "#f6dd38"
                                : "#fdb72c"
                            }
                          />
                        </div>
                        <div className="score-in-words">
                          {healthScoreInWords ? (
                            <>
                              <span className="nutrition_rate">
                                {t("health_score")}
                              </span>
                              <span
                                className="health-score-in-words"
                                style={{
                                  backgroundColor:
                                    healthScoreInWords === "High"
                                      ? "#95bf3e"
                                      : healthScoreInWords === "Medium"
                                      ? "#f6dd38"
                                      : "#fdb72c",
                                }}
                              >
                                {t(`${healthScoreInWords}`)}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="health-data">
                          {currentRecipe?.calories && (
                            <div
                              className="calories"
                              style={{
                                flexDirection:
                                  languageDirection === "rtl"
                                    ? "row"
                                    : "row-reverse",
                              }}
                            >
                              <>
                                <span>
                                  <IconContext.Provider
                                    value={{ className: "calories-icon" }}
                                  >
                                    <FaFireFlameCurved
                                      className="icon-btn"
                                      style={{
                                        marginBottom: "3px",
                                        color: "#fdb813",
                                      }}
                                    />
                                  </IconContext.Provider>
                                </span>
                                <span style={{ direction: languageDirection }}>
                                  {`${parseFloat(currentRecipe?.calories)} ${t(
                                    "calories_per_serving"
                                  )}`}
                                </span>
                              </>
                            </div>
                          )}

                          {currentRecipe?.allergens_may_contain !== null &&
                            currentRecipe?.allergens_may_contain !== "null" && (
                              <div
                                className="allergens"
                                style={{
                                  flexDirection:
                                    languageDirection === "rtl"
                                      ? "row-reverse"
                                      : "row",
                                }}
                              >
                                {languageDirection === "rtl" ? (
                                  <>
                                    <span
                                      style={{ direction: languageDirection }}
                                    >
                                      {`${t("recipe_may_contain")} ${
                                        currentRecipe?.allergens_may_contain
                                      }`}
                                    </span>
                                    <span>
                                      <IconContext.Provider
                                        value={{ className: "allergens-icon" }}
                                      >
                                        <BsExclamationCircleFill
                                          className="icon-btn"
                                          style={{
                                            marginBottom: "3px",
                                            color: "#FD0303",
                                          }}
                                        />
                                      </IconContext.Provider>
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span>
                                      <IconContext.Provider
                                        value={{ className: "allergens-icon" }}
                                      >
                                        <BsExclamationCircleFill
                                          className="icon-btn"
                                          style={{
                                            marginBottom: "3px",
                                            color: "#FD0303",
                                          }}
                                        />
                                      </IconContext.Provider>
                                    </span>
                                    <span>{`${
                                      currentRecipe?.allergens_may_contain
                                    } ${t("recipe_may_contain")}`}</span>
                                  </>
                                )}
                              </div>
                            )}
                        </div>
                      </div>

                      {recipeHasNutritionalValue && (
                        <>
                          <div
                            style={{
                              textAlign:
                                languageDirection === "rtl" ? "center" : "left",
                              // marginRight:
                              //   languageDirection === "rtl" ? "18%" : "0",
                            }}
                            className="recipe-nutritional-value-desktop"
                            onClick={() =>
                              dispatch(setNutritionalValuesPopupRedux(true))
                            }
                          >
                            {t("more_nutrition_information")}
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {currentRecipe.type === "personal" && (
                    <>
                      <div className="recipe-main-dish-desktop">
                        <div className="title">
                          <img src={mainDish} alt="" />

                          <h2>{t("main_dish")}</h2>
                        </div>
                        <ul
                          className="main-dish-desktop"
                          style={{
                            padding:
                              languageDirection === "rtl"
                                ? "0 1em 0 0"
                                : "0 0 0 1rem",
                          }}
                        >
                          <li>{t(`${currentRecipe.main_dish_name}`)}</li>
                        </ul>
                      </div>
                    </>
                  )}

                  <div className="recipe-ingredients-desktop">
                    <div className="title">
                      <img src={apple} alt="" />

                      <h2 style={{ direction: languageDirection }}>
                        {t("ingredients_with_colon")}
                      </h2>
                    </div>
                    <ul
                      className="recipe-ingredients-list"
                      style={{
                        padding:
                          languageDirection === "rtl"
                            ? "0 1em 0 0"
                            : "0 0 0 1rem",
                      }}
                    >
                      {recipeIngredients.length > 0 && (
                        <>
                          {recipeIngredients.map((ingredient) => {
                            return (
                              <li key={ingredient.id}>
                                <span>{ingredient.ingredient}</span>
                              </li>
                            );
                          })}
                        </>
                      )}
                    </ul>
                  </div>
                  <div className="recipe-preparation-desktop">
                    <div
                      className="title"
                      style={{ direction: languageDirection }}
                    >
                      <img src={mixer} alt="" />

                      <h2> {t("how_to")}</h2>
                    </div>
                    <ul
                      className="preparation-steps"
                      style={{
                        marginBottom: currentRecipe?.notes ? "0" : "100px",
                      }}
                    >
                      {recipePreparation.length > 0 && (
                        <>
                          {recipePreparation.map((step, idx) => {
                            return (
                              <li className="step" key={step.id}>
                                <span className="step-number">{`${t("STEP")} ${
                                  idx + 1
                                }:`}</span>
                                <br />
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: step.step.replace(/\n/g, "<br/>"),
                                  }}
                                ></span>
                              </li>
                            );
                          })}
                        </>
                      )}
                    </ul>
                  </div>
                  {currentRecipe.notes && (
                    <div className="recipe-notes-desktop">
                      <div className="title">
                        <span>
                          <IconContext.Provider
                            value={{ className: "notes-icon" }}
                          >
                            <BiMessageSquareError
                              className="icon-btn"
                              style={{
                                fontSize: "25px",
                                marginBottom: "7px",
                                color: "#3AA7D5",
                              }}
                            />
                          </IconContext.Provider>
                        </span>
                        <h2> {t("important_note")}</h2>
                      </div>

                      <p
                        dangerouslySetInnerHTML={{
                          __html: currentRecipe.notes.replace(/\n/g, "<br/>"),
                        }}
                      ></p>
                    </div>
                  )}
                </div>

                <div className="right">
                  <img
                    src={`${imageBaseUrl}recipe-image/${currentRecipe.image}`}
                    alt=""
                  />

                  <div className="recipe-buttons-desktop">
                    {showEditButtons && (
                      <>
                        <div
                          className="delete-recipe recipe-action"
                          onClick={() =>
                            dispatch(setDeleteRecipePopupOpenRedux(true))
                          }
                        >
                          <span>
                            <IconContext.Provider
                              value={{ className: "delete-icon" }}
                            >
                              <RiDeleteBin6Line
                                className="icon-btn"
                                style={{ marginBottom: "3px" }}
                              />
                            </IconContext.Provider>
                          </span>
                          <span>{t("delete")} </span>
                        </div>
                        <div className="edit-recipe" onClick={handleEditRecipe}>
                          <span>
                            <IconContext.Provider
                              value={{ className: "edit-icon" }}
                            >
                              <FaEdit
                                className="icon-btn"
                                style={{ marginBottom: "3px" }}
                              />
                            </IconContext.Provider>
                          </span>
                          <span>{t("edit")} </span>
                        </div>
                      </>
                    )}
                    <div
                      className="close-recipe"
                      style={{ width: showEditButtons ? "30%" : "50%" }}
                      onClick={() => navigate("/")}
                    >
                      <span>
                        <IconContext.Provider
                          value={{ className: "close-icon" }}
                        >
                          <IoIosCloseCircleOutline
                            className="icon-btn"
                            style={{ marginBottom: "3px" }}
                          />
                        </IconContext.Provider>
                      </span>
                      <span>{t("close")} </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* if mobile box it btn with position fixed thats why is out of recipe-review-main 
        but inside recipe-review-container 
        */}

        {mobile && (
          <div className="box-it-wrapper" dir={languageDirection}>
            <button className="btn-box-it" onClick={handleBoxIt}>
              <span>{t("box_it")}</span>
            </button>
            <button onClick={() => navigate("/")}>
              <span>{t("close")} </span>
            </button>
          </div>
        )}
      </div>
      {!mobile && (
        <div className="footer-wrapper">
          <FooterDesktop />
        </div>
      )}
    </>
  );
};

export default RecipeReview;
