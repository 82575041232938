import listIcon from "../images/list-icon.png";
import RecipeCardMobile from "./recipe-card-mobile";
import { useMediaQuery } from "react-responsive";
import "./styles/todays-suggestion.css";
import recipe1 from "../images/recipes/1.jpg";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentRecipeRedux } from "../redux/reducer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TodaysSuggestion = () => {
  const mobile = useMediaQuery({ maxWidth: 800 });
  const desktopMin1200Px = useMediaQuery({ minWidth: 1200 });
  const [todaysSuggestion, setTodaysSuggestion] = useState({});
  const generalRecipes = useSelector((state) => state.mainSlice.generalRecipes);
  const currentProfile = useSelector(
    (state) => state.mainSlice.currentChildProfile
  );
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (generalRecipes?.length) {
      // set todays suggestion using math random
      const randomIndex = Math.floor(Math.random() * generalRecipes.length);
      setTodaysSuggestion(generalRecipes[randomIndex]);
    }
  }, [generalRecipes]);

  const handleRecipeClick = async (recipe) => {
    dispatch(setCurrentRecipeRedux(recipe));
    navigate(`/recipe-review`);
  };
  return (
    <>
      <div
        className={mobile ? "todays-suggestion" : "todays-suggestion desktop"}
      >
        <div
          className="title"
          style={{
            gap: mobile ? "10px" : "5px",
            direction: languageDirection,
            paddingRight:
              languageDirection === "rtl" && mobile ? "20px" : "0px",
          }}
        >
          <span>
            <img src={listIcon} alt="" />
          </span>

          <span style={{ direction: languageDirection }}>
            {t("todays_suggestion", { name: currentProfile?.first_name })}
          </span>
        </div>
        <RecipeCardMobile
          handleRecipeClick={handleRecipeClick}
          recipe={todaysSuggestion}
          context="todays-suggestion"
        />
      </div>
    </>
  );
};

export default TodaysSuggestion;
