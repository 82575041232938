import axiosBase from "../axios/axios";

const videoService = {
  createVideo: async (video) => {
    try {
      const { data } = await axiosBase.post(`/video/createVideo`, video);
      return data;
    } catch (err) {
      console.log(err, "error in createVideo");
    }
  },
  getVideos: async (profileId) => {
    try {
      const { data } = await axiosBase.get(`/video/getVideos/${profileId}`);
      return data;
    } catch (err) {
      console.log(err, "error in getVideos");
    }
  },
  editVideo: async (video) => {
    try {
      const { data } = await axiosBase.post(`/video/editVideo`, video);
      return data;
    } catch (err) {
      console.log(err, "error in editVideo");
    }
  },
  deleteVideo: async (id) => {
    try {
      const { data } = await axiosBase.post(`/video/deleteVideo`, [id]);
      return data;
    } catch (err) {
      console.log(err, "error in deleteVideo");
    }
  },
};

export default videoService;
