import "./App.css";
import HomePage from "./components/homepage";
import Recipes from "./components/recipes";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import Search from "./components/search";
import ChangeProfileMobile from "./components/change-profile-mobile";
import EditProfile from "./components/edit-profile";
import ChangeAvatar from "./components/change-avatar";
import RecipeReview from "./components/recipe-review";
import WeeklyRecipesPopup from "./components/weekly-recipes-popup";
import RecipePanel from "./components/recipe-panel";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import CleanBoxPopup from "./components/clean-box-popup";
import Videos from "./components/videos";
import SearchResults from "./components/search-results";
import DeleteRecipePopup from "./components/delete-recipe-popup";
import AllergyDisclaimer from "./components/allergy-disclaimer";
import Favorites from "./components/favorites";
import AdminPanel from "./components/admin-panel";
import DeletionPopup from "./components/deletion-popup";
import NutritionalValuesPopup from "./components/nutritional-values-popup";
import NotificationPopup from "./components/notification-popup";
import UsersList from "./components/users-list";
import DeletedAccount from "./components/deleted_account";
function App() {
  const {
    weeklyRecipesPopupOpen,
    cleanBoxPopupOpen,
    deleteRecipePopupOpen,
    allergyDisclaimerPopupOpen,
    deletionPopupOpen,
    nutritionalValuesPopupOpen,
    notificationPopupOpen,
  } = useSelector((state) => state.mainSlice);
  const routes = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/:token/:profileId/:boxColor" element={<HomePage />} />
        <Route path="/" element={<HomePage />} exact />
        {"with date in url for inserting to weekly recipes"}
        <Route path="/recipes/:date" element={<Recipes />} />
        {"no date in url for searching in recipes"}
        <Route path="/recipes" element={<Recipes />} />
        <Route path="/recipe-review" element={<RecipeReview />} exact />
        <Route path="/search" element={<Search />} />
        <Route
          path="/change-profile-mobile"
          element={<ChangeProfileMobile />}
        />
        <Route path="/deleted-account" element={<DeletedAccount />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route path="/change-avatar" element={<ChangeAvatar />} />
        <Route path="/create-recipe" element={<RecipePanel />} />
        <Route path="/edit-recipe" element={<RecipePanel />} />
        <Route path="/create-article" element={<AdminPanel />} />
        <Route path="/edit-article" element={<AdminPanel />} />
        <Route path="/edit-testimonial" element={<AdminPanel />} />
        <Route path="/edit-video" element={<AdminPanel />} />
        <Route path="/search-results" element={<SearchResults />} />
        <Route path="/videos" element={<Videos />} />
        <Route path="/favorites" element={<Favorites />} />
        <Route path="/users-list" element={<UsersList />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </>
    )
  );

  return (
    <>
      <div className="App">
        <RouterProvider router={routes} />

        {weeklyRecipesPopupOpen && <WeeklyRecipesPopup />}
        {cleanBoxPopupOpen && <CleanBoxPopup />}
        {deleteRecipePopupOpen && <DeleteRecipePopup />}
        {allergyDisclaimerPopupOpen && <AllergyDisclaimer />}
        {deletionPopupOpen && <DeletionPopup />}
        {nutritionalValuesPopupOpen && <NutritionalValuesPopup />}
        {notificationPopupOpen && <NotificationPopup />}
      </div>
    </>
  );
}

export default App;
