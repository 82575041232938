import "./styles/admin-panel.css";
import { useMediaQuery } from "react-responsive";
import HeaderDesktop from "./header-desktop";
import FooterDesktop from "./footer-desktop";
import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Select from "react-select";
import { BsUpload } from "react-icons/bs";
import { IconContext } from "react-icons";
import { FaCheck } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { toast } from "react-toastify";
import articleService from "../services/articleService";
import videoService from "../services/videoService";
import recipeService from "../services/recipeService";
import testimonialService from "../services/testimonialService";
import utils from "../utils/utils";
import { useTranslation } from "react-i18next";

const AdminPanel = () => {
  const mobile = useMediaQuery({ maxWidth: 800 });
  const [context, setContext] = useState("article");
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [articleMainImage, setArticleMainImage] = useState(null);
  const [articleSecondaryImage, setArticleSecondaryImage] = useState(null);
  const [testimonialImage, setTestimonialImage] = useState(null);
  const [videoImage, setVideoImage] = useState(null);
  const [holdSubmissionBtn, setHoldSubmissionBtn] = useState(false); // to prevent double //submission [bug]
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "none",
      width: "300px",
      height: "50px",
      borderRadius: "4px",
      paddingInlineStart: "5px",
      borderBottom: "2px solid #3C4964",
      marginBottom: "10px",
      "&:hover": {
        borderBottom: "2px solid #3C4964", // Remove the border on hover
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none", // Hide the indicators separator (the vertical line)
    }),
    ValueContainer: (provided) => ({
      ...provided,
      border: "none",
      fontSize: "2rem",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#3f3d3d",
      fontSize: "1rem",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#3C4964",
    }),
    menu: (provided, state) => ({
      ...provided,
      maxHeight: "150px", // Adjust the max height as per your requirement
      overflowY: "scroll", // Enable vertical scrolling if options exceed maxHeight
      position: "absolute", // Ensure proper positioning
      zIndex: 9999, // Ensure the menu appears on top of other elements
    }),
  };
  const ViewOptions = [
    { value: "article", label: `${t("article")}` },
    { value: "testimonial", label: `${t("testimonial")}` },
    { value: "notification", label: `${t("notification")}` },
    { value: "video", label: `${t("video")}` },
    { value: "add ingredient", label: `${t("add_ingredient")}` },
    { value: "delete ingredient", label: `${t("delete_ingredient")}` },
    { value: "game", label: `${t("Create Game")}` },
    { value: "Delete Game", label: `${t("Delete Game")}` },
  ];
  const languageOptions = [
    { value: "en", label: `${t("English")}` },
    { value: "he", label: `${t("Hebrew")}` },
  ];
  const gameOptions = [
    { value: "ios", label: `${t("ios game")}` },
    { value: "android", label: `${t("android game")}` },
  ];
  const gameCategories = [
    {
      value: "What We're Playing",
      label: "What We're Playing",
    },
    {
      value: "Must-Play Games",
      label: "Must-Play Games",
    },
    {
      value: "More Games You Might Like",
      label: "More Games You Might Like",
    },
    {
      value: "Arcade Games",
      label: "Arcade Games",
    },
    {
      value: "Top Free Games",
      label: "Top Free Games",
    },
    {
      value: "Top Paid Games",
      label: "Top Paid Games",
    },
    {
      value: "Educational Games",
      label: "Educational Games",
    },
    {
      value: "Multiplayer games",
      label: "Multiplayer games",
    },
    {
      value: "Can You Rule the Leaderboard?",
      label: "Can You Rule the Leaderboard?",
    },
    {
      value: "Explore More Games for You",
      label: "Explore More Games for You",
    },
    {
      value: "Find Your Next Favourite Game",
      label: "Find Your Next Favourite Game",
    },
    {
      value: "New Games We Love",
      label: "New Games We Love",
    },
    {
      value: "New Phone? Play These!",
      label: "New Phone? Play These!",
    },
    {
      value: "Now Trending",
      label: "Now Trending",
    },
    {
      value: "Action Games for You",
      label: "Action Games for You",
    },
    {
      value: "Popular Games",
      label: "Popular Games",
    },
    {
      value: "Our Favourite Free Games",
      label: "Our Favourite Free Games",
    },
    {
      value: "Racing Games for You",
      label: "Racing Games for You",
    },
    {
      value: "Games Kids Will Love",
      label: "Games Kids Will Love",
    },

    {
      value: "Play Online With Friends",
      label: "Play Online With Friends",
    },
    {
      value: "Big Name Games",
      label: "Big Name Games",
    },
    {
      value: "Safe Video Streaming",
      label: "Safe Video Streaming",
    },
  ];
  const notificationsTypes = [
    { value: "Holiday", label: `${t("Holiday")}` },
    { value: "New Product", label: `${t("New Product")}` },
    { value: "Sale", label: `${t("Sale")}` },
    { value: "Reminder", label: `${t("Reminder")}` },
    { value: "Update", label: `${t("update")}` },
    { value: "Content", label: `${t("Content")}` },
    { value: "Clean box", label: `${t("Clean box")}` },
    { value: "Other", label: `${t("other")}` },
  ];
  const videoCategories = [
    { value: "tips", label: `${t("tips")}` },
    { value: "cleaning", label: `${t("cleaning")}` },
    { value: "unboxing", label: `${t("unboxing")}` },
    { value: "products", label: `${t("products")}` },
  ];
  const [articleObject, setArticleObject] = useState({
    title: "",
    content: "",
    link: "",
    articleLanguage: null,
  });
  const [testimonialObject, setTestimonialObject] = useState({
    testimonial: "",
    user: "",
    language: null,
  });
  const [notificationObject, setNotificationObject] = useState({
    content: "",
    type: "",
    startDate: "",
    endDate: "",
    language: null,
  });
  const [ingredient, setIngredient] = useState("");
  const [ingredientInHebrew, setIngredientInHebrew] = useState("");
  const [optionsForDeletion, setOptionsForDeletion] = useState([]);
  const [selectedIngredientForDeletion, setSelectedIngredientForDeletion] =
    useState([]);
  const [videoObject, setVideoObject] = useState({
    title: "",
    description: "",
    category: "",
    link: "",
    language: null,
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [editModeContext, setEditModeContext] = useState(null);
  const [error, setError] = useState(null);

  const [gameObject, setGameObject] = useState({
    gameLink: "",
    gameName: "",
    gameContext: "",
    category: null,
    gameDescription: "",
  });
  const [gameForDeletion, setGameForDeletion] = useState({
    id: "",
    gameContext: "",
    listOfGames: [],
  });
  const [gameImage, setGameImage] = useState(null);
  const articleMainImageRef = useRef(null);
  const articleSecondaryImageRef = useRef(null);
  const testimonialImageRef = useRef(null);
  const videoImageRef = useRef(null);
  const gameImageRef = useRef(null);
  const currentProfile = useSelector(
    (state) => state.mainSlice.currentChildProfile
  );
  const roleRedux = useSelector((state) => state.mainSlice.role);
  const currentArticleOrTestimonial = useSelector(
    (state) => state.mainSlice.currentArticle
  );
  const currentVideoRedux = useSelector((state) => state.mainSlice.video);

  const handleImageChange = (event) => {
    const name = event.target.name;
    const file = event.target.files[0];

    switch (name) {
      case "article-main-img":
        setArticleMainImage(file);
        break;
      case "article-secondary-img":
        setArticleSecondaryImage(file);
        break;
      case "testimonial-img":
        setTestimonialImage(file);
        break;
      case "video-image":
        setVideoImage(file);
        break;
      case "game":
        setGameImage(file);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    let name = pathname.split("/")[1].split("-")[0];
    let restOfPathname = pathname.split("/")[1].split("-").slice(1).join("-");

    const fetchIngredients = async () => {
      const response = await recipeService.getIngredients();
      if (response.message === "success") {
        // setIngredients(ingredients.data)

        const optionsForSelect = response.ingredients.map((ingredient) => {
          return {
            label:
              i18n.language === "en"
                ? ingredient.name
                : ingredient.name_in_hebrew,
            value: ingredient.id,
          };
        });
        setOptionsForDeletion(optionsForSelect);
      }
    };
    if (name === "edit") {
      // if true we set to edit mode and set the context
      setIsEditMode(true);

      if (restOfPathname === "video") {
        /* check if user came from video or article/testimonial */
        setEditModeContext(currentVideoRedux.context);
      } else {
        setEditModeContext(currentArticleOrTestimonial.context);
      }
    } else {
      setIsEditMode(false);
      setEditModeContext(null);
      /* if not edit mode fetch ingredients */
      fetchIngredients();
    }
  }, []);
  useEffect(() => {
    if (isEditMode) {
      // check context and populate the fields
      setContext(editModeContext);

      if (editModeContext === "article") {
        const selectedLanguage = languageOptions.find(
          (option) => option.value === currentArticleOrTestimonial.language
        );
        setArticleObject({
          title: currentArticleOrTestimonial.title,
          content: currentArticleOrTestimonial.content,
          link: currentArticleOrTestimonial.link,
          articleLanguage: selectedLanguage ? selectedLanguage : null,
        });

        setArticleMainImage(currentArticleOrTestimonial.main_image);
        setArticleSecondaryImage(currentArticleOrTestimonial.secondary_image);
      } else if (editModeContext === "testimonial") {
        // testimonial
        const selectedLanguage = languageOptions.find(
          (option) => option.value === currentArticleOrTestimonial.language
        );
        setTestimonialObject({
          testimonial: currentArticleOrTestimonial.content,
          user: currentArticleOrTestimonial.user_name,
          language: selectedLanguage ? selectedLanguage : null,
        });
        setTestimonialImage(currentArticleOrTestimonial.image);
      } else {
        // video
        const selectedLanguage = languageOptions.find(
          (option) => option.value === currentVideoRedux.language
        );
        setVideoObject({
          title: currentVideoRedux.title,
          description: currentVideoRedux.description,
          category: t(currentVideoRedux.category),
          link: currentVideoRedux.link,
          language: selectedLanguage ? selectedLanguage : null,
        });
        setVideoImage(currentVideoRedux.image);
      }
    }
  }, [isEditMode]);
  useEffect(() => {
    if (mobile || !currentProfile?.id || roleRedux !== 2) {
      navigate("/");
    }
  }, [mobile]);

  const handleFileRemove = (e, context) => {
    e.preventDefault();
    switch (context) {
      case "mainImage":
        setArticleMainImage(null);
        articleMainImageRef.current.value = "";
        break;
      case "secondaryImage":
        setArticleSecondaryImage(null);
        articleSecondaryImageRef.current.value = "";

        break;
      case "testimonialImage":
        setTestimonialImage(null);
        testimonialImageRef.current.value = "";
        break;
      case "videoImage":
        setVideoImage(null);
        videoImageRef.current.value = "";
        break;
      case "game":
        setGameImage(null);
        gameImageRef.current.value = "";
        break;
      default:
        break;
    }
  };

  const handleIngredientDeletion = async () => {
    try {
      if (selectedIngredientForDeletion.length === 0) {
        setError("Select an ingredient");
        return;
      }

      const response = await recipeService.deleteIngredient(
        selectedIngredientForDeletion.value
      );
      if (response.message === "success") {
        toast.success(t("Ingredient deleted"));
        navigate("/");
      } else {
        toast.error(t("Error deleting ingredient"));
      }
    } catch (err) {
      console.log("error deleting ingredient", err);
    }
  };

  useEffect(() => {
    console.log(gameForDeletion, "gameForDeletion");
    if (gameForDeletion?.gameContext) {
      setError("");
      const fetchListOfGames = async (OperatingSystem) => {
        try {
          const response = await articleService.fetchGamesByOperatingSystem(
            OperatingSystem
          );
          console.log(response, "response");
          if (response.message === "success") {
            setGameForDeletion({
              ...gameForDeletion,
              listOfGames: response.games,
            });
          }
        } catch (err) {
          console.log(err, "error fetching games");
        }
      };

      fetchListOfGames(gameForDeletion?.gameContext);
    }
  }, [gameForDeletion?.gameContext]);

  const handleSubmission = async () => {
    setError(null);
    const formData = new FormData();
    formData.append("profileId", currentProfile.id);
    if (context === "add ingredient") {
      formData.append("context", "ingredient");
    } else {
      formData.append("context", context);
    }

    if (context === "article") {
      if (!articleObject?.articleLanguage?.value) {
        setError(t("Please select language"));
        setHoldSubmissionBtn(false);
        return;
      }
      if (
        !articleObject.title ||
        !articleObject.content ||
        !articleObject.link ||
        !articleMainImage ||
        !articleSecondaryImage
      ) {
        setError(t("Please fill out all fields"));
        setHoldSubmissionBtn(false);
        return;
      }

      const testLink = utils.checkLinkValidity(articleObject.link);
      if (!testLink) {
        setError(t("Link is not valid"));
        setHoldSubmissionBtn(false);

        return;
      }
      formData.append("articleLanguage", articleObject.articleLanguage.value);
      formData.append("title", articleObject.title);
      formData.append("content", articleObject.content);
      formData.append("link", articleObject.link);
      formData.append("mainImage", articleMainImage);
      formData.append("secondaryImage", articleSecondaryImage);
    } else if (context === "testimonial") {
      if (!testimonialObject?.language?.value) {
        setError(t("Please select language"));
        setHoldSubmissionBtn(false);
        return;
      }

      if (
        !testimonialObject.testimonial ||
        !testimonialObject.user ||
        !testimonialImage
      ) {
        setError(t("Please fill out all fields"));
        setHoldSubmissionBtn(false);

        return;
      }

      formData.append("content", testimonialObject.testimonial);
      formData.append("user_name", testimonialObject.user);
      formData.append("image", testimonialImage);
      formData.append("language", testimonialObject.language.value);
    } else if (context === "notification") {
      if (!notificationObject.type) {
        setError(t("Please select type"));
        setHoldSubmissionBtn(false);
        return;
      }
      if (!notificationObject?.language?.value) {
        setError(t("Please select language"));
        setHoldSubmissionBtn(false);
        return;
      }

      if (
        !notificationObject.content ||
        !notificationObject.startDate ||
        !notificationObject.endDate
      ) {
        setError(t("Please fill out all fields"));
        setHoldSubmissionBtn(false);

        return;
      }

      formData.append("content", notificationObject.content);
      formData.append("type", notificationObject.type);
      formData.append("start_date", notificationObject.startDate);
      formData.append("end_date", notificationObject.endDate);
      formData.append("language", notificationObject.language.value);
    } else if (context === "add ingredient") {
      if (!ingredient || !ingredientInHebrew) {
        setError(t("Please enter ingredient in both languages"));
        setHoldSubmissionBtn(false);
        return;
      }
      const hebrewOnlyRegex = /^[\u0590-\u05FF\s]+$/;
      const isHebrewOnly = hebrewOnlyRegex.test(ingredientInHebrew);
      if (!isHebrewOnly) {
        setError(t("ingredient in hebrew only"));
        setHoldSubmissionBtn(false);
        return;
      }

      const englishOnlyRegex = /^[a-zA-Z\s]+$/;

      const isEnglishOnly = englishOnlyRegex.test(ingredient);

      if (!isEnglishOnly) {
        setError(t("ingredient in english only"));
        setHoldSubmissionBtn(false);
        return;
      }
    } else if (context === "video") {
      if (!videoObject?.category) {
        setError(t("Please select category"));
        setHoldSubmissionBtn(false);
        return;
      }
      if (!videoObject?.language?.value) {
        setError(t("Please select language"));
        setHoldSubmissionBtn(false);
        return;
      }
      if (
        !videoObject.title ||
        !videoObject.description ||
        !videoObject.link ||
        !videoImage
      ) {
        setError(t("Please fill out all fields"));
        setHoldSubmissionBtn(false);

        return;
      }

      const testLink = utils.checkLinkValidity(videoObject.link);
      if (!testLink) {
        setError(t("Url is not valid"));
        setHoldSubmissionBtn(false);
        return;
      }

      formData.append("title", videoObject.title);
      formData.append("description", videoObject.description);
      formData.append("category", videoObject.category);
      formData.append("link", videoObject.link);
      formData.append("image", videoImage);
      formData.append("language", videoObject.language.value);
    } else if (context === "game") {
      if (!gameObject.gameContext) {
        setError(t("Please select Operating System"));
        setHoldSubmissionBtn(false);
        return;
      }
      if (gameObject.category === null) {
        setError(t("Please select category"));
        setHoldSubmissionBtn(false);
        return;
      }

      if (!gameObject.gameName) {
        setError(t("Please enter the name of the game"));
        setHoldSubmissionBtn(false);
        return;
      }
      if (!gameObject.gameDescription) {
        setError(t("Please enter description"));
        setHoldSubmissionBtn(false);
        return;
      }

      if (!gameObject.gameLink) {
        setError(t("Please fill out game link"));
        setHoldSubmissionBtn(false);
        return;
      }
      const validGameLink = utils.checkLinkValidity(gameObject.gameLink);
      if (!validGameLink) {
        setError(t("Please enter a valid link"));
        setHoldSubmissionBtn(false);
        return;
      }

      if (!gameImage) {
        setError(t("Please upload game image"));
        setHoldSubmissionBtn(false);
        return;
      }
      formData.append("gameLink", gameObject.gameLink);
      formData.append("gameName", gameObject.gameName);
      formData.append("gameContext", gameObject.gameContext);
      formData.append("category", gameObject.category.value);
      formData.append("gameDescription", gameObject.gameDescription);
      formData.append("gameImage", gameImage);
    } else if (context === "Delete Game") {
      if (!gameForDeletion.gameContext) {
        setError(t("Please select Operating System"));
        setHoldSubmissionBtn(false);
        return;
      }
      if (!gameForDeletion.id) {
        setError(t("Please select game to delete"));
        setHoldSubmissionBtn(false);
        return;
      }
    }
    try {
      let request;

      if (isEditMode) {
        // EDIT mode
        // append id to edit
        if (context === "article") {
          formData.append("id", currentArticleOrTestimonial.id);
          // edit article
          request = await articleService.editArticle(formData);
        } else if (context === "testimonial") {
          // edit testimonial
          formData.append("id", currentArticleOrTestimonial.id);

          request = await testimonialService.editTestimonial(formData);
        } else if (context === "video") {
          formData.append("id", currentVideoRedux.id);
          // edit notification
          request = await videoService.editVideo(formData);
        }
      } else {
        // CREATE mode
        if (context === "article") {
          // create article
          request = await articleService.createArticle(formData);
        } else if (context === "testimonial") {
          // create testimonial
          request = await testimonialService.createTestimonial(formData);
        } else if (context === "notification") {
          // create notification

          request = await articleService.createNotification(formData);
        } else if (context === "add ingredient") {
          // create ingredient

          request = await recipeService.createIngredient(
            ingredient,
            ingredientInHebrew
          );
        } else if (context === "video") {
          // create video
          request = await videoService.createVideo(formData);
        } else if (context === "game") {
          request = await articleService.createGame(formData);
        } else if (context === "Delete Game") {
          request = await articleService.deleteGame(gameForDeletion.id);
        }
      }

      setHoldSubmissionBtn(false);
      if (request.message === "success") {
        if (context === "Delete Game") {
          toast.success(t("Game deleted"));
        } else {
          toast.success(
            `${context?.charAt(0).toUpperCase() + context.slice(1)} ${
              isEditMode ? "edited" : "created"
            }`
          );
          setHoldSubmissionBtn(false);
        }
        navigate("/");
      } else {
        toast.error(`Error creating ${context}`);
        console.log(request, `Error creating ${context}`);
      }
    } catch (err) {
      toast.error(`Error creating ${context}`);
      console.log(err, `Error creating ${context}`);
    }
  };

  return (
    <>
      <div className="article-panel-container">
        <HeaderDesktop />

        <div className="article-panel-main">
          <div className="title">
            {context === "delete ingredient"
              ? `${t("delete_ingredient")}`
              : context === "add ingredient"
              ? `${t("create_ingredient")}`
              : context === "Delete Game"
              ? `${t("Delete Game")}`
              : `${t("create_new")}  ${t(`${context}`)}`}
          </div>

          <div
            className="article-panel-select"
            style={{ direction: languageDirection }}
          >
            <Select
              styles={customStyles}
              placeholder={t(`${context}`)}
              options={ViewOptions}
              onChange={(e) => setContext(e.value)}
            />
          </div>

          <div
            className="panel-content"
            style={{ direction: languageDirection }}
          >
            {context === "article" && (
              <>
                <div className="form-wrapper">
                  <Select
                    styles={customStyles}
                    placeholder={t("select-language")}
                    options={languageOptions}
                    value={
                      articleObject?.articleLanguage
                        ? articleObject.articleLanguage
                        : ""
                    }
                    onChange={(e) => {
                      setError("");
                      setArticleObject({
                        ...articleObject,
                        articleLanguage: e,
                      });
                    }}
                  />
                </div>

                <div className="form-wrapper">
                  <input
                    type="text"
                    placeholder={t("title")}
                    value={articleObject.title}
                    onChange={(e) =>
                      setArticleObject({
                        ...articleObject,
                        title: e.target.value,
                      })
                    }
                    disabled={
                      articleObject.articleLanguage === "" ? true : false
                    }
                  />
                </div>
                <div className="form-wrapper">
                  <textarea
                    value={articleObject.content}
                    onChange={(e) =>
                      setArticleObject({
                        ...articleObject,
                        content: e.target.value,
                      })
                    }
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    placeholder={t("Content_up_to_120")}
                    disabled={
                      articleObject.articleLanguage === "" ? true : false
                    }
                  ></textarea>
                </div>
                <div className="form-wrapper">
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder={t("article_link")}
                    value={articleObject.link}
                    onChange={(e) =>
                      setArticleObject({
                        ...articleObject,
                        link: e.target.value,
                      })
                    }
                    disabled={
                      articleObject.articleLanguage === "" ? true : false
                    }
                  />
                </div>
                <div className="form-wrapper">
                  <label
                    htmlFor={`article-main-img`}
                    className="article-main-img"
                  >
                    <span>{t("main_image")}</span>
                    <input
                      type="file"
                      id={`article-main-img`}
                      name="article-main-img"
                      hidden
                      ref={articleMainImageRef}
                      onChange={handleImageChange}
                      disabled={
                        articleObject.articleLanguage === "" ? true : false
                      }
                    />
                    <IconContext.Provider
                      value={{
                        className: "upload-icon",
                        color: "#000",
                      }}
                    >
                      <BsUpload />
                    </IconContext.Provider>

                    {articleMainImage !== null && (
                      <span onClick={(e) => handleFileRemove(e, "mainImage")}>
                        <IconContext.Provider
                          value={{ className: "remove-icon", color: "red" }}
                        >
                          <RiDeleteBin6Line />
                        </IconContext.Provider>
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-wrapper">
                  <label
                    htmlFor={`article-secondary-img`}
                    className="article-secondary-img"
                  >
                    <span>{t("sub_image")}</span>
                    <input
                      type="file"
                      ref={articleSecondaryImageRef}
                      id={`article-secondary-img`}
                      name="article-secondary-img"
                      hidden
                      onChange={handleImageChange}
                      disabled={
                        articleObject.articleLanguage === "" ? true : false
                      }
                    />
                    <IconContext.Provider
                      value={{
                        className: "upload-icon",
                        color: "#000",
                      }}
                    >
                      <BsUpload />
                    </IconContext.Provider>

                    {articleSecondaryImage !== null && (
                      <span
                        onClick={(e) => handleFileRemove(e, "secondaryImage")}
                      >
                        <IconContext.Provider
                          value={{ className: "remove-icon", color: "red" }}
                        >
                          <RiDeleteBin6Line />
                        </IconContext.Provider>
                      </span>
                    )}
                  </label>
                </div>
              </>
            )}
            {context === "testimonial" && (
              <>
                {/* context === testimonial */}
                <div className="form-wrapper">
                  <Select
                    styles={customStyles}
                    placeholder={t("select-language")}
                    options={languageOptions}
                    value={
                      testimonialObject?.language
                        ? testimonialObject.language
                        : ""
                    }
                    onChange={(e) => {
                      setError("");
                      setTestimonialObject({
                        ...testimonialObject,
                        language: e,
                      });
                    }}
                  />
                </div>
                <div className="form-wrapper">
                  <textarea
                    value={testimonialObject.testimonial}
                    onChange={(e) =>
                      setTestimonialObject({
                        ...testimonialObject,
                        testimonial: e.target.value,
                      })
                    }
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    placeholder={t("Content_up_to_120")}
                    disabled={
                      testimonialObject?.language === null ? true : false
                    }
                  ></textarea>
                </div>
                <div className="form-wrapper">
                  <input
                    type="text"
                    placeholder={t("user_name")}
                    value={testimonialObject.user}
                    onChange={(e) =>
                      setTestimonialObject({
                        ...testimonialObject,
                        user: e.target.value,
                      })
                    }
                    disabled={
                      testimonialObject?.language === null ? true : false
                    }
                  />
                </div>

                <div className="form-wrapper">
                  <label htmlFor={`testimonial-img`}>
                    <span>{t("user_image")}</span>
                    <input
                      type="file"
                      ref={testimonialImageRef}
                      id={`testimonial-img`}
                      name="testimonial-img"
                      hidden
                      onChange={handleImageChange}
                      disabled={
                        testimonialObject?.language === null ? true : false
                      }
                    />
                    <IconContext.Provider
                      value={{
                        className: "upload-icon",
                        color: "#000",
                      }}
                    >
                      <BsUpload />
                    </IconContext.Provider>

                    {testimonialImage !== null && (
                      <span
                        onClick={(e) => handleFileRemove(e, "testimonialImage")}
                      >
                        <IconContext.Provider
                          value={{ className: "remove-icon", color: "red" }}
                        >
                          <RiDeleteBin6Line />
                        </IconContext.Provider>
                      </span>
                    )}
                  </label>
                </div>
              </>
            )}
            {context === "notification" && (
              <>
                <div className="form-wrapper">
                  <Select
                    styles={customStyles}
                    placeholder={t("type")}
                    options={notificationsTypes}
                    onChange={(e) => {
                      setError("");
                      setNotificationObject({
                        ...notificationObject,
                        type: e.value,
                      });
                    }}
                  />
                </div>
                <div className="form-wrapper">
                  <Select
                    styles={customStyles}
                    placeholder={t("select-language")}
                    options={languageOptions}
                    value={
                      notificationObject?.language
                        ? notificationObject.language
                        : ""
                    }
                    onChange={(e) => {
                      setError("");
                      setNotificationObject({
                        ...notificationObject,
                        language: e,
                      });
                    }}
                  />
                </div>
                <div className="form-wrapper">
                  <textarea
                    name=""
                    id=""
                    value={notificationObject.content}
                    onChange={(e) =>
                      setNotificationObject({
                        ...notificationObject,
                        content: e.target.value,
                      })
                    }
                    cols="30"
                    rows="5"
                    placeholder={t("Content_up_to_100")}
                    disabled={
                      notificationObject?.language === null ? true : false
                    }
                  ></textarea>
                </div>

                <div className="date-wrapper">
                  <span>{t("start_date")}</span>

                  <input
                    type="date"
                    value={notificationObject.startDate}
                    onChange={(e) =>
                      setNotificationObject({
                        ...notificationObject,
                        startDate: e.target.value,
                      })
                    }
                    disabled={
                      notificationObject?.language === null ? true : false
                    }
                  />
                </div>
                <div className="date-wrapper">
                  <span>{t("end_date")}</span>

                  <input
                    type="date"
                    value={notificationObject.endDate}
                    onChange={(e) =>
                      setNotificationObject({
                        ...notificationObject,
                        endDate: e.target.value,
                      })
                    }
                    disabled={
                      notificationObject?.language === null ? true : false
                    }
                  />
                </div>
              </>
            )}
            {context === "add ingredient" && (
              <>
                <div className="form-wrapper">
                  <input
                    type="text"
                    placeholder={t("add_ingredient_in_english")}
                    value={ingredient}
                    onChange={(e) => setIngredient(e.target.value)}
                  />
                </div>

                <div className="form-wrapper">
                  <input
                    type="text"
                    placeholder={t("add_ingredient_in_hebrew")}
                    value={ingredientInHebrew}
                    onChange={(e) => setIngredientInHebrew(e.target.value)}
                  />
                </div>
              </>
            )}
            {context === "delete ingredient" && (
              <>
                <div className="form-wrapper">
                  <h5>{t("ingredients_super")}</h5>
                  <Select
                    onChange={(e) => setSelectedIngredientForDeletion(e)}
                    options={optionsForDeletion}
                    styles={customStyles}
                    placeholder={t("choose_ingredient")}
                  />
                </div>
              </>
            )}
            {context === "video" && (
              <>
                <div className="form-wrapper">
                  <Select
                    styles={customStyles}
                    placeholder={t("category")}
                    options={videoCategories}
                    defaultValue={
                      isEditMode
                        ? {
                            label: t(currentVideoRedux.category),
                            value: currentVideoRedux.category,
                          }
                        : null
                    }
                    onChange={(e) =>
                      setVideoObject({
                        ...videoObject,
                        category: e.value,
                      })
                    }
                  />
                </div>
                <div className="form-wrapper">
                  <Select
                    styles={customStyles}
                    placeholder={t("select-language")}
                    options={languageOptions}
                    onChange={(e) => {
                      setError("");
                      setVideoObject({
                        ...videoObject,
                        language: e,
                      });
                    }}
                    value={videoObject?.language ? videoObject.language : ""}
                  />
                </div>
                <div className="form-wrapper">
                  <input
                    type="text"
                    placeholder={t("video_title")}
                    value={videoObject.title}
                    onChange={(e) =>
                      setVideoObject({
                        ...videoObject,
                        title: e.target.value,
                      })
                    }
                    disabled={videoObject.language === null ? true : false}
                  />
                </div>
                <div className="form-wrapper">
                  <input
                    type="text"
                    placeholder={t("video_description")}
                    value={videoObject.description}
                    onChange={(e) =>
                      setVideoObject({
                        ...videoObject,
                        description: e.target.value,
                      })
                    }
                    disabled={videoObject.language === null ? true : false}
                  />
                </div>
                <div className="form-wrapper">
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder={t("video_url")}
                    value={videoObject.link}
                    onChange={(e) =>
                      setVideoObject({
                        ...videoObject,
                        link: e.target.value,
                      })
                    }
                    disabled={videoObject.language === null ? true : false}
                  />
                </div>
                <div className="form-wrapper">
                  <label htmlFor="video-image" className="article-main-img">
                    <span>{t("video_image")}</span>
                    <input
                      type="file"
                      id="video-image"
                      name="video-image"
                      hidden
                      ref={videoImageRef}
                      onChange={handleImageChange}
                      disabled={videoObject.language === null ? true : false}
                    />
                    <IconContext.Provider
                      value={{
                        className: "upload-icon",
                        color: "#000",
                      }}
                    >
                      <BsUpload />
                    </IconContext.Provider>

                    {videoImage !== null && (
                      <span onClick={(e) => handleFileRemove(e, "videoImage")}>
                        <IconContext.Provider
                          value={{ className: "remove-icon", color: "red" }}
                        >
                          <RiDeleteBin6Line />
                        </IconContext.Provider>
                      </span>
                    )}
                  </label>
                </div>
              </>
            )}
            {context === "game" && (
              <>
                <div className="form-wrapper">
                  <Select
                    styles={customStyles}
                    placeholder={t("Operating System")}
                    options={gameOptions}
                    onChange={(e) =>
                      setGameObject({
                        ...gameObject,
                        gameContext: e.value,
                      })
                    }
                  />
                </div>
                <div className="form-wrapper">
                  <Select
                    styles={customStyles}
                    placeholder={t("Category")}
                    options={gameCategories}
                    onChange={(e) =>
                      setGameObject({
                        ...gameObject,
                        category: e,
                      })
                    }
                    isDisabled={gameObject.gameContext === "" ? true : false}
                  />
                </div>
                <div className="form-wrapper">
                  <input
                    type="text"
                    placeholder={t("game name")}
                    name="gameName"
                    id="gameName"
                    disabled={gameObject.gameContext === "" ? true : false}
                    onChange={(e) =>
                      setGameObject({
                        ...gameObject,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="form-wrapper">
                  <input
                    type="text"
                    placeholder={t("game description")}
                    name="gameDescription"
                    id="gameDescription"
                    disabled={gameObject.gameContext === "" ? true : false}
                    onChange={(e) =>
                      setGameObject({
                        ...gameObject,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="form-wrapper">
                  <input
                    type="text"
                    placeholder={t("link for game")}
                    name="gameLink"
                    id="gameLink"
                    onChange={(e) =>
                      setGameObject({
                        ...gameObject,
                        [e.target.name]: e.target.value,
                      })
                    }
                    disabled={gameObject.gameContext === "" ? true : false}
                  />
                </div>

                <div className="form-wrapper">
                  <label htmlFor="game">
                    <span>{t("game image")}</span>
                    <input
                      type="file"
                      name="game"
                      id="game"
                      hidden
                      ref={gameImageRef}
                      disabled={gameObject.gameContext === "" ? true : false}
                      onChange={handleImageChange}
                    />
                    <IconContext.Provider
                      value={{
                        className: "upload-icon",
                        color: "#000",
                      }}
                    >
                      <BsUpload />
                    </IconContext.Provider>

                    {gameImage !== null && (
                      <span onClick={(e) => handleFileRemove(e, "game")}>
                        <IconContext.Provider
                          value={{ className: "remove-icon", color: "red" }}
                        >
                          <RiDeleteBin6Line />
                        </IconContext.Provider>
                      </span>
                    )}
                  </label>
                </div>
              </>
            )}
            {context === "Delete Game" && (
              <>
                <div className="form-wrapper">
                  <Select
                    styles={customStyles}
                    placeholder={t("Operating System")}
                    options={gameOptions}
                    onChange={(e) =>
                      setGameForDeletion({
                        ...gameForDeletion,
                        gameContext: e.value,
                      })
                    }
                  />
                </div>

                {gameForDeletion?.listOfGames.length > 0 && (
                  <>
                    <div className="form-wrapper">
                      <Select
                        styles={customStyles}
                        placeholder={t("Select Game To Delete")}
                        options={gameForDeletion?.listOfGames}
                        onChange={(e) =>
                          setGameForDeletion({
                            ...gameForDeletion,
                            id: e.value,
                          })
                        }
                      />
                    </div>
                  </>
                )}
              </>
            )}
            {error && <div className="error">{error}</div>}
            <div
              className="submit"
              onClick={
                context === "delete ingredient"
                  ? handleIngredientDeletion
                  : handleSubmission
              }
            >
              <button>
                {context === "delete ingredient" || context === "Delete Game"
                  ? `${t("delete")}`
                  : context === "article" && isEditMode
                  ? `${t("update")}`
                  : `${t("create")}`}
              </button>
            </div>
          </div>
        </div>

        <FooterDesktop />
      </div>
    </>
  );
};

export default AdminPanel;
