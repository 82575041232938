import logo from "../images/logo_new.png";
import { useTranslation } from "react-i18next";
import "./styles/deleted-account.css";
const DeletedAccount = () => {
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  return (
    <>
      <div className="deleted-account-wrapper">
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <div className="goodby" dir={languageDirection}>
          <h3>{t("thanks yoopi")}</h3>

          <p>{t("happy to see you again")}</p>
          <p>{t("scan box")}</p>
        </div>

        <div className="button" dir={languageDirection}>
          {t("come back")}
        </div>
      </div>
    </>
  );
};

export default DeletedAccount;
