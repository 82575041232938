import "./styles/deletion-popup.css";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { setDeletionPopupOpenRedux } from "../redux/reducer";
import articleService from "../services/articleService";
import { toast } from "react-toastify";
import testimonialService from "../services/testimonialService";
import profileService from "../services/profileService";
import videoService from "../services/videoService";
import householdService from "../services/householdService";
import { useTranslation } from "react-i18next";
const DeletionPopup = () => {
  const [currentItem, setCurrentItem] = useState(null);
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const dispatch = useDispatch();
  const itemForDeletion = useSelector(
    (state) => state.mainSlice.itemForDeletion
  );
  const context = useSelector((state) => state.mainSlice.deletionPopupContext);
  useEffect(() => {
    console.log("itemForDeletion", itemForDeletion);
    console.log("context", context);
    if (itemForDeletion) {
      if (context === "testimonial" || context === "article") {
        // if testimonial is being deleted
        // set current item to entire testimonial
        setCurrentItem(itemForDeletion);
      } else if (context === "household") {
        // else id is enough
        setCurrentItem(itemForDeletion.email);
      } else {
        setCurrentItem(itemForDeletion.id);
      }
    }
  }, []);

  const handleDeletion = async () => {
    try {
      let response;
      if (context === "article") {
        // delete article
        response = await articleService.deleteArticle(currentItem);
      } else if (context === "testimonial") {
        // delete testimonial
        response = await testimonialService.deleteTestimonial(currentItem);
      } else if (context === "video") {
        response = await videoService.deleteVideo(currentItem);
      } else if (context === "profile") {
        // delete profile
        response = await profileService.deleteProfile(currentItem);
      } else if (context === "household") {
        // delete recipe
        console.log("deleting household", currentItem);
        console.log("context", context);
        response = await householdService.deleteHousehold(currentItem);
      }
      console.log(response, "response test");
      if (response.message === "success") {
        if (context === "profile") {
          if (!response.deletedHousehold) {
            localStorage.setItem("profileId", response.siblingId);
            window.location.reload();
            return;
          } else {
            // entire household is deleted
            localStorage.removeItem("profileId");
            localStorage.removeItem("token");
            window.location.href = "https://getyoopi.com/blog-post/";
          }
          return;
        } else if (context === "video") {
          window.location.reload();
        } else if (context === "household") {
          toast.success("Household deleted");
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      } else {
        toast.error(response.message);

        console.log(response, "error from deleting article / testimonial");
      }
    } catch (error) {
      console.log(error, "error in deleting item");
    }
  };

  return (
    <>
      <div className="delete-article-popup-container">
        <div className="delete-article-main">
          <p style={{ direction: languageDirection }}>{`${t(
            "confirm_deletion"
          )} ${t(`${context}`)}?`}</p>
          <div className="delete-article-buttons">
            <button className="delete-article yes" onClick={handleDeletion}>
              {t("yes")}
            </button>
            <button
              className="delete-article no"
              onClick={() => dispatch(setDeletionPopupOpenRedux(false))}
            >
              {t("no")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeletionPopup;
