import calender from "../images/calander.png";
import template from "../images/template.png";
import "./styles/weekly-recipes.css";
import dd from "..//images/recipes/1.jpg";
import addSign from "../images/weekly-recipes/blue.png";
import { useEffect, useState } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { MdOutlineDeleteForever } from "react-icons/md";

import { IconContext } from "react-icons";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import {
  setWeeklyRecipesRedux,
  setWeeklySuppliesRedux,
  setWeeklyRecipesPopupOpenRedux,
  setCurrentRecipeRedux,
} from "../redux/reducer";
import { toast } from "react-toastify";
import recipeService from "../services/recipeService";
import imageBaseUrl from "../config";
import moment from "moment";
import "moment-timezone";
import sliceTextToMaxLength from "../utils/sliceTextToMaxLength";
import { useTranslation } from "react-i18next";
const WeeklyRecipes = (props) => {
  const [thisWeek, setThisWeek] = useState([]);
  const [nextWeek, setNextWeek] = useState([]);
  const [weekOnDisplay, setWeekOnDisplay] = useState([]);
  const [stateOfButtons, setStateOfButtons] = useState("Last week");
  const [weeklyRecipes, setWeeklyRecipes] = useState([]);
  const mobile = useMediaQuery({ maxWidth: 800 });
  const dispatch = useDispatch();
  const weeklyRecipesRedux = useSelector(
    (state) => state.mainSlice.weeklyRecipes
  );

  const currentRecipeRedux = useSelector(
    (state) => state.mainSlice.currentRecipe
  );

  const currentProfileRedux = useSelector(
    (state) => state.mainSlice.currentChildProfile
  );
  const timeZoneRedux = useSelector((state) => state.mainSlice.timeZone);
  const { t } = useTranslation();
  useEffect(() => {
    if (weeklyRecipesRedux?.length) {
      setWeeklyRecipes(weeklyRecipesRedux);
    } else {
      setWeeklyRecipes([]);
    }
  }, [weeklyRecipesRedux]);
  useEffect(() => {
    const today = new Date();
    const currentDay = today.getDay(); // 0 (Sunday) to 6 (Saturday)

    // check current household timezone
    const currentTimeZone =
      timeZoneRedux === "America/New_York" ? "en-US" : "en-GB";

    const storedLang = localStorage.getItem("lang");
    const isHebrew = storedLang === "he";

    // Calculate the start date of the current week
    const startDateCurrentWeek = new Date(today);
    if (isHebrew) {
      // For Hebrew, start the week on Sunday
      startDateCurrentWeek.setDate(today.getDate() - currentDay);
    } else {
      // For other languages, start the week on Monday
      startDateCurrentWeek.setDate(today.getDate() - currentDay + 1);
    }

    // Calculate the start date of the next week
    const startDateNextWeek = new Date(today);
    if (isHebrew) {
      // For Hebrew, start the next week on Sunday
      startDateNextWeek.setDate(today.getDate() + (7 - currentDay));
    } else {
      // For other languages, start the next week on Monday
      startDateNextWeek.setDate(today.getDate() + (7 - currentDay + 1));
    }

    const currentWeekDates = [];
    const nextWeekDates = [];

    const options = {
      weekday: "short",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    // Format function to match the database date format
    const formatDateForDatabase = (date) => {
      return moment(date).format("MM/DD/YYYY");
    };

    const numberOfDaysToDisplay = 6; // Always display 6 days

    for (let i = 0; i < numberOfDaysToDisplay; i++) {
      const currentDate = new Date(startDateCurrentWeek);
      currentDate.setDate(startDateCurrentWeek.getDate() + i);
      const formattedDate = currentDate.toLocaleDateString(
        currentTimeZone,
        options
      );

      const formattedDateForDatabase = formatDateForDatabase(currentDate);

      currentWeekDates.push({
        day: formattedDate.split(",")[0], // Extract day name
        date: formattedDateForDatabase, // Use formatted date for the database
      });

      const nextDate = new Date(startDateNextWeek);
      nextDate.setDate(startDateNextWeek.getDate() + i);
      const nextDateFormatted = nextDate.toLocaleDateString(
        currentTimeZone,
        options
      );

      nextWeekDates.push({
        day: nextDateFormatted.split(",")[0].slice(0, 3), // Extract day name
        date: formatDateForDatabase(nextDate), // Use formatted date for the database
      });
    }

    // Merge data from weeklyRecipes into currentWeekDates and nextWeekDates
    currentWeekDates.forEach((day) => {
      const matchingRecipe = weeklyRecipes.find(
        (recipe) => recipe.date === day.date
      );
      if (matchingRecipe) {
        day.image = matchingRecipe.image;
        day.short_name = matchingRecipe.short_name;
        day.recipe_id = matchingRecipe.recipe_id;
      }
    });

    nextWeekDates.forEach((day) => {
      const matchingRecipe = weeklyRecipes.find(
        (recipe) => recipe.date === day.date
      );
      if (matchingRecipe) {
        day.image = matchingRecipe.image;
        day.short_name = matchingRecipe.short_name;
        day.recipe_id = matchingRecipe.recipe_id;
      }
    });

    setThisWeek(currentWeekDates);
    setNextWeek(nextWeekDates);
  }, [weeklyRecipes, timeZoneRedux]);
  // useEffect(() => {
  //   const today = new Date();
  //   const currentDay = today.getDay(); // 0 (Sunday) to 6 (Saturday)

  //   // check current household timezone
  //   const currentTimeZone =
  //     timeZoneRedux === "America/New_York" ? "en-US" : "en-GB";

  //   const storedLang = localStorage.getItem("lang");

  //   // Calculate the start date of the current week (Monday)
  //   const startDateCurrentWeek = new Date(today);
  //   startDateCurrentWeek.setDate(today.getDate() - currentDay + 1);

  //   // Calculate the start date of the next week (Monday)
  //   const startDateNextWeek = new Date(today);
  //   startDateNextWeek.setDate(today.getDate() + (7 - currentDay + 1));

  //   const currentWeekDates = [];
  //   const nextWeekDates = [];

  //   const options = {
  //     weekday: "short",
  //     year: "numeric",
  //     month: "2-digit",
  //     day: "2-digit",
  //   };

  //   // Format function to match the database date format
  //   const formatDateForDatabase = (date) => {
  //     return moment(date).format("MM/DD/YYYY");
  //   };

  //   for (let i = 0; i < 6; i++) {
  //     const currentDate = new Date(startDateCurrentWeek);
  //     currentDate.setDate(startDateCurrentWeek.getDate() + i);
  //     const formattedDate = currentDate.toLocaleDateString(
  //       currentTimeZone,
  //       options
  //     );

  //     const formattedDateForDatabase = formatDateForDatabase(currentDate);

  //     currentWeekDates.push({
  //       day: formattedDate.split(",")[0], // Extract day name
  //       date: formattedDateForDatabase, // Use formatted date for the database
  //     });

  //     const nextDate = new Date(startDateNextWeek);
  //     nextDate.setDate(startDateNextWeek.getDate() + i);
  //     const nextDateFormatted = nextDate.toLocaleDateString(
  //       currentTimeZone,
  //       options
  //     );

  //     nextWeekDates.push({
  //       day: nextDateFormatted.split(",")[0].slice(0, 3), // Extract day name
  //       date: formatDateForDatabase(nextDate), // Use formatted date for the database
  //     });
  //   }

  //   // Merge data from weeklyRecipes into currentWeekDates and nextWeekDates
  //   currentWeekDates.forEach((day) => {
  //     const matchingRecipe = weeklyRecipes.find(
  //       (recipe) => recipe.date === day.date
  //     );
  //     if (matchingRecipe) {
  //       day.image = matchingRecipe.image;
  //       day.short_name = matchingRecipe.short_name;
  //       day.recipe_id = matchingRecipe.recipe_id;
  //     }
  //   });

  //   nextWeekDates.forEach((day) => {
  //     const matchingRecipe = weeklyRecipes.find(
  //       (recipe) => recipe.date === day.date
  //     );
  //     if (matchingRecipe) {
  //       day.image = matchingRecipe.image;
  //       day.short_name = matchingRecipe.short_name;
  //       day.recipe_id = matchingRecipe.recipe_id;
  //     }
  //   });

  //   setThisWeek(currentWeekDates);
  //   setNextWeek(nextWeekDates);
  // }, [weeklyRecipes, timeZoneRedux]);
  useEffect(() => {
    if (stateOfButtons === "Last week") {
      setWeekOnDisplay(thisWeek);
    } else {
      setWeekOnDisplay(nextWeek);
    }
  }, [thisWeek, nextWeek]);

  const handleWeekChange = (weekToDisplay) => {
    if (weekToDisplay === stateOfButtons) return;

    setStateOfButtons(weekToDisplay);
    if (weekToDisplay === "Last week") setWeekOnDisplay(thisWeek);
    else setWeekOnDisplay(nextWeek);
  };
  const handlePopUp = async (date) => {
    const profileId = currentProfileRedux?.id;
    const recipeId = currentRecipeRedux?.id;

    try {
      const response = await recipeService.addRecipeToWeeklyMenu(
        profileId,
        recipeId,
        date
      );
      if (response.message === "success") {
        dispatch(setWeeklyRecipesRedux(response.weeklyRecipes));
        dispatch(setWeeklyRecipesPopupOpenRedux(false));
        toast.success(t("Recipe added to weekly menu"));
      } else {
        toast.error(t("Error adding recipe to weekly menu"));
        console.log(response, "error box recipe popup");
      }
    } catch (err) {
      console.log(err, "error box recipe popup");
    }
  };

  const handleRecipeReview = async (recipeId) => {
    // when user clicks on recipe in weekly recipes
    // we need to fetch the recipe and set it as current recipe
    if (!recipeId) return;

    try {
      const response = await recipeService.getRecipeById(recipeId);
      if (response.message === "success") {
        dispatch(setCurrentRecipeRedux(response.recipe));
        props.navigate(`/recipe-review`);
      } else {
        console.log(
          response,
          "response error fetch recipe for review in weekly recipes"
        );
      }
    } catch (err) {
      console.log(err, "error fetch recipe for review in weekly recipes");
    }
  };
  const handleSaveTemplate = async () => {
    const checkIfWeekHasRecipes = weekOnDisplay.filter(
      (day) => day.recipe_id !== undefined
    );
    if (!checkIfWeekHasRecipes.length)
      return toast.error(t("No recipes to save as template"));

    try {
      const save = await recipeService.saveTemplate(
        currentProfileRedux.id,
        weekOnDisplay,
        stateOfButtons
      );
      if (save.message === "success") {
        toast.success(t("Template saved"));
      } else {
        toast.error(t("Error saving template"));
        console.log(save, "error saving template");
      }
    } catch (err) {
      console.log(err, "error saving template");
    }
  };
  const handleUseTemplate = async () => {
    if (!currentProfileRedux.id) return;

    try {
      const useTemplate = await recipeService.getTemplate(
        currentProfileRedux.id,
        weekOnDisplay
      );
      if (useTemplate.message === "No template found")
        return toast.error(t("No template found"));

      if (useTemplate.message === "success") {
        dispatch(setWeeklyRecipesRedux(useTemplate.weeklyRecipes));
        dispatch(setWeeklyRecipesPopupOpenRedux(false));
        toast.success(t("Template applied"));
      } else {
        console.log(useTemplate, "useTemplate error");
      }
    } catch (err) {
      console.log(err, "err in handleUseTemplate");
    }
  };

  const removeRecipeFromCalendar = async (week) => {
    try {
      const response = await recipeService.removeRecipeFromCalendar(
        currentProfileRedux.id,
        week.date
      );
      if (response.message === "success") {
        toast.success(t("Recipe removed from calendar"));

        const weekly = await recipeService.getWeeklyRecipes(
          currentProfileRedux.id
        );

        if (weekly.message === "success") {
          dispatch(setWeeklyRecipesRedux(weekly.weeklyRecipes));
          if (weekly.weeklySupplies?.length) {
            dispatch(setWeeklySuppliesRedux(weekly.weeklySupplies));
          } else {
            dispatch(setWeeklySuppliesRedux([]));
          }
        } else if (weekly.message === "No weekly recipes found") {
          dispatch(setWeeklyRecipesRedux([]));
          dispatch(setWeeklySuppliesRedux([]));
        } else {
          toast.error(t("Error removing recipe from calendar"));
          console.log(weekly, "error removing recipe from calendar");
        }
      } else {
        toast.error(t("Error removing recipe from calendar"));
        console.log(response, "error removing recipe from calendar");
      }
    } catch (err) {
      console.log(err, "error removing recipe from calendar");
    }
  };
  return (
    <>
      <div
        className={
          mobile
            ? "weekly-recipes-container"
            : "weekly-recipes-container desktop"
        }
      >
        <div className="weekly-recipes-top">
          <div className={mobile ? "title-wrapper" : "title-wrapper desktop"}>
            <img src={calender} alt="" />
            {/* if popup OR homepage mobile/desktop */}
            <span
              className={mobile ? "title-mobile" : "title-desktop"}
              style={{
                color: props.context === "popup" ? "#ffffff" : "#3c4964",
              }}
            >
              {t("weekly_recipe1")}
            </span>
          </div>
          {props.context === "home" && (
            <div
              className={mobile ? "template" : "template desktop"}
              onClick={handleUseTemplate}
            >
              <img src={template} alt="" />
              <span>{t("use_template")}</span>
            </div>
          )}
        </div>

        <div
          className={
            mobile
              ? "weekly-recipes-calender"
              : "weekly-recipes-calender desktop"
          }
          style={{
            margin:
              props.context === "popup" || !mobile
                ? "10px 0 0 0"
                : "20px 0 0 0",
          }}
        >
          {weekOnDisplay.length && (
            <>
              {weekOnDisplay.map((week) => {
                const parts = week.date.split("/");
                const month = parts[0];
                const day = parts[1];
                return (
                  <div
                    className="calender"
                    style={{ gap: props.context === "popup" ? "5px" : "0" }}
                    key={week.day}
                  >
                    <div
                      className={
                        mobile ? "calender-header" : "calender-header desktop"
                      }
                      style={{
                        padding: mobile ? "" : "8px 10px",
                      }}
                    >
                      <div className="day">
                        {t(`${week.day.toLowerCase()}`)}
                      </div>
                      <div className="date">{`${day}/${month}`}</div>
                    </div>
                    <div
                      className={
                        mobile ? "calender-body" : "calender-body desktop"
                      }
                      style={{
                        boxShadow:
                          props.context === "popup"
                            ? "0px 0px 5px 0px rgba(0,0,0,0.2)"
                            : "none",
                      }}
                    >
                      {week?.image ? (
                        <>
                          <div
                            className="selected-recipe"
                            // onClick={
                            //   props.context === "popup"
                            //     ? () => handlePopUp(week.date)
                            //     : () => handleRecipeReview(week.recipe_id)
                            // }
                          >
                            <img
                              src={`${imageBaseUrl}recipe-image/${week.image}`}
                              className={
                                mobile
                                  ? "recipe-thumbnail"
                                  : "recipe-thumbnail desktop"
                              }
                              onClick={
                                // props.context === "popup"
                                //   ? () => handlePopUp(week.date)
                                //   :
                                () => handleRecipeReview(week.recipe_id)
                              }
                              // onClick={
                              //   props.navigate === null
                              //     ? null
                              //     : () =>
                              //         props.navigate(
                              //           `/recipes/${encodeURIComponent(
                              //             week.date
                              //           )}`
                              //         )
                              // }
                            />
                            <div
                              className={
                                mobile
                                  ? "recipe-short-name"
                                  : "recipe-short-name desktop"
                              }
                            >
                              {sliceTextToMaxLength(week.short_name, 17)}
                            </div>
                            <span
                              className="remove-recipe-from-calendar"
                              onClick={() => removeRecipeFromCalendar(week)}
                            >
                              <IconContext.Provider
                                value={{
                                  color: "#000000",
                                  size: "1.1rem",
                                  className: "global-class-name",
                                }}
                              >
                                <MdOutlineDeleteForever />
                              </IconContext.Provider>
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <img
                            src={addSign}
                            style={{ width: mobile ? "28px" : "20px" }}
                            alt=""
                            onClick={
                              props.navigate === null
                                ? () => handlePopUp(week.date)
                                : () =>
                                    props.navigate(
                                      `/recipes/${encodeURIComponent(
                                        week.date
                                      )}`
                                    )
                            }
                          />
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
        <div className={mobile ? "calender-bottom" : "calender-bottom desktop"}>
          <div
            className="save-template"
            style={{
              margin:
                props.context === "popup" || !mobile
                  ? "5px 0 0 0"
                  : "10px 0 6px 0",
            }}
            onClick={handleSaveTemplate}
          >
            {t("save_template")}
          </div>
          <div className={mobile ? "choose-week" : "choose-week desktop"}>
            <span onClick={() => handleWeekChange("Last week")}>
              <IconContext.Provider
                value={{
                  color:
                    props.context === "popup" && stateOfButtons === "Last week"
                      ? "#fff"
                      : props.context === "popup" &&
                        stateOfButtons === "Next week"
                      ? "grey"
                      : props.context !== "popup" &&
                        stateOfButtons === "Next week"
                      ? "grey"
                      : "#000",
                }}
              >
                <FaChevronLeft className="calender-left-icon" />
              </IconContext.Provider>
            </span>
            <span
              className="last-week"
              onClick={() => handleWeekChange("Last week")}
              style={{
                color:
                  props.context === "popup" && stateOfButtons === "Last week"
                    ? "#fff"
                    : props.context === "popup" &&
                      stateOfButtons === "Next week"
                    ? "grey"
                    : props.context !== "popup" &&
                      stateOfButtons === "Next week"
                    ? "grey"
                    : "#000",
              }}
            >
              {t("last_week")}
            </span>
            {mobile && props.context !== "popup" && (
              <div className="line"></div>
            )}
            <span
              className="next-week"
              onClick={() => handleWeekChange("Next week")}
              style={{
                color:
                  props.context === "popup" && stateOfButtons === "Next week"
                    ? "#fff"
                    : props.context === "popup" &&
                      stateOfButtons === "Last week"
                    ? "grey"
                    : props.context !== "popup" &&
                      stateOfButtons === "Last week"
                    ? "grey"
                    : "#000",
              }}
            >
              {t("next_week")}
            </span>

            <span onClick={() => handleWeekChange("Next week")}>
              <IconContext.Provider
                value={{
                  color:
                    props.context === "popup" && stateOfButtons === "Next week"
                      ? "#fff"
                      : props.context === "popup" &&
                        stateOfButtons === "Last week"
                      ? "grey"
                      : props.context !== "popup" &&
                        stateOfButtons === "Last week"
                      ? "grey"
                      : "#000",
                }}
              >
                <FaChevronRight className="calender-right-icon" />
              </IconContext.Provider>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default WeeklyRecipes;
