import "./styles/delete-recipe-popup.css";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { setDeleteRecipePopupOpenRedux } from "../redux/reducer";
import recipeService from "../services/recipeService";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const DeleteRecipePopup = () => {
  const dispatch = useDispatch();
  const recipe = useSelector((state) => state.mainSlice.currentRecipe);
  const [recipeId, setRecipeId] = useState(0);
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  useEffect(() => {
    if (recipe) {
      setRecipeId(recipe.id);
    }
  }, [recipe]);
  const deleteRecipe = async () => {
    try {
      const response = await recipeService.deleteRecipe(recipeId);
      if (response.message === "success") {
        dispatch(setDeleteRecipePopupOpenRedux(false));
        toast.success("Recipe deleted ");
        window.location.replace("/");
      } else {
        toast.error("Error deleting recipe");
        console.log(response, "response from delete recipe");
      }
    } catch (err) {
      console.log(err, "error deleting recipe");
    }
  };
  return (
    <>
      <div className="delete-recipe-popup-container">
        <div className="delete-recipe-main">
          <p style={{ direction: languageDirection }}>
            {t("delete_recipe_confirmation")}
          </p>
          <div className="delete-recipe-buttons">
            <button className="delete-recipe yes" onClick={deleteRecipe}>
              {t("yes")}
            </button>
            <button
              className="delete-recipe no"
              onClick={() => dispatch(setDeleteRecipePopupOpenRedux(false))}
            >
              {t("no")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteRecipePopup;
