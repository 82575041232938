import axiosBase from "../axios/axios";

const articleService = {
  createArticle: async (article) => {
    try {
      const { data } = await axiosBase.post(`/article/createArticle`, article);
      return data;
    } catch (err) {
      console.log(err, "error in createArticle");
    }
  },
  getArticles: async (profileId) => {
    try {
      const { data } = await axiosBase.get(`/article/getArticles/${profileId}`);
      return data;
    } catch (err) {
      console.log(err, "error in getArticlesAndTestimonials");
    }
  },
  deleteArticle: async (item) => {
    try {
      const { data } = await axiosBase.post(`/article/deleteArticle`, item);
      return data;
    } catch (err) {
      console.log(err, "error in deleteArticle");
    }
  },
  editArticle: async (item) => {
    try {
      const { data } = await axiosBase.post(`/article/editArticle`, item);
      return data;
    } catch (err) {
      console.log(err, "error in editArticle");
    }
  },
  createNotification: async (notification) => {
    try {
      const { data } = await axiosBase.post(
        `/article/createNotification`,
        notification
      );
      return data;
    } catch (err) {
      console.log(err, "error in createNotification");
    }
  },
  getNotifications: async (profileId) => {
    try {
      const { data } = await axiosBase.get(
        `/article/getNotifications/${profileId}`
      );
      return data;
    } catch (err) {
      console.log(err, "error in getNotifications");
    }
  },
  notificationViewed: async (profileId, notificationId) => {
    try {
      const { data } = await axiosBase.post(`/article/notificationViewed`, [
        profileId,
        notificationId,
      ]);
      if (data.message === "success") {
        const { data } = await axiosBase.get(
          `/article/getNotifications/${profileId}`
        );
        return data;
      } else {
        console.log("error in notificationViewed");
      }
    } catch (err) {
      console.log(err, "error in notificationViewed");
    }
  },
  deleteNotification: async (profileId, notificationId) => {
    try {
      const { data } = await axiosBase.post(`/article/deleteNotification`, [
        notificationId,
      ]);
      if (data.message === "success") {
        const { data } = await axiosBase.get(
          `/article/getNotifications/${profileId}`
        );
        return data;
      } else {
        console.log("error in deleteNotification");
      }
    } catch (err) {
      console.log(err, "error in deleteNotification");
    }
  },
  createGame: async (game) => {
    try {
      const { data } = await axiosBase.post(`/article/createGame`, game);
      return data;
    } catch (err) {
      console.log(err, "error in createGame");
    }
  },
  fetchGamesByOperatingSystem: async (operatingSystem) => {
    try {
      const { data } = await axiosBase.get(
        `/article/fetchGamesByOperatingSystem/${operatingSystem}`
      );
      return data;
    } catch (err) {
      console.log(err, "error in fetchGamesByOperatingSystem");
    }
  },
  deleteGame: async (gameId) => {
    try {
      const { data } = await axiosBase.delete(`/article/deleteGame/${gameId}`);
      return data;
    } catch (err) {
      console.log(err, "error in deleteGame");
    }
  },
};
export default articleService;
