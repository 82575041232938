import "./styles/recipe-mobile.css";
import { useEffect, useState } from "react";
import redHeart from "../images/red-heart.png";
import whiteHeart from "../images/white-heart.png";
import recipeImagePlaceholder from "../images/recipes/1.jpg";
import vegan from "../images/green.png";
import allergy from "../images/purple-new.png";
import StarRatings from "react-star-ratings";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setCurrentRecipeRedux,
  setGeneralRecipes,
  setPersonalRecipes,
} from "../redux/reducer";
import { AiOutlineEdit } from "react-icons/ai";
import { IconContext } from "react-icons";
import { useSelector } from "react-redux";
import imageBaseUrl from "../config";
import recipeService from "../services/recipeService";
import sliceTextToMaxLength from "../utils/sliceTextToMaxLength";
// diet icons
import pescatarian from "../images/icons/pescatarian.png";
import veganIcon from "../images/icons/vegan.png";
import vegetarianIcon from "../images/icons/vegetarian.png";

// allergy icons
import genericAllergy from "../images/icons/general-allergy.png";
import glutenFree from "../images/icons/gluten.png";
import lactoseFree from "../images/icons/lactose.png";
import nutFree from "../images/icons/nuts.png";
import eggFree from "../images/icons/eggs.png";
import fishFree from "../images/icons/fish.png";
import sesameFree from "../images/icons/sesame.png";
import soyFree from "../images/icons/soy.png";
import { useTranslation } from "react-i18next";
// RecipeCardMobile
const RecipeCardMobile = ({
  recipe,
  handleRecipeClick = null,
  context = null,
}) => {
  const mobile = useMediaQuery({ minWidth: 390, maxWidth: 800 });
  const smallMobile = useMediaQuery({ maxWidth: 380 });
  const mobileUpTo450px = useMediaQuery({ maxWidth: 450 });
  const desktop800To950 = useMediaQuery({ minWidth: 801, maxWidth: 950 });
  const desktop951To1200 = useMediaQuery({ minWidth: 951, maxWidth: 1200 });

  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const textAlignment = i18n.language === "he" ? "right" : "left";
  const currentProfile = useSelector(
    (state) => state.mainSlice.currentChildProfile
  );
  const [isFavorite, setIsFavorite] = useState(recipe.favorite);
  const [recipeDisclaimerIcon, setRecipeDisclaimerIcon] = useState(false);
  const [recipeLifeStyle, setRecipeLifeStyle] = useState(recipe.life_style);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myCreatedRecipesRedux = useSelector(
    (state) => state.mainSlice.myCreatedRecipes
  );

  const handleFavoriteClick = async () => {
    /* if recipe was created by user we dont add to favorites */
    if (recipe.type === "personal") return;
    try {
      const response = await recipeService.handleFavorites(
        currentProfile.id,
        recipe.id,
        !isFavorite
      );
      if (context === "todays-suggestion") return;
      // if user added today's suggestion to favorites
      // we dont need to fetch recipes

      if (response.message === "success") {
        const getRecipes = await recipeService.getRecipes(currentProfile.id);
        if (getRecipes.message === "success") {
          getRecipes.personalRecipes.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );

          getRecipes.generalRecipes.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );
          if (myCreatedRecipesRedux.length) {
            getRecipes.generalRecipes = getRecipes.generalRecipes.filter(
              (recipe) => {
                return !myCreatedRecipesRedux.some(
                  (myRecipe) => myRecipe.id === recipe.id
                );
              }
            );
          }
          dispatch(setGeneralRecipes(getRecipes.generalRecipes));
          dispatch(setPersonalRecipes(getRecipes.personalRecipes));
        }
      }
    } catch (err) {
      console.log(err, "error adding favorite");
    }
  };
  useEffect(() => {
    // if recipe has allergy or life style = vegan or vegetarian show icon
    if (
      recipe.allergy_free !== null ||
      recipe.life_style === "1" ||
      recipe.life_style === "2"
    ) {
      setRecipeDisclaimerIcon(true);
    }

    //  recipe life style = 1,2,3
    if (recipe?.life_style !== null) {
      if (recipe?.life_style?.includes(",")) {
        setRecipeLifeStyle(recipe.life_style.split(",")[0]);
      } else {
        setRecipeLifeStyle(recipe.life_style);
      }
    }
  }, [recipe]);

  return (
    <>
      <div className="recipe">
        <div className="recipe-img">
          <div
            style={{
              backgroundImage: `url(${imageBaseUrl}recipe-image/${recipe.image})`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
              width: "100%",
              height: "200px",
            }}
            onClick={handleRecipeClick ? () => handleRecipeClick(recipe) : null}
          ></div>

          <div className="heart" onClick={handleFavoriteClick}>
            <img
              src={
                recipe.type === "personal"
                  ? redHeart
                  : recipe?.favorite
                  ? redHeart
                  : whiteHeart
              }
              alt=""
            />
          </div>
        </div>
        <div
          className="recipe-details"
          style={{
            background: recipe.type === "personal" ? "#b7dfeb" : "#e0eef2",
          }}
          onClick={handleRecipeClick ? () => handleRecipeClick(recipe) : null}
        >
          {/* {recipeDisclaimerIcon && ( */}
          <div className="top">
            {recipe.allergy_free !== null && (
              <>
                {recipe?.allergy_free?.includes(",") ? (
                  recipe.allergy_free.trim().split(",").length > 2 ? (
                    <img
                      src={genericAllergy}
                      alt=""
                      className="recipe-allergy"
                    />
                  ) : (
                    <>
                      {recipe.allergy_free.split(",").map((item, index) => (
                        <img
                          key={index}
                          src={
                            item.trim() === "1"
                              ? eggFree
                              : item.trim() === "2"
                              ? fishFree
                              : item.trim() === "3"
                              ? nutFree
                              : item.trim() === "4"
                              ? glutenFree
                              : item.trim() === "5"
                              ? soyFree
                              : item.trim() === "6"
                              ? sesameFree
                              : item.trim() === "7"
                              ? lactoseFree
                              : null
                          }
                          alt=""
                          className={`recipe-allergy recipe-allergy-${
                            index + 1
                          }`}
                        />
                      ))}
                    </>
                  )
                ) : (
                  <img
                    src={
                      recipe.allergy_free === "1"
                        ? eggFree
                        : recipe.allergy_free === "2"
                        ? fishFree
                        : recipe.allergy_free === "3"
                        ? nutFree
                        : recipe.allergy_free === "4"
                        ? glutenFree
                        : recipe.allergy_free === "5"
                        ? soyFree
                        : recipe.allergy_free === "6"
                        ? sesameFree
                        : recipe.allergy_free === "7"
                        ? lactoseFree
                        : null
                    }
                    alt=""
                    className="recipe-allergy"
                  />
                )}
              </>
            )}
            {recipe.life_style !== null && (
              <img
                src={
                  recipeLifeStyle === "1"
                    ? vegetarianIcon
                    : recipeLifeStyle === "2"
                    ? veganIcon
                    : recipeLifeStyle === "3"
                    ? pescatarian
                    : null
                }
                alt=""
                className="recipe-vegan"
              />
            )}
          </div>
          {/* )} */}
          <div className={recipeDisclaimerIcon ? "name" : "name no-top-icon"}>
            <p
              style={{
                marginTop:
                  recipe.life_style !== null || recipe.allergy_free !== null
                    ? "10px"
                    : "30px",
                padding:
                  desktop951To1200 || desktop800To950
                    ? "4px 10px 0 10px"
                    : languageDirection === "rtl"
                    ? "0 30px 0 20px "
                    : "0 20px 0 30px",
                textAlign: textAlignment,
              }}
            >
              {sliceTextToMaxLength(recipe.name, 50)}
            </p>
          </div>
          {recipe.type === "personal" ? (
            <>
              <div className="personal-recipe-mobile">
                <span>
                  <IconContext.Provider
                    value={{
                      color: "#3AA7D5",
                      size: smallMobile ? "1rem" : "1.5rem",
                    }}
                  >
                    <AiOutlineEdit />
                  </IconContext.Provider>
                </span>
                <span> {t("watch_edit")}</span>
              </div>
            </>
          ) : (
            <>
              <div>
                <div
                  className="rating"
                  style={{
                    direction: languageDirection,
                    padding:
                      languageDirection === "rtl" ? "0 30px 0 0" : "0 0 0 30px",
                  }}
                >
                  <span>{t("rate")}</span>
                  <StarRatings
                    rating={recipe.rating || 5}
                    starRatedColor="gold"
                    //   changeRating={handleRatingChange}
                    numberOfStars={5}
                    name="rating"
                    starDimension={
                      mobile
                        ? "22px"
                        : smallMobile || desktop951To1200
                        ? "12px"
                        : desktop800To950
                        ? "9px"
                        : "15px"
                    } // Adjust the size of the stars
                    starSpacing={
                      desktop800To950 || desktop951To1200 ? "2px" : "3px"
                    } // Adjust the spacing between stars
                    className="star-ratings" // Apply your custom CSS class here
                  />
                </div>
                <div
                  className="cooking-time "
                  style={{
                    direction: languageDirection,
                    padding:
                      languageDirection === "rtl"
                        ? "0 30px 0 0 "
                        : "0 0 0 30px",
                    marginBottom: "10px",
                  }}
                >
                  <span
                    className="cooking-time-title"
                    style={{
                      width: mobileUpTo450px
                        ? "50%"
                        : languageDirection === "rtl"
                        ? "40%"
                        : "60%",
                    }}
                  >
                    {t("cooking_time_recipe_card")}
                  </span>
                  <span
                    className="cooking-time-time"
                    style={{
                      width: mobileUpTo450px ? "50%" : "50%",
                    }}
                  >{`${recipe.cooking_time} ${t("min")} `}</span>
                </div>
                <div
                  className="prep-time"
                  style={{
                    direction: languageDirection,
                    padding:
                      languageDirection === "rtl"
                        ? "0 30px 0 0 "
                        : "0 0 0 30px",
                  }}
                >
                  <span
                    className="prep-time-title"
                    style={{
                      width: mobileUpTo450px
                        ? "50%"
                        : languageDirection === "rtl"
                        ? "40%"
                        : "60%",
                    }}
                  >
                    {t("short_prep_time")}
                  </span>
                  <span
                    className="prep-time-min"
                    style={{
                      width: mobileUpTo450px ? "50%" : "50%",
                    }}
                  >{`${recipe.prep_time} ${t("min")} `}</span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default RecipeCardMobile;
