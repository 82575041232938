import "./styles/nutritional-values-popup.css";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IoMdClose } from "react-icons/io";
import { IconContext } from "react-icons";
import data from "../utils/static-data";
import HealthRateChart from "./healthRate";
import { setNutritionalValuesPopupRedux } from "../redux/reducer";
import { useTranslation } from "react-i18next";
const NutritionalValuesPopup = () => {
  const [nutritionalValues, setNutritionalValues] = useState([]);
  const [healthScoreInWords, setHealthScoreInWords] = useState("");
  const [allergies, setAllergies] = useState(null);
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const textDirection = i18n.language === "he" ? "right" : "left";
  const dispatch = useDispatch();
  const currentRecipeRedux = useSelector(
    (state) => state.mainSlice.currentRecipe
  );
  useEffect(() => {
    const healthScore = parseFloat(currentRecipeRedux.health_rate);
    if (!Number.isNaN(healthScore)) {
      if (healthScore >= 8) {
        setHealthScoreInWords("High");
      } else if (healthScore >= 6) {
        setHealthScoreInWords("Medium");
      } else {
        setHealthScoreInWords("Low");
      }
    } else {
      setHealthScoreInWords(null);
    }

    if (
      currentRecipeRedux?.allergies &&
      currentRecipeRedux.allergies !== null &&
      typeof currentRecipeRedux.allergies !== "undefined"
    ) {
      const allergensArray = currentRecipeRedux.allergies.split(",");
      if (Array.isArray(allergensArray) && allergensArray.length > 1) {
        // Multiple allergens
        const allergyNames = data.allergies.filter((allergy) => {
          allergensArray.includes(allergy.value);
        });
        setAllergies(allergyNames);
      } else {
        // Single allergen or no allergens
        const allergyName = data.allergies.find(
          (allergy) =>
            allergy.value === parseFloat(currentRecipeRedux.allergies)
        );
        setAllergies([allergyName]);
      }
    }

    if (
      currentRecipeRedux?.nutritional_values !== null &&
      currentRecipeRedux?.nutritional_values?.length > 0
    ) {
      setNutritionalValues(currentRecipeRedux.nutritional_values);
    } else {
      setNutritionalValues([]);
    }
  }, [currentRecipeRedux]);

  return (
    <div className="nutritional-values-popup">
      <div className="nutritional-values-main">
        <div
          className="nutritional-values-header"
          style={{
            flexDirection: languageDirection === "ltr" ? "row" : "row-reverse",
          }}
        >
          <h2>{t("nutritional_values")}</h2>
          <span onClick={() => dispatch(setNutritionalValuesPopupRedux(false))}>
            <IconContext.Provider value={{ className: "close-icon" }}>
              <IoMdClose style={{ fontSize: "25px" }} />
            </IconContext.Provider>
          </span>
        </div>
        <div className="nutritional-values-body">
          <div
            className="nutritional-data"
            style={{ direction: languageDirection }}
          >
            <div className="top">
              <div className="left-side">
                <div className="chart-wrapper">
                  <HealthRateChart
                    value={currentRecipeRedux.health_rate}
                    chartColor={
                      healthScoreInWords === "High"
                        ? "#95bf3e"
                        : healthScoreInWords === "Medium"
                        ? "#f6dd38"
                        : "#fdb72c"
                    }
                  />
                </div>
                <div className="current-score">
                  <span>{t("health_score")}</span>
                  <span
                    style={{
                      backgroundColor:
                        healthScoreInWords === "High"
                          ? "#95bf3e"
                          : healthScoreInWords === "Medium"
                          ? "#f6dd38"
                          : "#fdb72c",
                      textAlign: "center",
                    }}
                  >
                    {t(`${healthScoreInWords}`)}
                  </span>
                </div>
              </div>
              <div className="right-side">
                <div className="high">
                  <span className="square green"></span>
                  {t("high_score")}
                </div>
                <div className="medium">
                  <span className="square yellow"></span> {t("medium_score")}
                </div>
                <div className="low">
                  <span className="square red"></span> {t("low_score")}
                </div>
              </div>
            </div>
            <div className="bottom">
              {t("nutritional_values_popup_header_text")}
            </div>
          </div>

          <table
            className="nutritional-values-table"
            style={{ direction: languageDirection }}
          >
            <thead>
              <tr>
                <th style={{ textAlign: textDirection }}>
                  {t("nutrient_table")}
                </th>
                <th style={{ textAlign: textDirection }}>{t("amount")}</th>
                {nutritionalValues.length && nutritionalValues[0].percent && (
                  <th style={{ textAlign: textDirection }}>{t("percent")}</th>
                )}
              </tr>
            </thead>
            <tbody>
              {nutritionalValues.map((value, index) => (
                <tr key={index}>
                  <td style={{ textAlign: textDirection }}>{value.nutrient}</td>
                  <td style={{ textAlign: textDirection }}>{value.amount}</td>
                  {value?.percent && (
                    <td
                      style={{ textAlign: textDirection }}
                    >{`${value.percent}%`}</td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div
          className="nutritional-values-footer"
          style={{ direction: languageDirection }}
        >
          <p>{t("nutritional_values_popup_footer_para_1")}</p>
          <p>{t("nutritional_values_popup_footer_para_2")}</p>
          <p style={{ marginBottom: "20px" }}>
            {t("nutritional_values_popup_footer_para_3")}
          </p>
          <div>
            <button
              onClick={() => dispatch(setNutritionalValuesPopupRedux(false))}
            >
              {t("close")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NutritionalValuesPopup;
