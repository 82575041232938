import { useMediaQuery } from "react-responsive";
import HeaderMobile from "./header-mobile";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import favorite from "../images/favorite.png";
import search from "../images/search.png";
import addSign from "../images/weekly-recipes/blue.png";
import "./styles/recipes.css";
import { FaHome } from "react-icons/fa";
import RecipeCardMobile from "./recipe-card-mobile";
import { IconContext } from "react-icons";
import HeaderDesktop from "./header-desktop";
import RecipeCardDesktop from "./recipe-card-desktop";
import FooterDesktop from "./footer-desktop";
import { useNavigate } from "react-router-dom";
import YellowSeparator from "./yellow-separator";
import { useSelector, useDispatch } from "react-redux";
import recipeService from "../services/recipeService";
import {
  setCurrentRecipeRedux,
  setWeeklyRecipesRedux,
  setRecipePanelEditMode,
  setWeeklySuppliesRedux,
} from "../redux/reducer";
import moment from "moment";
import { useTranslation } from "react-i18next";
const Recipes = () => {
  const mobile = useMediaQuery({ maxWidth: 800 });
  const under470px = useMediaQuery({ maxWidth: 470 });
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const currentProfileRedux = useSelector(
    (state) => state.mainSlice.currentChildProfile
  );
  const personalRecipes = useSelector(
    (state) => state.mainSlice.personalRecipes
  );
  const generalRecipes = useSelector((state) => state.mainSlice.generalRecipes);
  // const myCreatedRecipes = useSelector(
  //   (state) => state.mainSlice.myCreatedRecipes
  // );
  const roleRedux = useSelector((state) => state.mainSlice.role);
  const [recipesBasedOnSelections, setRecipesBasedOnSelections] = useState([]);
  const [allRecipes, setAllRecipes] = useState([]);
  const [recipeDate, setRecipeDate] = useState(null);
  const [handleNavigation, setHandleNavigation] = useState(false);
  const { date } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!currentProfileRedux.id) {
      navigate("/");
    } else {
      if (date) {
        setRecipeDate(date);
      }
    }
  }, [date]);

  useEffect(() => {
    // Check if both generalRecipes and personalRecipes have length
    if (generalRecipes?.length && personalRecipes?.length) {
      // Combine both arrays into one array
      const combinedRecipes = [...generalRecipes, ...personalRecipes];
      setRecipesBasedOnSelections(combinedRecipes);
    } else if (generalRecipes?.length) {
      // If only generalRecipes has length
      setRecipesBasedOnSelections(generalRecipes);
    } else if (personalRecipes?.length) {
      // If only personalRecipes has length
      setRecipesBasedOnSelections(personalRecipes);
    }
  }, [personalRecipes, generalRecipes]);

  // no need for this function  because we are not using it at the moment
  const handleMoreRecipes = () => {
    if (allRecipes.length) {
      setAllRecipes([]);
    } else {
      setAllRecipes(generalRecipes);
    }
  };
  useEffect(() => {
    if (!mobile) {
      setAllRecipes(generalRecipes);
    } else {
      setAllRecipes([]);
    }
  }, [mobile]);

  const handleRecipeClick = async (recipe) => {
    // if recipeDate is null user came from my recipes button
    //and we need to navigate to the recipe review page

    if (recipeDate === null) {
      dispatch(setCurrentRecipeRedux(recipe));
      setHandleNavigation(true);
    } else {
      // if recipeDate is not null user wants to add recipe to weekly menu
      // we send data to db
      // Convert recipeDate to a consistent format (en-US)

      const dateObject = new Date(recipeDate);
      const isValidDate = !isNaN(dateObject.getTime());

      let formattedDate = null;

      if (isValidDate) {
        // If the date is valid, format it using moment
        formattedDate = moment(dateObject).format("MM/DD/YYYY");
      }

      const response = await recipeService.addRecipeToWeeklyMenu(
        currentProfileRedux.id,
        recipe.id,
        formattedDate ? formattedDate : recipeDate
      );
      console.log("response55", response);
      if (response.message === "success") {
        if (response.weeklyRecipes.length) {
          dispatch(setWeeklyRecipesRedux(response.weeklyRecipes));
        } else {
          dispatch(setWeeklyRecipesRedux([]));
        }

        const fetchWeeklyRecipes = await recipeService.getWeeklyRecipes(
          currentProfileRedux.id
        );
        if (fetchWeeklyRecipes.message === "success") {
          if (fetchWeeklyRecipes.weeklySupplies.length) {
            dispatch(setWeeklySuppliesRedux(fetchWeeklyRecipes.weeklySupplies));
          } else {
            dispatch(setWeeklySuppliesRedux([]));
          }
        }
        setHandleNavigation(true);
      }
    }
  };
  useEffect(() => {
    if (handleNavigation) {
      if (recipeDate === null) {
        navigate(`/recipe-review`);
      } else {
        navigate("/");
      }
    }
  }, [handleNavigation]);
  return (
    <>
      {mobile ? (
        <>
          <HeaderMobile location="recipes" />

          <div className="recipes-container">
            <YellowSeparator />
            <div
              className="recipes-title"
              style={{
                flexDirection:
                  languageDirection === "rtl" ? "row-reverse" : "row",
              }}
            >
              <span className="title">{t("recipes")} </span>
              <span>
                <IconContext.Provider value={{ className: "home-icon" }}>
                  <FaHome
                    style={{
                      fontSize: under470px ? "18px" : "22px",
                      color: "#a2d2e2",
                    }}
                    onClick={() => navigate("/")}
                  />
                </IconContext.Provider>
              </span>
              <span onClick={() => navigate("/favorites")}>
                <img src={favorite} alt="" />
              </span>
              <span>
                <img src={search} alt="" onClick={() => navigate("/search")} />
              </span>
            </div>
            <div className="recipes-restrictions"></div>

            <div className="recipes-list">
              {roleRedux === 1 && (
                <div
                  className="crate-recipe-btn-wrapper"
                  onClick={() => {
                    dispatch(setRecipePanelEditMode(false));
                    navigate("/create-recipe");
                  }}
                >
                  <span>
                    <img src={addSign} alt="" />
                  </span>
                  <span>{t("create-recipe")} </span>
                </div>
              )}

              {recipesBasedOnSelections.length > 0 && (
                <>
                  {recipesBasedOnSelections.map((recipe) => {
                    return (
                      <RecipeCardMobile
                        recipe={recipe}
                        key={recipe.id}
                        handleRecipeClick={handleRecipeClick}
                      />
                    );
                  })}
                </>
              )}
            </div>

            {/* <div className="show-hide-recipes" onClick={handleMoreRecipes}>
              {allRecipes.length ? (
                <>
                  <span> More recipes</span>
                  <span>
                    <IconContext.Provider value={{ className: "arrow-icon" }}>
                      <MdOutlineKeyboardArrowDown
                        style={{ fontSize: "22px" }}
                      />
                    </IconContext.Provider>
                  </span>
                </>
              ) : (
                <>
                  <span>Less recipes</span>
                  <span>
                    <IconContext.Provider value={{ className: "arrow-icon" }}>
                      <MdOutlineKeyboardArrowUp style={{ fontSize: "22px" }} />
                    </IconContext.Provider>
                  </span>
                </>
              )}
            </div>

            <div className="bottom-separator"></div>

            {allRecipes.length > 0 && (
              <>
                {allRecipes.map((recipe) => {
                  return (
                    <RecipeCardMobile
                      recipe={recipe}
                      key={recipe.id}
                      handleRecipeClick={handleRecipeClick}
                    />
                  );
                })}
              </>
            )} */}
          </div>
        </>
      ) : (
        <>
          {/* desktop */}
          <div className="recipes-container-desktop">
            <HeaderDesktop />

            <div
              className="recipes-desktop-top"
              style={{
                flexDirection:
                  languageDirection === "rtl" ? "row-reverse" : "row",
                paddingInlineEnd: languageDirection === "rtl" ? "80px" : "0px",
              }}
            >
              <div className="title-wrapper">
                <div className="title">{t("recipes")}</div>
              </div>
              <div className="favAndSearch">
                <div className="home-btn" onClick={() => navigate("/")}>
                  <IconContext.Provider value={{ className: "home-icon" }}>
                    <FaHome style={{ fontSize: "22px", color: "#a2d2e2" }} />
                  </IconContext.Provider>
                  <span>{t("home")}</span>
                </div>
                <div
                  className="favorite"
                  onClick={() => navigate("/favorites")}
                >
                  <img src={favorite} alt="" />
                  <span>{t("favorites")}</span>
                </div>
                <div className="search" onClick={() => navigate("/search")}>
                  <img src={search} alt="" />
                  <span>{t("search")}</span>
                </div>
              </div>
            </div>
            <div className="recipes-desktop-main">
              {roleRedux === 1 && (
                <div
                  className="create-recipe-btn-wrapper"
                  onClick={() => {
                    dispatch(setRecipePanelEditMode(false));

                    navigate("/create-recipe");
                  }}
                >
                  <span>
                    <img src={addSign} alt="" />
                  </span>
                  <span>{t("create-recipe")} </span>
                </div>
              )}

              {recipesBasedOnSelections.length > 0 && (
                <>
                  {recipesBasedOnSelections.map((recipe) => {
                    return (
                      <RecipeCardDesktop
                        recipe={recipe}
                        key={recipe.id}
                        handleRecipeClick={handleRecipeClick}
                      />
                    );
                  })}
                </>
              )}
            </div>
            {/* <div className="recipes-desktop-separator"></div> */}
            {/* <div className="recipes-desktop-bottom">
              <div className="title">More recipes:</div>
              <div className="more-recipes">
                {allRecipes?.length > 0 && (
                  <>
                    {allRecipes.map((recipe) => {
                      return (
                        <RecipeCardDesktop
                          recipe={recipe}
                          key={recipe.id}
                          handleRecipeClick={handleRecipeClick}
                        />
                      );
                    })}
                  </>
                )}
              </div>
            </div> */}
            <FooterDesktop />
          </div>
        </>
      )}
    </>
  );
};

export default Recipes;
