import axios from "axios";
const envType = process.env.NODE_ENV;
let axiosBase;
if (envType === "production") {
  axiosBase = axios.create({
    baseURL: "https://api.getyoopi.com/",
  });
} else {
  axiosBase = axios.create({
    baseURL: "http://localhost:8080/",
  });
}

axiosBase.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["x-auth-token"] = token;
    }
    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);
export default axiosBase;
