import { useEffect, useState } from "react";

import newProductImage from "../images/icons/notification_icons/New-Product.png";
import holidayImage from "../images/icons/notification_icons/Holiday.png";
import saleImage from "../images/icons/notification_icons/Sale.png";
import reminderImage from "../images/icons/notification_icons/Reminder.png";
import updateImage from "../images/icons/notification_icons/Update.png";
import contentImage from "../images/icons/notification_icons/Content.png";
import cleanBocImage from "../images/icons/notification_icons/CleanBox.png";
import otherImage from "../images/icons/notification_icons/Other.png";

import { GrFormClose } from "react-icons/gr";
import { IconContext } from "react-icons";
import { useSelector, useDispatch } from "react-redux";
import { MdOutlineDeleteForever } from "react-icons/md";
import articleService from "../services/articleService";
import sliceTextToMaxLength from "../utils/sliceTextToMaxLength";
import "./styles/notification.css";
import {
  setNotificationsRedux,
  setNotificationPopupOpenRedux,
  setCurrentNotification,
} from "../redux/reducer";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

const Notification = ({ notification }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const role = useSelector((state) => state.mainSlice.role);
  const [notificationImage, setNotificationImage] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentProfile = useSelector(
    (state) => state.mainSlice.currentChildProfile
  );
  useEffect(() => {
    switch (notification.type) {
      case "New Product":
        setNotificationImage(newProductImage);
        break;
      case "Holiday":
        setNotificationImage(holidayImage);
        break;
      case "Sale":
        setNotificationImage(saleImage);
        break;
      case "Reminder":
        setNotificationImage(reminderImage);
        break;
      case "Update":
        setNotificationImage(updateImage);
        break;
      case "Content":
        setNotificationImage(contentImage);
        break;
      case "Clean Box":
        setNotificationImage(cleanBocImage);
        break;
      case "Other":
        setNotificationImage(otherImage);
        break;
      default:
        setNotificationImage(otherImage);
    }
  }, [notification]);

  const handleDelete = async () => {
    if (role !== 2) return;

    try {
      const deleteNotification = await articleService.deleteNotification(
        currentProfile.id,
        notification.id
      );
      if (deleteNotification.message === "success") {
        toast.success("Notification deleted");
        dispatch(setNotificationsRedux(deleteNotification.notifications));
      } else {
        console.log(deleteNotification, "deleteNotification error");
      }
    } catch (err) {
      console.log(err, "err in handleDelete");
    }
  };

  const openNotificationPopup = async (notification) => {
    dispatch(setCurrentNotification(notification));
    dispatch(setNotificationPopupOpenRedux(true));
  };
  return (
    <div
      className={
        isMobile ? "notification-container" : "notification-container desktop"
      }
    >
      <div className="main">
        <div className="bubble">
          <img src={notificationImage} alt="" />
        </div>
        <div className="message">
          <p>{sliceTextToMaxLength(notification?.content, 10)}</p>
        </div>
        {role === 2 && (
          <div className="delete" onClick={handleDelete}>
            <IconContext.Provider
              value={{ className: "delete-icon", size: "1rem" }}
            >
              <MdOutlineDeleteForever style={{ marginBottom: "10px" }} />
            </IconContext.Provider>
          </div>
        )}
      </div>
      <div
        className="close"
        onClick={() => openNotificationPopup(notification)}
      >
        <p>{t("open")}</p>
        {/* <div className="close-icon">
          <IconContext.Provider
            value={{ className: "close-icon", size: "1rem" }}
          >
            <GrFormClose />
          </IconContext.Provider>
        </div> */}
      </div>
    </div>
  );
};

export default Notification;
