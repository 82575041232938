import logo from "../images/logo_new.png";
import profileDetails from "../images/mobile-menu.png";
import "./styles/header-mobile.css";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { IconContext } from "react-icons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import localAvatarImages from "../utils/avatars-images-array";
import { useTranslation } from "react-i18next";
const HeaderMobile = ({ location }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const childProfilesRedux = useSelector(
    (state) => state.mainSlice.currentChildProfile
  );
  const [childProfile, setChildProfile] = useState({});
  const [profileAvatar, setProfileAvatar] = useState("");
  useEffect(() => {
    if (childProfilesRedux?.id) {
      setChildProfile(childProfilesRedux);
    }
  }, [childProfilesRedux]);

  useEffect(() => {
    if (childProfile?.id) {
      if (childProfile?.avatar?.length > 10) {
        // avatar image is base64
        setProfileAvatar(childProfile.avatar);
      } else {
        // avatar image is local image assign the image
        localAvatarImages.forEach((image) => {
          if (
            image.name.toLocaleLowerCase() ===
            childProfile.avatar.toLocaleLowerCase()
          ) {
            setProfileAvatar(image.img);
          }
        });
      }
    }
  }, [childProfile]);

  return (
    <>
      <header
        className="header"
        style={{
          padding:
            location == "home" || location === "recipes" ? " 0 20px" : "0 10px",
        }}
      >
        {location === "home" ? (
          <div
            className="profile-image-wrapper"
            onClick={() => navigate("/change-profile-mobile")}
          >
            <div className="img-circle">
              <img src={profileAvatar} alt="" />
            </div>
            <div className="profile-name">{childProfile?.first_name}</div>
          </div>
        ) : (
          <div
            className="back-btn-mobile"
            onClick={
              location === "change-avatar"
                ? () => navigate("/edit-profile")
                : () => navigate("/")
            }
          >
            <div className="span">
              <IconContext.Provider value={{ className: "arrow-icon" }}>
                <BiChevronLeft style={{ fontSize: "20px" }} />
              </IconContext.Provider>
            </div>
            <span>{t("back")}</span>
          </div>
        )}

        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <div className="profile-edit" onClick={() => navigate("/edit-profile")}>
          <div className="img-circle edit">
            <img src={profileDetails} alt="" className="profile-edit-image" />
          </div>
          {/* <div className="text">test</div> */}
        </div>
      </header>
    </>
  );
};

export default HeaderMobile;
