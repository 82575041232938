import axiosBase from "../axios/axios";
const householdService = {
  getHouseholdInformation: async () => {
    try {
      const { data } = await axiosBase.get(
        `/household/getHouseholdInformation`
      );
      return data;
    } catch (err) {
      console.log(err, "error in getHouseholdId");
    }
  },
  updateLanguage: async (language, profileId) => {
    try {
      const { data } = await axiosBase.post(`/household/updateLanguage`, {
        language,
        profileId,
      });
      return data;
    } catch (err) {
      console.log(err, "error in updateLanguage");
    }
  },
  deleteHousehold: async (email) => {
    try {
      const { data } = await axiosBase.post(`/household/deleteHousehold`, {
        email,
      });
      return data;
    } catch (err) {
      console.log(err, "error in deleteHousehold");
    }
  },
};

export default householdService;
