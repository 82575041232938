const data = {
  foods: [
    { value: 1, label: "Muffins" },
    { value: 2, label: "Tortilla" },
    { value: 3, label: "Pizza" },
    { value: 4, label: "Sandwich" }, //
    { value: 5, label: "Pita" },
    { value: 6, label: "Buns" }, //
    { value: 7, label: "Eggs" },
    { value: 8, label: "Omelet" },
    { value: 9, label: "Toast bread" },
    { value: 10, label: "Vegetables" },
    { value: 11, label: "Rice" },
    { value: 12, label: "Pretzel" },
    { value: 13, label: "Pancakes" },
    { value: 14, label: "Belgian waffle" },
    { value: 15, label: "Pasta" },
    { value: 16, label: "Spaghetti" },
    { value: 17, label: "Noodles" },
    { value: 18, label: "Fritters" },
    { value: 19, label: "Meatballs" },
    { value: 20, label: "Fruits" },
    { value: 21, label: "Surprise box" },
    { value: 47, label: "Beef" },
    { value: 22, label: "Bread" },
    { value: 25, label: "Burritos" },
    { value: 40, label: "Cereals" },
    { value: 42, label: "Cheeses" }, //
    { value: 46, label: "Chicken" },
    { value: 29, label: "Dumplings" },
    { value: 31, label: "Empanadas" },
    { value: 30, label: "Falafel" },
    { value: 48, label: "Finger foods" },
    { value: 33, label: "Fish sticks" },
    { value: 39, label: "French toast" },
    { value: 37, label: "Frittata" },
    { value: 41, label: "Granola" },
    { value: 50, label: "Hamburger" },
    { value: 35, label: "Kebabs" },
    { value: 43, label: "Leftovers" },
    { value: 32, label: "Nuggets" },
    { value: 36, label: "Quiche" },
    { value: 34, label: "Roll-ups" },
    { value: 24, label: "Salads" },
    { value: 44, label: "Skewers" },
    { value: 49, label: "Snacks" },
    { value: 28, label: "Spring rolls" },
    { value: 27, label: "Sushi" },
    { value: 26, label: "Taco" }, //
    { value: 45, label: "Tofu dishes" },
    { value: 38, label: "Waffles" },
    { value: 23, label: "Wraps" },
    ///// new
    { value: 51, label: "Hamantaschen" },
    { value: 52, label: "Ban" },
    { value: 53, label: "Borax" },
    { value: 54, label: "Dishes for the holidays" },
    { value: 55, label: "Hummus" },
    { value: 56, label: "Happy Birthday" },
    { value: 57, label: "Everything Else" },
    { value: 58, label: "lasagna" },
    { value: 59, label: "Pastries" },
    { value: 60, label: "Mini pancakes" },
    { value: 61, label: "Hot dog" },
    { value: 62, label: "Donuts" },
    { value: 63, label: "Cigars" },
    { value: 64, label: "Cookies" },
    { value: 65, label: "Pies" },
    { value: 66, label: "Fries" },
    { value: 67, label: "Crackers" },
    { value: 68, label: "Vegetable skewers" },
    { value: 69, label: "Chicken skewers" },
    { value: 70, label: "Schnitzel" },
    { value: 71, label: "A Rustle" },
    { value: 72, label: "Stews" },
    { value: 73, label: "Stuffed" },
  ],
  hubbies: [
    { value: 1, label: "Basketball" },
    { value: 2, label: "Soccer" },
    { value: 3, label: "Dancing" },
    { value: 4, label: "Baseball" },
    { value: 5, label: "Video games" },
    { value: 7, label: "Swimming" },
    { value: 8, label: "Riding bikes" },
    { value: 9, label: "Skateboarding" },
    { value: 10, label: "Roller skating" },
    { value: 11, label: "Reading books" },
    { value: 12, label: "Drawing" },
    { value: 13, label: "Musical instruments" },
    { value: 14, label: "Singing/theater/acting" },
    { value: 15, label: "Legos & blocks" },
    { value: 16, label: "Board games" },
    { value: 17, label: "Collecting things" },
    { value: 18, label: "Photography" },
    { value: 19, label: "Gardening/planting" },
    { value: 20, label: "Cooking/baking" },
    { value: 21, label: "Camping/hiking" },
    { value: 22, label: "Fishing" },
  ],
  diets: [
    { value: 6, label: "Eats everything" },
    { value: 1, label: "Vegetarian" },
    { value: 2, label: "Vegan" },
    { value: 3, label: "Pescatarian" },
    // { value: 4, label: "Gluten free" },
    // { value: 5, label: "Lactose free" },
  ],
  allergies: [
    { value: 8, label: "None" },
    { value: 1, label: "Eggs" },
    { value: 2, label: "Fish" },
    { value: 3, label: "Nuts / Peanuts" },
    { value: 4, label: "Gluten" },
    { value: 5, label: "Soy" },
    { value: 6, label: "Sesame" },
    { value: 7, label: "Lactose" },
  ],
  environment: [
    { value: 1, label: "School" },
    { value: 2, label: "Kindergarten" },
    { value: 3, label: "Other" },
  ],
};
export default data;
