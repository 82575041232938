import "./styles/footer-mobile.css";
import strawberries from "../images/strawberries.png";
import videos from "../images/mobile-footer/video.png";
import clean from "../images/mobile-footer/clean.png";
import { setCleanBoxPopupOpenRedux } from "../redux/reducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SlStar } from "react-icons/sl";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { FaHeadphonesAlt } from "react-icons/fa";
import { IoGameControllerOutline } from "react-icons/io5";

import blog from "../images/mobile-footer/blog.png";
import premiumStar from "../images/mobile-footer/YOOPI_Prime_Logo_White.png";
import { FaUsers } from "react-icons/fa6";

import { IconContext } from "react-icons/lib";
import { useEffect } from "react";
const MobileFooter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.dir();
  const handleAboutUs = () => {
    if (i18n.language === "he") {
      window.open("https://getyoopi.com/about/", "_blank");
    } else {
      window.open("https://getyoopi.com/about-us-en/", "_blank");
    }
  };
  const handlePrivacy = () => {
    if (i18n.language === "he") {
      window.open("https://getyoopi.com/privacy-policy-heb/", "_blank");
    } else {
      window.open("https://getyoopi.com/privacy-policy-eng/", "_blank");
    }
  };

  return (
    <>
      <div className="mobile-footer-container">
        <div className="strawberries">
          <img src={strawberries} alt="" />
        </div>
        <div className="main-mobile-footer">
          <div className="mobile-footer-title">{t("extras")} </div>
          <div className="separator"></div>

          <div className="mobile-footer-buttons">
            <div className="videos" onClick={() => navigate("/videos")}>
              <div className="footer-circle">
                <img src={videos} alt="" className="videos" />
              </div>
              <div className="footer-link">{t("videos")}</div>
            </div>
            <div
              className="clean"
              onClick={() => dispatch(setCleanBoxPopupOpenRedux(true))}
            >
              <div className="footer-circle">
                <img src={clean} alt="" className="clean" />
              </div>
              <div className="footer-link clean">{t("clean_box")}</div>
            </div>
            <div
              className="premium"
              onClick={() =>
                window.open("https://getyoopi.com/prime-isr/", "_blank")
              }
            >
              <div className="footer-circle">
                <img src={premiumStar} alt="" />
              </div>
              <div className="footer-link ">{t("YOOPI Prime")}</div>
            </div>
          </div>
        </div>
        <div className="footer-credit">
          <div className="icons">
            <div
              className="kids-zone"
              onClick={() =>
                (window.location.href = "https://reg.getyoopi.com/kids-zone")
              }
            >
              <IconContext.Provider value={{ color: "#f8c13b" }}>
                <IoGameControllerOutline
                  className="footer-icon kids-zone"
                  style={{ width: "45px", height: "45px" }}
                />
              </IconContext.Provider>
            </div>
            <div
              className="blog"
              onClick={() =>
                window.open("https://getyoopi.com/blog-post/", "_blank")
              }
            >
              <img src={blog} alt="" />
            </div>
            <div className="privacy" onClick={handlePrivacy}>
              <IconContext.Provider value={{ size: "35px", color: "#B89CD1" }}>
                <MdOutlinePrivacyTip className="footer-icon" />
              </IconContext.Provider>
            </div>
            <div
              className="contact-us"
              onClick={() =>
                window.open("https://getyoopi.com/contact-us/", "_blank")
              }
            >
              <IconContext.Provider value={{ size: "35px", color: "#B89CD1" }}>
                <FaHeadphonesAlt className="footer-icon" />
              </IconContext.Provider>
            </div>

            <div className="about-us" onClick={handleAboutUs}>
              <IconContext.Provider value={{ size: "35px", color: "#B89CD1" }}>
                <FaUsers className="footer-icon" />
              </IconContext.Provider>
            </div>
          </div>
{/*           <div className="credit">{t("built_By_hippocampus")}</div> */}
        </div>
      </div>
    </>
  );
};

export default MobileFooter;
