import axiosBase from "../axios/axios";
const recipeService = {
  getRecipes: async (profileId) => {
    try {
      const { data } = await axiosBase.get(`/recipe/getRecipes/${profileId}`);
      return data;
    } catch (err) {
      console.log(err, "error in getRecipes");
    }
  },
  getRatings: async (profileId, recipeId) => {
    try {
      const { data } = await axiosBase.get(
        `/recipe/getRatings/${profileId}/${recipeId}`
      );
      return data;
    } catch (err) {
      console.log(err, "error in getRatings");
    }
  },
  rateRecipe: async (profileId, recipeId, rating) => {
    try {
      const { data } = await axiosBase.post(`/recipe/rateRecipe`, {
        profileId,
        recipeId,
        rating,
      });

      return data;
    } catch (err) {
      console.log(err, "error in rateRecipe");
    }
  },
  addRecipeToWeeklyMenu: async (profileId, recipeId, date) => {
    try {
      const { data } = await axiosBase.post(`/recipe/addToWeeklyMenu`, {
        profileId,
        recipeId,
        date,
      });
      return data;
    } catch (err) {
      console.log(err, "error in addRecipeToWeeklyMenu");
    }
  },
  getWeeklyRecipes: async (profileId) => {
    try {
      const { data } = await axiosBase.get(
        `/recipe/getWeeklyRecipes/${profileId}`
      );
      return data;
    } catch (err) {
      console.log(err, "error in getWeeklyRecipes");
    }
  },
  createRecipe: async (formData) => {
    try {
      const { data } = await axiosBase.post(`/recipe/createRecipe`, formData);
      return data;
    } catch (err) {
      console.log(err, "error in createGeneralRecipe");
    }
  },
  searchRecipes: async (searchProperties) => {
    try {
      const { data } = await axiosBase.post(
        `/recipe/searchRecipes`,
        searchProperties
      );
      return data;
    } catch (err) {
      console.log(err, "error in searchRecipes");
    }
  },
  deleteRecipe: async (recipeId) => {
    try {
      const { data } = await axiosBase.delete(
        `/recipe/deleteRecipe/${recipeId}`
      );
      return data;
    } catch (err) {
      console.log(err, "error in deleteRecipe");
    }
  },
  getRecipeById: async (recipeId) => {
    try {
      const { data } = await axiosBase.get(`/recipe/getRecipeById/${recipeId}`);
      return data;
    } catch (err) {
      console.log(err, "error in getRecipeById");
    }
  },
  resetRating: async (profileId, recipeId) => {
    try {
      const { data } = await axiosBase.delete(
        `/recipe/resetRating/${profileId}/${recipeId}`
      );

      if (data.message === "success") {
        const { data } = await axiosBase.get(
          `/recipe/getRecipeById/${recipeId}`
        );
        return data;
      } else {
        console.log(data, "rating error");
      }
    } catch (err) {
      console.log(err, "error in resetRating");
    }
  },
  handleFavorites: async (profileId, recipeId, addToFavorites) => {
    try {
      const { data } = await axiosBase.post(`/recipe/handleFavorites`, {
        profileId,
        recipeId,
        addToFavorites,
      });
      return data;
    } catch (err) {
      console.log(err, "error in handleFavorites");
    }
  },
  getFavorites: async (profileId) => {
    try {
      const { data } = await axiosBase.get(`/recipe/getFavorites/${profileId}`);
      return data;
    } catch (err) {
      console.log(err, "error in getFavorites");
    }
  },
  getMyCreatedRecipes: async (profileId) => {
    try {
      const { data } = await axiosBase.get(
        `/recipe/getMyCreatedRecipes/${profileId}`
      );
      return data;
    } catch (err) {
      console.log(err, "error in getMyCreatedRecipes");
    }
  },
  getRecipeMembers: async (recipeId) => {
    try {
      const { data } = await axiosBase.get(
        `/recipe/getRecipeMembers/${recipeId}`
      );
      return data;
    } catch (err) {
      console.log(err, "error in getRecipeMembers");
    }
  },
  editRecipe: async (formData) => {
    try {
      const { data } = await axiosBase.post(`/recipe/editRecipe`, formData);
      return data;
    } catch (err) {
      console.log(err, "error in editRecipeWithSameImage");
    }
  },
  saveTemplate: async (profileId, template, weekPosition) => {
    try {
      const { data } = await axiosBase.post(`/recipe/saveTemplate`, {
        profileId,
        template,
        weekPosition,
      });
      return data;
    } catch (err) {
      console.log(err, "error in saveTemplate");
    }
  },
  getTemplate: async (profileId, weekOnDisplay) => {
    try {
      const { data } = await axiosBase.post(`/recipe/getTemplate`, {
        profileId,
        weekOnDisplay,
      });
      if (data.message === "success") {
        const { data } = await axiosBase.get(
          `/recipe/getWeeklyRecipes/${profileId}`
        );
        return data;
      } else if (data.message === "No template found") {
        return data;
      } else {
        console.log(data, "error in getTemplate");
      }
    } catch (err) {
      console.log(err, "error in useTemplate");
    }
  },
  createIngredient: async (ingredient, ingredientInHebrew) => {
    try {
      const { data } = await axiosBase.post(`/recipe/createIngredient`, {
        ingredient,
        ingredientInHebrew,
      });
      return data;
    } catch (err) {
      console.log(err, "error in createIngredient");
    }
  },
  getIngredients: async () => {
    try {
      const { data } = await axiosBase.get(`/recipe/getIngredients`);
      return data;
    } catch (err) {
      console.log(err, "error in getIngredients");
    }
  },
  deleteIngredient: async (ingredientId) => {
    try {
      const { data } = await axiosBase.delete(
        `/recipe/deleteIngredient/${ingredientId}`
      );
      return data;
    } catch (err) {
      console.log(err, "error in deleteIngredient");
    }
  },
  removeRecipeFromCalendar: async (profileId, date) => {
    try {
      const { data } = await axiosBase.post(
        `/recipe/removeRecipeFromCalendar`,
        { profileId, date }
      );
      return data;
    } catch (err) {
      console.log(err, "error in removeRecipeFromCalendar");
    }
  },
};

export default recipeService;
