import axiosBase from "../axios/axios";

const testimonialService = {
  createTestimonial: async (testimonial) => {
    try {
      const { data } = await axiosBase.post(
        `/testimonial/createTestimonial`,
        testimonial
      );
      return data;
    } catch (err) {
      console.log(err, "error in createTestimonial");
    }
  },
  getTestimonials: async (profileId) => {
    try {
      const { data } = await axiosBase.get(
        `/testimonial/getTestimonials/${profileId}`
      );
      return data;
    } catch (err) {
      console.log(err, "error in getTestimonials");
    }
  },
  deleteTestimonial: async (item) => {
    try {
      const { data } = await axiosBase.post(
        `/testimonial/deleteTestimonial`,
        item
      );
      return data;
    } catch (err) {
      console.log(err, "error in deleteTestimonial");
    }
  },
  editTestimonial: async (item) => {
    try {
      const { data } = await axiosBase.post(
        `/testimonial/editTestimonial`,
        item
      );
      return data;
    } catch (err) {
      console.log(err, "error in editTestimonial");
    }
  },
};

export default testimonialService;
