import Stories from "react-insta-stories";
import "./styles/instagram.css";
import { FiInstagram } from "react-icons/fi";
import { IconContext } from "react-icons";
import { useEffect, useState } from "react";
import instagramStories from "../utils/instagram-stories";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
const Instagram = () => {
  const [seeMoreClicked, setSeeMoreClicked] = useState(false);
  const lessThan400Px = useMediaQuery({ maxWidth: 400 });
  const mobile = useMediaQuery({ maxWidth: 800 });
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.dir(i18n.language);
  console.log(languageDirection, "1");
  useEffect(() => {}, [instagramStories]);
  return (
    <>
      <div
        className="instagram-container"
        style={{
          marginBottom: mobile ? "0" : "60px",
          marginTop: mobile ? "50px" : "0",
        }}
      >
        <div
          className={mobile ? "instagram-title" : "instagram-title desktop"}
          style={{
            flexDirection: languageDirection === "rtl" ? "row-reverse" : "row",
            padding: languageDirection === "rtl" ? "0 20px 0 0" : "0 0 0 20px",
            fontSize: mobile ? "1rem" : "1.2rem",
            margin: mobile ? "0" : "8px 0",
          }}
        >
          <span className="insta-icon">
            <IconContext.Provider value={{ className: "insta-icon" }}>
              <FiInstagram
                className={mobile ? "instagram-icon" : "instagram-icon desktop"}
              />
            </IconContext.Provider>
          </span>
          <span> {t("Inspiration_of_the_week")}</span>
        </div>
        <div
          className={mobile ? "instagram-images" : "instagram-images-desktop"}
        >
          <iframe
            src="https://widget.tagembed.com/147486?view"
            style={{
              width: mobile ? "100%" : "90%",
              height: mobile ? "500px" : "400px",
              // padding: mobile ? "0 20x" : "0 0",
              overflow: "hidden",
            }}
            allowtransparency="true"
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default Instagram;
