import HeaderDesktop from "./header-desktop";
import HeaderMobile from "./header-mobile";
import FooterDesktop from "./footer-desktop";
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import videoPlaceholder from "../images/videos/placeholder.png";
import sliceTextToMaxLength from "../utils/sliceTextToMaxLength";
import {
  MdOutlineKeyboardArrowUp,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";
import { TiEdit } from "react-icons/ti";
import { MdOutlineDeleteForever } from "react-icons/md";
import videoService from "../services/videoService";
import { IconContext } from "react-icons";
import "./styles/videos.css";
import imageBaseUrl from "../config";
import {
  setDeletionPopupContext,
  setDeletionPopupOpenRedux,
  setCurrentVideo,
  setItemForDeletion,
} from "../redux/reducer";
import { useTranslation } from "react-i18next";
const Videos = () => {
  const mobile = useMediaQuery({ maxWidth: 800 });
  const [modalOpen, setModalOpen] = useState(false);
  const [allVideos, setAllVideos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [filter, setFilter] = useState("all"); // ["all", "tips", "unboxing", "cleaning", "products"
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.dir();
  const currentProfileRedux = useSelector(
    (state) => state.mainSlice.currentChildProfile
  );
  const roleRedux = useSelector((state) => state.mainSlice.role);
  useEffect(() => {
    const fetchVideos = async () => {
      const dbVideos = await videoService.getVideos(currentProfileRedux.id);
      if (dbVideos?.message === "success") {
        setAllVideos(dbVideos.videos.length ? dbVideos.videos : []);
        setVideos(dbVideos.videos.length ? dbVideos.videos : []);
      } else {
        setAllVideos([]);
        setVideos([]);
        setError(t("No videos found"));
      }
    };
    if (!currentProfileRedux.id) {
      navigate("/");
    } else {
      fetchVideos();
    }
  }, []);
  const handleVideoEdit = (video) => {
    const item = { ...video, context: "video" };
    dispatch(setCurrentVideo(item));
    navigate("/edit-video");
  };
  const handleDelete = (video) => {
    dispatch(setDeletionPopupContext("video"));
    dispatch(setItemForDeletion(video));
    dispatch(setDeletionPopupOpenRedux(true));
  };
  const handleFilter = async (elementId) => {
    setError("");
    setFilter(elementId);
    if (elementId === "all") {
      setVideos(allVideos);
      return;
    } else {
      // extract videos with category === filter
      const filtered = allVideos.filter(
        (video) => video.category === elementId
      );
      if (filtered.length) {
        setVideos(filtered);
      } else {
        // error message
        setVideos([]);
        setError(`${t("No videos found in this category")}`);
      }
    }
  };
  const sendToVideo = (e) => {
    e.preventDefault();
    window.location.href = e.target.getAttribute("href");
  };
  const openVideo = (link) => {
    const dummy = document.createElement("a");
    dummy.setAttribute("href", link);
    dummy.setAttribute("target", "_blank");
    dummy.style.display = "none";
    document.body.appendChild(dummy);
    dummy.addEventListener("click", sendToVideo);
    dummy.click();
    document.body.removeChild(dummy);
  };
  return (
    <>
      {mobile ? (
        <>
          <HeaderMobile />
          <div className="videos-wrapper">
            <div
              className="filter-wrapper"
              style={{
                minHeight: modalOpen ? "none" : "110px",
              }}
            >
              <div
                className="filter-modal"
                style={{
                  flexDirection:
                    languageDirection === "rtl" ? "row-reverse" : "row",
                }}
              >
                <h4>{t("Filter Video")}</h4>
                <div
                  className="trigger-modal"
                  onClick={() => setModalOpen(!modalOpen)}
                >
                  <IconContext.Provider
                    value={{ size: "30px", color: "#3c4964" }}
                  >
                    {modalOpen ? (
                      <MdOutlineKeyboardArrowUp className="arrow-icon" />
                    ) : (
                      <MdOutlineKeyboardArrowDown className="arrow-icon" />
                    )}
                  </IconContext.Provider>
                </div>
              </div>
              {modalOpen && (
                <div className="filter-options">
                  <div
                    style={{
                      direction: languageDirection,
                      padding:
                        languageDirection === "rtl" ? "0 22% 0 0" : "0 0 0 22%",
                    }}
                  >
                    <input
                      type="radio"
                      name="filter"
                      id="tips"
                      checked={filter === "tips"}
                      onChange={(e) => handleFilter(e.target.id)}
                    />
                    <label htmlFor="tips">{t("Tips")} </label>
                  </div>
                  <div
                    style={{
                      direction: languageDirection,
                      padding:
                        languageDirection === "rtl" ? "0 22% 0 0" : "0 0 0 22%",
                    }}
                  >
                    <input
                      type="radio"
                      name="filter"
                      id="unboxing"
                      checked={filter === "unboxing"}
                      onChange={(e) => handleFilter(e.target.id)}
                    />
                    <label htmlFor="unboxing">{t("Unboxing")}</label>
                  </div>
                  <div
                    style={{
                      direction: languageDirection,
                      padding:
                        languageDirection === "rtl" ? "0 22% 0 0" : "0 0 0 22%",
                    }}
                  >
                    <input
                      type="radio"
                      name="filter"
                      id="cleaning"
                      checked={filter === "cleaning"}
                      onChange={(e) => handleFilter(e.target.id)}
                    />
                    <label htmlFor="cleaning">{t("cleaning")}</label>
                  </div>
                  <div
                    style={{
                      direction: languageDirection,
                      padding:
                        languageDirection === "rtl" ? "0 22% 0 0" : "0 0 0 22%",
                    }}
                  >
                    <input
                      type="radio"
                      name="filter"
                      id="products"
                      checked={filter === "products"}
                      onChange={(e) => handleFilter(e.target.id)}
                    />
                    <label htmlFor="products">{t("products")}</label>
                  </div>
                  <div
                    style={{
                      direction: languageDirection,
                      padding:
                        languageDirection === "rtl" ? "0 22% 0 0" : "0 0 0 22%",
                    }}
                  >
                    <input
                      type="radio"
                      name="filter"
                      id="all"
                      checked={filter === "all"}
                      onChange={(e) => handleFilter(e.target.id)}
                    />
                    <label htmlFor="all">{t("All")}</label>
                  </div>
                </div>
              )}
            </div>

            {error && <div className="error">{error}</div>}

            <div className="videos">
              {videos.length > 0 && (
                <>
                  {videos.map((video) => {
                    return (
                      <div
                        className="video-card"
                        style={{ width: "100%" }}
                        key={video.id}
                      >
                        <div
                          className="video-image-wrapper"
                          onClick={() => openVideo(video.link)}
                        >
                          <div
                            style={{
                              backgroundImage: `url(${imageBaseUrl}video-image/${video.image})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              width: "100%",
                              height: "200px",
                            }}
                          ></div>
                        </div>

                        <div
                          className="video-title"
                          style={{
                            direction: languageDirection,
                            padding:
                              languageDirection === "rtl"
                                ? "0 10px 0 0 "
                                : " 0 0 0 10px",
                          }}
                          onClick={() => openVideo(video.link)}
                        >
                          {video.title}
                        </div>
                        <div
                          className="video-category"
                          style={{
                            direction: languageDirection,
                            padding:
                              languageDirection === "rtl"
                                ? "0 10px 0 0 "
                                : " 0 0 0 10px",
                          }}
                        >
                          {t(`${video.category}`)}
                        </div>
                        <div
                          className="video-description"
                          style={{
                            direction: languageDirection,
                            padding:
                              languageDirection === "rtl"
                                ? "0 10px 0 0 "
                                : " 0 0 0 10px",
                          }}
                        >
                          {sliceTextToMaxLength(video.description, 80)}
                        </div>
                        {roleRedux === 2 && (
                          <>
                            <div className="admin-panel">
                              <span onClick={() => handleVideoEdit(video)}>
                                <IconContext.Provider
                                  value={{
                                    color: "#000000",
                                    size: "1.5rem",
                                    className: "global-class-name",
                                  }}
                                >
                                  <TiEdit />
                                </IconContext.Provider>
                              </span>
                              <span onClick={() => handleDelete(video)}>
                                <IconContext.Provider
                                  value={{
                                    color: "#000000",
                                    size: "1.5rem",
                                    className: "global-class-name",
                                  }}
                                >
                                  <MdOutlineDeleteForever />
                                </IconContext.Provider>
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <HeaderDesktop />
          <div className="videos-wrapper">
            <div
              className="title desktop"
              style={{ direction: languageDirection }}
            >
              <h1>{t("Video library:")}</h1>
            </div>

            <div
              className="filters-wrapper desktop"
              style={{ direction: languageDirection }}
            >
              <h4>{t("Filter Video:")}</h4>
              <div style={{ direction: languageDirection }}>
                <input
                  type="radio"
                  name="filter"
                  id="tips"
                  checked={filter === "tips"}
                  onChange={(e) => handleFilter(e.target.id)}
                />
                <label htmlFor="tips">{t("Tips")}</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="filter"
                  id="unboxing"
                  checked={filter === "unboxing"}
                  onChange={(e) => handleFilter(e.target.id)}
                />
                <label htmlFor="unboxing">{t("Unboxing")}</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="filter"
                  id="cleaning"
                  onChange={(e) => handleFilter(e.target.id)}
                />
                <label htmlFor="cleaning">{t("cleaning")}</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="filter"
                  id="products"
                  checked={filter === "products"}
                  onChange={(e) => handleFilter(e.target.id)}
                />
                <label htmlFor="products">{t("products")}</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="filter"
                  id="all"
                  checked={filter === "all"}
                  onChange={(e) => handleFilter(e.target.id)}
                />
                <label htmlFor="all">{t("All")}</label>
              </div>
            </div>
            {error && <div className="error-desktop">{error}</div>}
            <div className="videos-desktop">
              {videos.length > 0 && (
                <>
                  {videos.map((video) => {
                    return (
                      <div
                        className="video-card"
                        style={{ width: "23vw" }}
                        key={video.id}
                      >
                        <div
                          className="video-image-wrapper desktop"
                          onClick={() => openVideo(video.link)}
                        >
                          <div
                            style={{
                              backgroundImage: `url(${imageBaseUrl}video-image/${video.image})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              width: "100%",
                              height: "200px",
                            }}
                          ></div>
                        </div>

                        <div
                          className="video-title"
                          style={{
                            direction: languageDirection,
                            padding:
                              languageDirection === "rtl"
                                ? "0 10px 0 0 "
                                : " 0 0 0 10px",
                          }}
                          onClick={() => openVideo(video.link)}
                        >
                          {video.title}
                        </div>
                        <div
                          className="video-category"
                          style={{
                            direction: languageDirection,
                            padding:
                              languageDirection === "rtl"
                                ? "0 10px 0 0 "
                                : " 0 0 0 10px",
                          }}
                        >
                          {t(`${video.category}`)}
                        </div>
                        <div
                          className="video-description"
                          style={{
                            direction: languageDirection,
                            padding:
                              languageDirection === "rtl"
                                ? "0 10px 0 0 "
                                : " 0 0 0 10px",
                          }}
                        >
                          {sliceTextToMaxLength(video.description, 50)}
                        </div>
                        {roleRedux === 2 && (
                          <>
                            <div className="admin-panel">
                              <span onClick={() => handleVideoEdit(video)}>
                                <IconContext.Provider
                                  value={{
                                    color: "#000000",
                                    size: "1.5rem",
                                    className: "global-class-name",
                                  }}
                                >
                                  <TiEdit />
                                </IconContext.Provider>
                              </span>
                              <span onClick={() => handleDelete(video)}>
                                <IconContext.Provider
                                  value={{
                                    color: "#000000",
                                    size: "1.5rem",
                                    className: "global-class-name",
                                  }}
                                >
                                  <MdOutlineDeleteForever />
                                </IconContext.Provider>
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
          <FooterDesktop />
        </>
      )}
    </>
  );
};

export default Videos;
