import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
Chart.register(ArcElement);

const HealthRateChart = ({ value, chartColor }) => {
  const data = {
    labels: ["Value", "Remaining"],
    datasets: [
      {
        data: [value, 10 - value],
        backgroundColor: [chartColor, "#E7E7E7"],
      },
    ],
  };

  const options = {
    cutoutPercentage: 70,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  };

  return (
    <div style={{ position: "relative", width: "60px", height: "60px" }}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <span style={{ fontSize: "15px", color: "#000" }}>{value}</span>
      </div>
      <Doughnut data={data} options={options} width={200} height={200} />
    </div>
  );
};

export default HealthRateChart;
