import "./styles/change-avatar.css";
import { useEffect, useState, useRef, useCallback } from "react";
import { IconContext } from "react-icons/lib";
import { BsCameraFill } from "react-icons/bs";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import localAvatarImages from "../utils/avatars-images-array";
import Webcam from "react-webcam";
import { useNavigate } from "react-router-dom";
import HeaderMobile from "./header-mobile";
import HeaderDesktop from "./header-desktop";
import FooterDesktop from "./footer-desktop";
import profileService from "../services/profileService";
import {
  setCurrentChildProfileRedux,
  setChildProfilesRedux,
} from "../redux/reducer";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const ChangeAvatar = () => {
  const mobile = useMediaQuery({ query: "(max-width:800px)" });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const [selectedAvatar, setSelectedAvatar] = useState("");
  const [selectedAvatarName, setSelectedAvatarName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  //   const [avatars, setAvatars] = useState([]);
  const [listOfAvatars, setListOfAvatars] = useState([]);
  const webcamRef = useRef(null);
  const currentChildProfileRedux = useSelector(
    (state) => state.mainSlice.currentChildProfile
  );
  const allProfilesRedux = useSelector(
    (state) => state.mainSlice.childProfiles
  );
  useEffect(() => {
    const isBase64 = (avatarName) => {
      if (avatarName === "Captured") {
        return "base64";
      } else {
        return "localImagePath";
      }

      // if (avatarName?.length < 11) {
      // }
      //  return "unknown";
    };

    if (currentChildProfileRedux?.id) {
      const avatarsData = [];
      localAvatarImages.forEach((avatar) => {
        avatarsData.push({
          id: avatar.id,
          image: avatar.img,
          name: avatar.name,
        });
      });
      //   setAvatars(avatarsData);
      setListOfAvatars(avatarsData);

      const checkIfImageIsBase64OrPathToLocalImage = isBase64(
        currentChildProfileRedux.avatar
      );

      if (checkIfImageIsBase64OrPathToLocalImage === "base64") {
        // avatar is base64
        setSelectedAvatar(currentChildProfileRedux.avatar);
        setSelectedAvatarName("Captured");
      } else if (checkIfImageIsBase64OrPathToLocalImage === "localImagePath") {
        // avatar is name from local images
        localAvatarImages.forEach((avatar) => {
          if (
            currentChildProfileRedux.avatar.toLocaleLowerCase() ===
            avatar.name.toLocaleLowerCase()
          ) {
            setSelectedAvatarName(avatar.name);
            setSelectedAvatar(avatar.img);
            return;
          }
        });
      }
    } else {
      navigate("/");
    }
  }, []);

  const capture = useCallback(
    (e) => {
      e.stopPropagation(); // Stop event propagation to ensure outer div's click event doesn't get triggered
      const imageSrc = webcamRef.current.getScreenshot();
      setSelectedAvatarName("Captured");
      setSelectedAvatar(imageSrc);
      setIsOpen(false);
    },
    [webcamRef]
  );

  const handleAvatarChange = (name) => {
    setIsOpen(false);
    listOfAvatars.map((avatar) => {
      if (avatar.name.toLocaleLowerCase() === name.toLocaleLowerCase()) {
        setSelectedAvatarName(avatar.name);
        setSelectedAvatar(avatar.image);
        return;
      }
    });
  };
  const handleSaveAvatar = async () => {
    let imageChanged = false;
    let updatedProfile = {};
    if (selectedAvatarName === "Captured") {
      // if Captured image is base64
      // check if image changed if not don't update
      if (
        selectedAvatar.toLocaleLowerCase() !==
        currentChildProfileRedux?.avatar.toLocaleLowerCase()
      ) {
        // base64 image didn't change
        imageChanged = true;
        updatedProfile = {
          ...currentChildProfileRedux,
          avatar: selectedAvatar,
          avatarName: selectedAvatarName,
        };
      }
    } else {
      // image is not base64
      // check if image changed if not don't update
      if (
        selectedAvatarName.toLocaleLowerCase() ===
        currentChildProfileRedux?.avatar.toLocaleLowerCase()
      ) {
        // local image didn't change
        // console.log("image not changed");
        return;
      } else {
        // local image changed
        imageChanged = true;
        updatedProfile = {
          ...currentChildProfileRedux,
          avatar: selectedAvatar,
          avatarName: selectedAvatarName,
        };
      }
    }

    if (!imageChanged) return;

    try {
      const response = await profileService.changeAvatar(updatedProfile);
      if (response.message === "success") {
        const profile = response.profile;

        const updatedProfiles = allProfilesRedux.map((profileRedux) => {
          if (profileRedux.id === profile.id) {
            return profile; // Replace with the response.profile
          } else {
            return profileRedux; // Keep the original profile
          }
        });
        toast.success(`${t("Avatar updated")}`);
        dispatch(setCurrentChildProfileRedux(profile));
        dispatch(setChildProfilesRedux(updatedProfiles));
        navigate("/edit-profile");
      }
    } catch (err) {
      console.log(err, "update avatar error");
      toast.error(`${t("error updating avatar")}`);
    }
  };

  return (
    <>
      <div className="change-avatar-container">
        {mobile ? <HeaderMobile location="change-avatar" /> : <HeaderDesktop />}
        <div className="chosen-avatar">
          {/*  if isOpen=false  that means the camera is off we display avatar */}

          {!isOpen ? (
            <>
              <div className="avatar-wrapper">
                {selectedAvatar && (
                  <img
                    src={selectedAvatar}
                    alt="Captured"
                    className={
                      selectedAvatarName === "Captured"
                        ? "captured"
                        : "system-avatar"
                    }
                  />
                )}
              </div>

              <div
                className="take-photo-wrapper"
                onClick={() => setIsOpen(true)}
              >
                <div className="take-photo-icon">
                  <IconContext.Provider value={{ className: "upload-icon" }}>
                    <BsCameraFill style={{ fontSize: "20px" }} />
                  </IconContext.Provider>
                </div>
                <div className="take-photo-para">{t("take_photo")}</div>
              </div>
            </>
          ) : (
            <>
              <div className="camera-container">
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  imageSmoothing={true}
                  screenshotFormat="image/jpeg"
                  videoConstraints={{
                    facingMode: "user",
                    width: "300px!important",
                    height: "300px!important",
                  }}
                  className="webcam-video"
                />
                <div
                  className="take-photo-wrapper capture"
                  onClick={(e) => capture(e)}
                >
                  <div className="take-photo-icon">
                    <IconContext.Provider value={{ className: "upload-icon" }}>
                      <BsCameraFill style={{ fontSize: "30px" }} />
                    </IconContext.Provider>
                  </div>
                  <div className="take-photo-para">{t("capture")}</div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="list-of-avatars">
          {listOfAvatars.length > 0 && (
            <>
              {listOfAvatars.map((avatar) => {
                return (
                  <div className="avatar" key={avatar?.id}>
                    <div className="avatar-container">
                      <div className="avatar-image">
                        <img
                          src={avatar?.image}
                          alt=""
                          onClick={() => handleAvatarChange(avatar?.name)}
                          className={
                            selectedAvatarName === avatar?.name ? "active" : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="avatar-name">{`${t(
                      `${avatar?.name}`
                    )}`}</div>
                  </div>
                );
              })}
            </>
          )}
        </div>
        <div
          className="button-wrapper"
          onClick={handleSaveAvatar}
          style={{ direction: languageDirection }}
        >
          <button> {t("save")}</button>
          <div className="arrow">
            <IconContext.Provider value={{ className: "arrow-icon" }}>
              {languageDirection === "rtl" ? (
                <MdKeyboardArrowLeft
                  className="arrow-icon"
                  style={{ fontSize: "30px" }}
                />
              ) : (
                <MdKeyboardArrowRight
                  className="arrow-icon"
                  style={{ fontSize: "30px" }}
                />
              )}
            </IconContext.Provider>
          </div>
        </div>
        {!mobile && <FooterDesktop />}
      </div>
    </>
  );
};

export default ChangeAvatar;
