import "./styles/allergy-disclaimer.css";
import { CgDanger } from "react-icons/cg";
import { IconContext } from "react-icons";
import { setAllergyDisclaimerPopupOpenRedux } from "../redux/reducer";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
const AllergyDisclaimer = () => {
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const dispatch = useDispatch();
  return (
    <>
      <div className="allergy-disclaimer-container">
        <div className="main">
          <div
            className="disclaimer-wrapper"
            style={{ direction: languageDirection }}
          >
            <IconContext.Provider
              value={{ className: "danger-icon", size: "4.5em", color: "red" }}
            >
              <CgDanger />
            </IconContext.Provider>

            <span>{t("If you remove an allergen")}</span>
          </div>
          <div className="button-wrapper">
            <button
              className="remove-allergy"
              onClick={() =>
                dispatch(setAllergyDisclaimerPopupOpenRedux(false))
              }
            >
              {t("Got it")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllergyDisclaimer;
