import "./styles/testimonials.css";
import quate from "../images/quats.png";
import avatarImage from "../images/avatar/4.png";
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TiEdit } from "react-icons/ti";
import { MdOutlineDeleteForever } from "react-icons/md";
import { IconContext } from "react-icons";
import {
  setCurrentArticle as SetCurrentTestimonial,
  setItemForDeletion,
  setDeletionPopupContext,
  setDeletionPopupOpenRedux,
} from "../redux/reducer";
import leftArrow from "../images/arrow-left.png";
import rightArrow from "../images/arrow-right.png";
import { useNavigate } from "react-router-dom";
import imageBaseUrl from "../config";
import { useTranslation } from "react-i18next";
const Testimonials = () => {
  const mobile = useMediaQuery({ maxWidth: 800 });
  const [testimonial, setTestimonial] = useState(null);
  const [testimonials, setTestimonials] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const testimonialsRedux = useSelector(
    (state) => state.mainSlice.testimonials
  );

  const roleRedux = useSelector((state) => state.mainSlice.role);

  useEffect(() => {
    if (testimonialsRedux?.length) {
      setTestimonials(testimonialsRedux);
    }
  }, [testimonialsRedux]);

  useEffect(() => {
    if (testimonials?.length) {
      setTestimonial(testimonials[0]);
    }
  }, [testimonials]);
  const handleEdit = () => {
    const item = {
      ...testimonial,
      context: "testimonial",
    };
    dispatch(SetCurrentTestimonial(item));
    navigate("/edit-testimonial");
  };
  const handleDelete = async () => {
    dispatch(setDeletionPopupContext("testimonial"));
    dispatch(setItemForDeletion(testimonial));
    dispatch(setDeletionPopupOpenRedux(true));
  };
  const handleChangeTestimonial = (direction) => {
    const index = testimonials.findIndex((item) => item.id === testimonial.id);

    if (direction === "left") {
      // if direction is left we need to move one place to the left in testimonials array and set the new testimonial and if its index 0 we set the last item in the array

      if (index === 0) {
        setTestimonial(testimonials[testimonials.length - 1]);
      } else {
        setTestimonial(testimonials[index - 1]);
      }
    } else {
      // direction === "right"
      // if direction is right we need to move one place to the right in testimonials array and set the new testimonial and if its index is the last item in the array we set the first item in the array

      if (index === testimonials.length - 1) {
        setTestimonial(testimonials[0]);
      } else {
        setTestimonial(testimonials[index + 1]);
      }
    }
  };
  return (
    <>
      <div
        className={
          mobile ? "testimonials-container" : "testimonials-container desktop"
        }
      >
        <div
          className="left-arrow"
          onClick={() => handleChangeTestimonial("left")}
        >
          <img src={leftArrow} alt="" />
        </div>
        <div className="center">
          <div
            className={mobile ? "main" : "main desktop"}
            style={{ direction: languageDirection }}
          >
            <div className="quote-image">
              <img
                src={quate}
                alt=""
                style={{ width: mobile ? "70px" : "60px" }}
              />
            </div>
            <div
              className={
                mobile ? "testimonials-bubble" : "testimonials-bubble desktop"
              }
            >
              <div>{testimonial?.content}</div>
              {roleRedux === 2 && (
                <div
                  className="testimonial-admin"
                  style={{ gap: mobile ? "20px" : "10px" }}
                >
                  <span onClick={handleEdit}>
                    <IconContext.Provider
                      value={{
                        color: "#000000",
                        size: "1.2rem",
                        className: "global-class-name",
                      }}
                    >
                      <TiEdit />
                    </IconContext.Provider>
                  </span>
                  <span onClick={handleDelete}>
                    <IconContext.Provider
                      value={{
                        color: "#000000",
                        size: "1.2rem",
                        className: "global-class-name",
                      }}
                    >
                      <MdOutlineDeleteForever />
                    </IconContext.Provider>
                  </span>
                </div>
              )}
            </div>
          </div>

          <div
            className="testimonials-avatar"
            style={{ direction: languageDirection }}
          >
            <span
              className={
                mobile
                  ? "testimonial-img-circle"
                  : "testimonial-img-circle desktop"
              }
            >
              <img
                src={`${imageBaseUrl}testimonial-image/${testimonial?.image}`}
                alt=""
                style={{
                  width: mobile ? "40px" : "30px",
                  height: mobile ? "40px" : "30px",
                }}
              />
            </span>

            <span style={{ fontSize: mobile ? "1.2rem" : "1rem" }}>
              {`#${testimonial?.user_name}`}
            </span>
          </div>
        </div>
        <div
          className="right-arrow"
          onClick={() => handleChangeTestimonial("right")}
        >
          <img src={rightArrow} alt="" />
        </div>
      </div>
    </>
  );
};

export default Testimonials;
