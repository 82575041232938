import "./styles/weekly-recipes-popup.css";
import WeeklyRecipes from "./weekly-recipes";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setWeeklyRecipesPopupOpenRedux } from "../redux/reducer";
const WeeklyRecipesPopup = () => {
  const dispatch = useDispatch();
  return (
    <>
      <div className="weekly-recipes-popup-container">
        <div className="main">
          <WeeklyRecipes context="popup" navigate={null} />
          <div className="close-popup-wrapper">
            <div
              className="close"
              onClick={() => dispatch(setWeeklyRecipesPopupOpenRedux(false))}
            >
              close
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WeeklyRecipesPopup;
