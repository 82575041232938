import "./styles/footer-desktop.css";
import video from "../images/desktop/desktop-footer/video.png";
import stars from "../images/desktop/desktop-footer/stars.png";
import blog from "../images/desktop/desktop-footer/blog.png";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCleanBoxPopupOpenRedux } from "../redux/reducer";
import { useTranslation } from "react-i18next";
import { FaUsers } from "react-icons/fa6";
import { FaHeadphonesAlt } from "react-icons/fa";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { IoIosHelpBuoy } from "react-icons/io";
import { IoGameControllerOutline } from "react-icons/io5";

import { IconContext } from "react-icons/lib";
const FooterDesktop = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const handleRedirect = () => {
    window.open("https://www.hippocampus.me/", "_blank");
  };
  const handleAboutUs = () => {
    const lang = localStorage.getItem("lang");
    if (lang === "he") {
      window.open("https://getyoopi.com/about/", "_blank");
    } else {
      window.open("https://getyoopi.com/about-us-en/", "_blank");
    }
  };
  const handlePrivacy = () => {
    if (i18n.language === "he") {
      window.open("https://getyoopi.com/privacy-policy-heb/", "_blank");
    } else {
      window.open("https://getyoopi.com/privacy-policy-eng/", "_blank");
    }
  };

  return (
    <>
      <div className="desktop-footer">
        <div className="desktop-footer-main">
          <div
            className="kids-zone"
            onClick={() =>
              (window.location.href = "https://reg.getyoopi.com/kids-zone")
            }
          >
            <IconContext.Provider value={{ size: "25px", color: "#f8c13b" }}>
              <IoGameControllerOutline className="footer-icon" />
            </IconContext.Provider>
            <p>{t("kids zone")}</p>
          </div>
          <div className="videos" onClick={() => navigate("/videos")}>
            <img src={video} alt="" />
            <p>{t("videos")} </p>
          </div>
          <div
            className="clean"
            onClick={() => dispatch(setCleanBoxPopupOpenRedux(true))}
          >
            <img src={stars} alt="" />
            <p>{t("clean_box")} </p>
          </div>
          <div
            className="blog"
            onClick={() =>
              window.open("https://getyoopi.com/blog-post/", "_blank")
            }
          >
            <img src={blog} alt="" />
            <p>{t("to_our_blog")}</p>
          </div>

          <div className="privacy" onClick={handlePrivacy}>
            <IconContext.Provider value={{ size: "20px", color: "#B89CD1" }}>
              <MdOutlinePrivacyTip className="footer-icon" />
            </IconContext.Provider>
            <p>{t("Privacy Policy")}</p>
          </div>
          <div
            className="contact-us"
            onClick={() =>
              window.open("https://getyoopi.com/contact-us/", "_blank")
            }
          >
            <IconContext.Provider value={{ size: "20px", color: "#B89CD1" }}>
              <FaHeadphonesAlt className="footer-icon" />
            </IconContext.Provider>
            <p>{t("Contact us")}</p>
          </div>
          <div className="about-us" onClick={handleAboutUs}>
            <IconContext.Provider value={{ size: "20px", color: "#B89CD1" }}>
              <FaUsers className="footer-icon" />
            </IconContext.Provider>
            <p>{t("About us")}</p>
          </div>
          {/* <div className="help">
            <IconContext.Provider value={{ size: "20px", color: "#B89CD1" }}>
              <IoIosHelpBuoy className="footer-icon" />
            </IconContext.Provider>
            <p>{t("Help")}</p>
          </div> */}
        </div>
{/*         <div className="desktop-footer-credit" onClick={handleRedirect}> */}
{/*           <p>{t("built_By_hippocampus")}</p>
         </div> */}
      </div>
    </>
  );
};

export default FooterDesktop;
