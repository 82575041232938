import { createSlice } from "@reduxjs/toolkit";
export const mainSlice = createSlice({
  name: "mainSlice",
  initialState: {
    householdId: null,
    role: null,
    childProfiles: [],
    boxColor: null,
    currentChildProfile: {},
    timeZone: null,
    notifications: [],
    currentNotification: {},
    personalRecipes: [],
    generalRecipes: [],
    myCreatedRecipes: [],
    currentRecipe: {},
    recipePanelEditMode: false,
    weeklyRecipes: [],
    profilePreferences: {
      allergies: [],
      diets: [],
      foods: [],
    },
    weeklyRecipesPopupOpen: false,
    cleanBoxPopupOpen: false,
    deleteRecipePopupOpen: false,
    deletionPopupOpen: false,
    deletionPopupContext: null,
    allergyDisclaimerPopupOpen: false,
    nutritionalValuesPopupOpen: false,
    notificationPopupOpen: false,
    weeklySupplies: [],
    searchResults: [],
    articles: [],
    currentArticle: {},
    itemForDeletion: {},
    testimonials: [],
    video: {},
    dontFetchUserDetails: false,
  },
  reducers: {
    setNotificationsRedux: (state, action) => {
      state.notifications = action.payload;
    },
    setCurrentNotification: (state, action) => {
      state.currentNotification = action.payload;
    },
    setChildProfilesRedux: (state, action) => {
      state.childProfiles = action.payload;
    },
    setCurrentChildProfileRedux: (state, action) => {
      state.currentChildProfile = action.payload;
    },
    setHouseholdIdRedux: (state, action) => {
      state.householdId = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setPersonalRecipes: (state, action) => {
      state.personalRecipes = action.payload;
    },
    setGeneralRecipes: (state, action) => {
      state.generalRecipes = action.payload;
    },
    setCurrentRecipeRedux: (state, action) => {
      state.currentRecipe = action.payload;
    },
    setWeeklyRecipesRedux: (state, action) => {
      state.weeklyRecipes = action.payload;
    },
    setWeeklyRecipesPopupOpenRedux: (state, action) => {
      state.weeklyRecipesPopupOpen = action.payload;
    },
    setWeeklySuppliesRedux: (state, action) => {
      state.weeklySupplies = action.payload;
    },
    setProfilePreferencesRedux: (state, action) => {
      state.profilePreferences = action.payload;
    },
    setCleanBoxPopupOpenRedux: (state, action) => {
      state.cleanBoxPopupOpen = action.payload;
    },
    setSearchResultsRedux: (state, action) => {
      state.searchResults = action.payload;
    },
    setDeleteRecipePopupOpenRedux: (state, action) => {
      state.deleteRecipePopupOpen = action.payload;
    },
    setAllergyDisclaimerPopupOpenRedux: (state, action) => {
      state.allergyDisclaimerPopupOpen = action.payload;
    },
    setMyCreatedRecipesRedux: (state, action) => {
      state.myCreatedRecipes = action.payload;
    },
    setRecipePanelEditMode: (state, action) => {
      state.recipePanelEditMode = action.payload;
    },
    setArticles: (state, action) => {
      state.articles = action.payload;
    },
    setTestimonials: (state, action) => {
      state.testimonials = action.payload;
    },
    setDeletionPopupOpenRedux: (state, action) => {
      state.deletionPopupOpen = action.payload;
    },
    setDeletionPopupContext: (state, action) => {
      state.deletionPopupContext = action.payload;
    },
    setItemForDeletion: (state, action) => {
      state.itemForDeletion = action.payload;
    },
    setCurrentArticle: (state, action) => {
      state.currentArticle = action.payload;
    },
    setNutritionalValuesPopupRedux: (state, action) => {
      state.nutritionalValuesPopupOpen = action.payload;
    },
    setBoxColor: (state, action) => {
      state.boxColor = action.payload;
    },
    setTimeZone: (state, action) => {
      state.timeZone = action.payload;
    },
    setCurrentVideo: (state, action) => {
      state.video = action.payload;
    },
    setDontFetchUserDetails: (state, action) => {
      state.dontFetchUserDetails = action.payload;
    },
    setNotificationPopupOpenRedux: (state, action) => {
      state.notificationPopupOpen = action.payload;
    },
  },
});

export const {
  setNotificationsRedux,
  setCurrentNotification,
  setChildProfilesRedux,
  setHouseholdIdRedux,
  setCurrentChildProfileRedux,
  setPersonalRecipes,
  setGeneralRecipes,
  setCurrentRecipeRedux,
  setWeeklyRecipesRedux,
  setWeeklyRecipesPopupOpenRedux,
  setWeeklySuppliesRedux,
  setProfilePreferencesRedux,
  setRole,
  setCleanBoxPopupOpenRedux,
  setSearchResultsRedux,
  setDeleteRecipePopupOpenRedux,
  setAllergyDisclaimerPopupOpenRedux,
  setMyCreatedRecipesRedux,
  setRecipePanelEditMode,
  setArticles,
  setTestimonials,
  setBoxColor,
  setDeletionPopupOpenRedux,
  setDeletionPopupContext,
  setCurrentArticle,
  setNutritionalValuesPopupRedux,
  setTimeZone,
  setCurrentVideo,
  setItemForDeletion,
  setDontFetchUserDetails,
  setNotificationPopupOpenRedux,
} = mainSlice.actions;
export default mainSlice.reducer;
