import { useEffect, useState } from "react";
import redHeart from "../images/red-heart.png";
import whiteHeart from "../images/white-heart.png";
import recipeImagePlaceholder from "../images/desktop/recipes/1.jpg";

import StarRatings from "react-star-ratings";
import { AiOutlineEdit } from "react-icons/ai";
import { IconContext } from "react-icons";
import { useSelector, useDispatch } from "react-redux";
import recipeService from "../services/recipeService";
import "./styles/recipe-desktop.css";
import { setGeneralRecipes, setPersonalRecipes } from "../redux/reducer";
import imageBaseUrl from "../config";
// diet icons
import pescatarian from "../images/icons/pescatarian.png";
import veganIcon from "../images/icons/vegan.png";
import vegetarianIcon from "../images/icons/vegetarian.png";

// allergy icons
import genericAllergy from "../images/icons/general-allergy.png";
import glutenFree from "../images/icons/gluten.png";
import lactoseFree from "../images/icons/lactose.png";
import nutFree from "../images/icons/nuts.png";
import eggFree from "../images/icons/eggs.png";
import fishFree from "../images/icons/fish.png";
import sesameFree from "../images/icons/sesame.png";
import soyFree from "../images/icons/soy.png";

import { useTranslation } from "react-i18next";

// RecipeCardDesktop
const RecipeCardDesktop = ({ recipe, handleRecipeClick }) => {
  const [isFavorite, setIsFavorite] = useState(recipe.favorite);
  const [recipeLifeStyle, setRecipeLifeStyle] = useState(recipe.life_style);
  const currentProfile = useSelector(
    (state) => state.mainSlice.currentChildProfile
  );
  const myCreatedRecipesRedux = useSelector(
    (state) => state.mainSlice.myCreatedRecipes
  );
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const textAlignment = i18n.language === "he" ? "right" : "left";
  const dispatch = useDispatch();

  const handleFavoriteClick = async (e = null) => {
    if (e !== null) e.stopPropagation();
    /* if recipe was created by user we dont add to favorites */
    if (recipe.type === "personal") return;

    try {
      const response = await recipeService.handleFavorites(
        currentProfile.id,
        recipe.id,
        !isFavorite
      );

      if (response.message === "success") {
        const getRecipes = await recipeService.getRecipes(currentProfile.id);
        if (getRecipes.message === "success") {
          getRecipes.personalRecipes.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );

          getRecipes.generalRecipes.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );
          // remove myRecipes from generalRecipes
          if (myCreatedRecipesRedux.length) {
            getRecipes.generalRecipes = getRecipes.generalRecipes.filter(
              (recipe) => {
                return !myCreatedRecipesRedux.some(
                  (myRecipe) => myRecipe.id === recipe.id
                );
              }
            );
          }
          dispatch(setGeneralRecipes(getRecipes.generalRecipes));
          dispatch(setPersonalRecipes(getRecipes.personalRecipes));
        }
      }
    } catch (err) {
      console.log(err, "error adding favorite");
    }
  };
  useEffect(() => {
    if (recipe?.life_style !== null) {
      if (recipe?.life_style?.includes(",")) {
        setRecipeLifeStyle(recipe.life_style.split(",")[0]);
      } else {
        setRecipeLifeStyle(recipe.life_style);
      }
    }
  }, [recipe]);

  return (
    <>
      <div className="recipe-desktop">
        <div
          className="recipe-desktop-top"
          onClick={() => handleRecipeClick(recipe)}
          style={{
            height: "150px",
            backgroundImage: recipe.image
              ? `url(${imageBaseUrl}recipe-image/${recipe.image})`
              : `url(${recipeImagePlaceholder})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          {recipe.life_style !== null && (
            // if recipe is vegetarian or vegan or pescatarian show icon of first life style only
            <img
              src={
                parseInt(recipe.life_style.split(",")[0], 10) === 1
                  ? vegetarianIcon
                  : parseInt(recipe.life_style.split(",")[0], 10) === 2
                  ? veganIcon
                  : parseInt(recipe.life_style.split(",")[0], 10) === 3
                  ? pescatarian
                  : null
              }
              alt=""
              className="recipe-desktop-vegan"
            />
          )}
          {/* {recipe.allergy_free !== null && (
            <img src={recipe.allergy_free === ""} alt="" className="recipe-desktop-allergy" />
          )} */}
          {/* {recipe.allergy_free !== null && (
            <>
              {recipe.allergy_free.includes(",") ? (
                   // Handling multiple allergy items
                recipe.allergy_free.split(",").length > 2 ? (
                          // More than two allergy items, display generic icon

                  <img
                    src={genericAllergy}
                    alt=""
                    className="recipe-desktop-allergy"
                  />
                ) : (
                  <>
                    {recipe.allergy_free.split(",").map((item, index) => (
                      <img
                        key={index}
                        src={
                          item.trim() === "1"
                            ? eggFree
                            : item.trim() === "2"
                            ? fishFree
                            : item.trim() === "3"
                            ? nutFree
                            : item.trim() === "4"
                            ? glutenFree
                            : item.trim() === "5"
                            ? soyFree
                            : item.trim() === "6"
                            ? sesameFree
                            : item.trim() === "7"
                            ? lactoseFree
                            : null
                        }
                        alt=""
                        className={
                          recipe.life_style !== null
                            ? `recipe-desktop-allergy recipe-desktop-allergy-${
                                index + 1
                              }`
                            : "recipe-desktop-allergy no-life-style"
                        }
                      />
                    ))}
                  </>
                )
              ) : (
                <img
                  src={
                    recipe.allergy_free.trim() === "1"
                      ? eggFree
                      : recipe.allergy_free.trim() === "2"
                      ? fishFree
                      : recipe.allergy_free.trim() === "3"
                      ? nutFree
                      : recipe.allergy_free.trim() === "4"
                      ? glutenFree
                      : recipe.allergy_free.trim() === "5"
                      ? soyFree
                      : recipe.allergy_free.trim() === "6"
                      ? sesameFree
                      : recipe.allergy_free.trim() === "7"
                      ? lactoseFree
                      : null
                  }
                  alt=""
                  className="recipe-desktop-allergy"
                  style={{
                    right: recipe.life_style === null ? "25px" : "75px",
                  }}
                />
              )}
            </>
          )} */}
          {recipe.allergy_free !== null && (
            <>
              {recipe.allergy_free.includes(",") ? (
                // Handling multiple allergy items
                recipe.allergy_free.split(",").length > 2 ? (
                  // More than two allergy items, display generic icon
                  <img
                    src={genericAllergy}
                    alt=""
                    className="recipe-desktop-allergy"
                  />
                ) : (
                  // Exactly two allergy items, render both icons
                  <>
                    {recipe.allergy_free.split(",").map((item, index) => (
                      <img
                        key={index}
                        src={
                          item.trim() === "1"
                            ? eggFree
                            : item.trim() === "2"
                            ? fishFree
                            : item.trim() === "3"
                            ? nutFree
                            : item.trim() === "4"
                            ? glutenFree
                            : item.trim() === "5"
                            ? soyFree
                            : item.trim() === "6"
                            ? sesameFree
                            : item.trim() === "7"
                            ? lactoseFree
                            : null
                        }
                        alt=""
                        className={
                          recipe.life_style !== null
                            ? `recipe-desktop-allergy recipe-desktop-allergy-${
                                index + 1
                              }`
                            : `recipe-desktop-allergy no-life-style-${index}`
                        }
                      />
                    ))}
                  </>
                )
              ) : (
                // Handling single allergy item
                <img
                  src={
                    recipe.allergy_free.trim() === "1"
                      ? eggFree
                      : recipe.allergy_free.trim() === "2"
                      ? fishFree
                      : recipe.allergy_free.trim() === "3"
                      ? nutFree
                      : recipe.allergy_free.trim() === "4"
                      ? glutenFree
                      : recipe.allergy_free.trim() === "5"
                      ? soyFree
                      : recipe.allergy_free.trim() === "6"
                      ? sesameFree
                      : recipe.allergy_free.trim() === "7"
                      ? lactoseFree
                      : null
                  }
                  alt=""
                  className="recipe-desktop-allergy"
                  style={{
                    right: recipe.life_style === null ? "25px" : "75px",
                  }}
                />
              )}
            </>
          )}

          {recipe.type === "general" && (
            <img
              src={recipe?.favorite ? redHeart : whiteHeart}
              alt=""
              className="recipe-desktop-heart"
              onClick={(e) => handleFavoriteClick(e)}
            />
          )}
        </div>
        <div
          className="recipe-desktop-bottom"
          style={{
            background: recipe.type === "personal" ? "#b7dfeb" : "#e0eef2",
          }}
          onClick={() => handleRecipeClick(recipe)}
        >
          <div
            className="recipe-desktop-title"
            style={{
              textAlign: textAlignment,
              padding:
                languageDirection === "rtl" ? "0 20px 0 0" : "0  0 0 20px",
            }}
          >
            {recipe.name}
          </div>

          {recipe.type === "personal" ? (
            <>
              <div className="personal-recipe">
                <span>
                  <IconContext.Provider
                    value={{ color: "#3AA7D5", size: "20px" }}
                  >
                    <AiOutlineEdit />
                  </IconContext.Provider>
                </span>
                <span> {t("watch_edit")}</span>
              </div>
            </>
          ) : (
            <>
              <div
                className="recipe-desktop-rating"
                style={{
                  direction: languageDirection,
                  padding:
                    languageDirection === "rtl" ? "0 20px 0 0" : "0  0 0 20px",
                }}
              >
                <span>{t("rate")}</span>
                <StarRatings
                  rating={recipe.rating ? recipe.rating : 5}
                  starRatedColor="gold"
                  //   changeRating={handleRatingChange}
                  numberOfStars={recipe.rating ? recipe.rating : 5}
                  name="rating"
                  starDimension="13px" // Adjust the size of the stars
                  starSpacing="2px" // Adjust the spacing between stars
                  starFullColor="#3AA7D5" // Color for the selected star
                  starEmptyColor="#ccc" // Color for the empty stars
                  starHoverColor="#3AA7D5" // Color for stars on hover
                  className="star-ratings" // Apply your custom CSS class here
                />
              </div>
              <div
                className="recipe-desktop-cooking-time"
                style={{
                  direction: languageDirection,
                  padding:
                    languageDirection === "rtl" ? "0 20px 0 0" : "0  0 0 20px",
                }}
              >
                <span>{t("cooking_time_recipe_card")} </span>
                <span>{`${recipe.cooking_time} ${t("min")} `}</span>
              </div>
              <div
                className="recipe-desktop-cooking-time"
                style={{
                  direction: languageDirection,
                  padding:
                    languageDirection === "rtl" ? "0 20px 0 0" : "0  0 0 20px",
                }}
              >
                <span>{t("prep_time")}</span>
                <span> {`${recipe.prep_time} ${t("min")} `}</span>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default RecipeCardDesktop;
