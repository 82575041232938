import { useEffect, useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import HeaderDesktop from "./header-desktop";
import HeaderMobile from "./header-mobile";
import YellowSeparator from "./yellow-separator";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import profileService from "../services/profileService";
import "./styles/users-list.css";
import { MdDeleteForever } from "react-icons/md";
import {
  setDeletionPopupOpenRedux,
  setItemForDeletion,
  setDeletionPopupContext,
} from "../redux/reducer";
const UsersList = () => {
  const mobile = useMediaQuery({ maxWidth: 800 });
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const navigate = useNavigate();
  const role = useSelector((state) => state.mainSlice.role);
  const [usersList, setUsersList] = useState([]);
  const [error, setError] = useState("");
  const [offset, setOffset] = useState(0);

  const [hasMoreUsers, setHasMoreUsers] = useState(true); // Track if there are more users to fetch
  const [fetchingUsers, setFetchingUsers] = useState(false); // Track if users are currently being fetched
  const limit = 50; // Number of users per page
  const fetchUsersList = useCallback(async () => {
    if (fetchingUsers) return;

    setFetchingUsers(true);

    try {
      const list = await profileService.getUserList({ limit, offset });

      if (list.message === "success") {
        const newUsers = list.users;

        if (newUsers.length > 0) {
          if (offset === 0) {
            // If fetching the first page, replace the existing list with the new one
            setUsersList(newUsers);
          } else {
            // If fetching subsequent pages, remove the last batch of fetched users
            // before appending the new batch
            setUsersList((prevUsers) =>
              prevUsers.slice(0, -limit).concat(newUsers)
            );
          }
          // Update the offset for the next page
          setOffset((prevOffset) => prevOffset + limit);
          // If fetched less than the limit, it means there are no more users to fetch
          if (newUsers.length < limit) {
            setHasMoreUsers(false);
          }
        } else {
          // If no new users are fetched, there are no more users to fetch
          setHasMoreUsers(false);
        }
      } else {
        // Handle error
        console.error("Error fetching users:", list.message);
        setUsersList([]);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setFetchingUsers(false);
    }
  }, [fetchingUsers, offset]);

  useEffect(() => {
    if (role !== 2) {
      navigate("/");
    } else {
      fetchUsersList();
    }
  }, []);
  useEffect(() => {
    if (mobile) {
      navigate("/");
    }
  }, [mobile]);

  const formatDate = useCallback((isoDateString) => {
    const date = new Date(isoDateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }, []);
  const handleRemoveUser = async (user) => {
    dispatch(setItemForDeletion(user));
    dispatch(setDeletionPopupContext("household"));
    dispatch(setDeletionPopupOpenRedux(true));
  };
  const renderedUsers = useMemo(
    () =>
      usersList.map((user) => (
        <tr key={user.child_profile_id}>
          <td>{user.box_identifier}</td>
          <td style={{ minWidth: "120px" }}>{formatDate(user.created_at)}</td>
          <td>{user.parent_name}</td>
          <td>{user.parent_last_name}</td>
          <td>{user.email}</td>
          <td>{user.child_name}</td>
          <td>{user.year_of_birth}</td>
          <td>{user.month_of_birth}</td>
          <td>
            {!user.diet_names ||
            user.diet_names === "Null" ||
            user.diet_names === null ||
            user.diet_names === "None"
              ? `${t("Eats Everything")}`
              : user.diet_names}
          </td>
          <td>
            {!user.allergy_names ||
            user.allergy_names === "None" ||
            user.allergy_names === null ||
            user.allergy_names === "NULL"
              ? `${t("None")}`
              : user.allergy_names}
          </td>
          <td>{user.hubbie_names}</td>
          <td>{user.learning_environment}</td>
          <td
            style={{ textAlign: "center", cursor: "pointer" }}
            onClick={() => handleRemoveUser(user)}
          >
            <MdDeleteForever />
          </td>
        </tr>
      )),
    [usersList]
  );

  return (
    <>
      <div className="user-list-container">
        <HeaderDesktop />

        <div className="user-list-main-content" dir={languageDirection}>
          <h1>{t("Registered Users")}</h1>
          {error && <p>{error}</p>}
          <table
            className="users-table"
            style={{ direction: languageDirection }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    textAlign: languageDirection === "ltr" ? "left" : "right",
                  }}
                >
                  {t("box number")}
                </th>
                <th
                  style={{
                    textAlign: languageDirection === "ltr" ? "left" : "right",
                  }}
                >
                  {t("creation date")}
                </th>
                <th
                  style={{
                    textAlign: languageDirection === "ltr" ? "left" : "right",
                  }}
                >
                  {t("parent name")}
                </th>
                <th
                  style={{
                    textAlign: languageDirection === "ltr" ? "left" : "right",
                  }}
                >
                  {t("parent last name")}
                </th>
                <th
                  style={{
                    textAlign: languageDirection === "ltr" ? "left" : "right",
                  }}
                >
                  {t("email")}
                </th>
                <th
                  style={{
                    textAlign: languageDirection === "ltr" ? "left" : "right",
                  }}
                >
                  {t("child name")}
                </th>
                <th
                  style={{
                    textAlign: languageDirection === "ltr" ? "left" : "right",
                  }}
                >
                  {t("year of birth")}
                </th>
                <th
                  style={{
                    textAlign: languageDirection === "ltr" ? "left" : "right",
                  }}
                >
                  {t("month of birth")}
                </th>
                <th
                  style={{
                    textAlign: languageDirection === "ltr" ? "left" : "right",
                  }}
                >
                  {t("life style")}
                </th>
                <th
                  style={{
                    textAlign: languageDirection === "ltr" ? "left" : "right",
                  }}
                >
                  {t("allergies")}
                </th>
                <th
                  style={{
                    textAlign: languageDirection === "ltr" ? "left" : "right",
                  }}
                >
                  {t("hubbies")}
                </th>
                <th
                  style={{
                    textAlign: languageDirection === "ltr" ? "left" : "right",
                  }}
                >
                  {t("goes to")}
                </th>
                <th
                  style={{
                    textAlign: languageDirection === "ltr" ? "left" : "right",
                  }}
                >
                  {t("Delete")}
                </th>
              </tr>
            </thead>
            <tbody>{renderedUsers}</tbody>
          </table>

          <div className="pagination-buttons">
            <button
              onClick={() =>
                setOffset((prevOffset) => Math.max(prevOffset - limit, 0))
              }
              disabled={offset === 0 || fetchingUsers}
            >
              Previous
            </button>
            <button
              onClick={fetchUsersList}
              disabled={!hasMoreUsers || fetchingUsers}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersList;
