import "./styles/clean-box-popup.css";
import { useDispatch } from "react-redux";
import { setCleanBoxPopupOpenRedux } from "../redux/reducer";
import stars from "../images/box-clean.png";
import { useTranslation } from "react-i18next";
const CleanBoxPopup = () => {
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const dispatch = useDispatch();
  const handleMoreInformationClick = () => {
    const lang = localStorage.getItem("lang");
    let link;
    if (lang === "he") {
      link = "https://getyoopi.com/yoopi-clean-instructions-heb/";
    } else {
      link = "https://getyoopi.com/yoopi-clean-instructions-eng/";
    }

    const openLink = (e) => {
      e.preventDefault();
      const href = e.target.getAttribute("href");
      window.open(href, "_blank", "noopener,noreferrer");
    };

    // Create a dummy link element to trigger the event
    const dummyLink = document.createElement("a");
    dummyLink.setAttribute("href", link);

    dummyLink.style.display = "none";
    document.body.appendChild(dummyLink);

    // Trigger the event
    dummyLink.addEventListener("click", openLink);
    dummyLink.click();

    // Clean up
    document.body.removeChild(dummyLink);
  };
  return (
    <div className="clean-box-container">
      <div className="main-content">
        <div className="stars">
          <img src={stars} alt="" />
        </div>
        <div className="clean-box" style={{ direction: languageDirection }}>
          <h2>{t("how_clean_box")} </h2>
          <p>
            <span>{t("Yes_you_can_clean_in_the_dishwasher")}</span>{" "}
            {t("but_please_use_the_top_tray_only")}
          </p>
          <p>
            <span>{t("follow_these_steps")}</span>
            {/* {t("prevent_mold_growth")} */}
          </p>

          <ul>
            <li>{t("maintain_hygiene")}</li>
            <li>{t("special_care")}</li>
            <li>{t("humid_environment")}</li>
            <li>{t("warranty_voided")}</li>
          </ul>
        </div>
        <div
          className="more-information"
          style={{ direction: languageDirection }}
          onClick={handleMoreInformationClick}
        >
          {t("want_to_know_more")}
        </div>
        <div
          style={{ direction: languageDirection }}
          className="button-wrapper"
          onClick={() => dispatch(setCleanBoxPopupOpenRedux(false))}
        >
          <button>{t("got_it")}</button>
        </div>
      </div>
    </div>
  );
};

export default CleanBoxPopup;
