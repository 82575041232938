import "./styles/box-profile.css";
import { IconContext } from "react-icons";
import {
  MdOutlineArrowForwardIos,
  MdOutlineArrowBackIosNew,
} from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { use } from "i18next";
import yellowBox from "../images/boxes/box_yellow.png";
import baseUrl from "../config";
const BoxProfile = () => {
  const mobile = useMediaQuery({ maxWidth: 800 });
  const navigate = useNavigate();
  const currentProfileRedux = useSelector(
    (state) => state.mainSlice.currentChildProfile
  );
  const { t, i18n } = useTranslation();

  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";

  const boxImageRedux = useSelector((state) => state.mainSlice.boxColor);
  const [profileName, setProfileName] = useState("");

  useEffect(() => {
    if (currentProfileRedux?.id) {
      setProfileName(currentProfileRedux.first_name);
    }
  }, [currentProfileRedux]);

  const handleKidsZone = () => {
    const link = "https://reg.getyoopi.com/kids-zone";

    // Open the link in a new tab without using window open
    // to avoid the popup blocker
    const newTab = document.createElement("a");
    newTab.href = link;
    newTab.target = "_blank";
    newTab.rel = "noopener noreferrer";
    newTab.click();
  };
  return (
    <>
      <div className={mobile ? "profile-box" : "profile-box desktop"}>
        {mobile ? (
          <>
            <div className="profile-name">
              <h2>{profileName}</h2>
            </div>
            <img src={boxImageRedux} alt="" />
          </>
        ) : (
          /* desktop */
          <>
            <div className="profile-name">
              <h2 style={{ fontSize: "25px" }}>{profileName}</h2>
            </div>
            <img
              src={boxImageRedux}
              alt=""
              style={{ maxWidth: mobile ? "320px" : "300px" }}
            />
          </>
        )}
      </div>
      <div className="buttons-container">
        <div
          className={mobile ? "btn-wrapper" : "btn-wrapper-desktop"}
          style={{ direction: languageDirection }}
          onClick={() => navigate("/recipes")}
        >
          <span>{t("to_my_recipes")}</span>
          <div className="arrow">
            <IconContext.Provider value={{ className: "arrow-icon" }}>
              {i18n.language === "en" ? (
                <MdOutlineArrowForwardIos />
              ) : (
                <MdOutlineArrowBackIosNew />
              )}
            </IconContext.Provider>
          </div>
        </div>
        <div
          className={mobile ? "kids-zone mobile" : "kids-zone"}
          onClick={handleKidsZone}
        >
          {t("kids zone")}
        </div>
      </div>
    </>
  );
};

export default BoxProfile;
