import HeaderDesktop from "./header-desktop";
import HeaderMobile from "./header-mobile";
import "./styles/favorites.css";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import FooterDesktop from "./footer-desktop";
import RecipeCardDesktop from "./recipe-card-desktop";
import RecipeCardMobile from "./recipe-card-mobile";
import { setCurrentRecipeRedux } from "../redux/reducer";
import { useNavigate } from "react-router-dom";
import recipeService from "../services/recipeService";
import { useTranslation } from "react-i18next";
const Favorites = () => {
  const mobile = useMediaQuery({ maxWidth: 800 });
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const [favorites, setFavorites] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const generalRecipes = useSelector((state) => state.mainSlice.generalRecipes);
  const personalRecipes = useSelector(
    (state) => state.mainSlice.personalRecipes
  );
  const currentProfile = useSelector(
    (state) => state.mainSlice.currentChildProfile
  );

  useEffect(() => {
    const fetchFavorites = async () => {
      try {
        const response = await recipeService.getFavorites(currentProfile.id);
        console.log(response, "response 68");

        if (response.message === "success") {
          // favorites is not empty from db
          // Combine generalRecipes and personalRecipes
          const allRecipes = [...generalRecipes, ...personalRecipes];

          // Map over allRecipes and update the favorite property
          const updatedRecipes = allRecipes.map((recipe) => {
            // Check if the recipe_id is in response.favorites
            const isFavorite = response.favorites.some(
              (favoriteRecipe) => favoriteRecipe.recipe_id == recipe.id
            );
            // Set favorite property based on isFavorite
            return { ...recipe, favorite: isFavorite };
          });
          // Filter recipes that are favorites
          const favorites = updatedRecipes.filter((recipe) => recipe.favorite);
          setFavorites(favorites);
        } else {
          // favorites is empty from db

          setFavorites([]);
        }
      } catch (err) {
        console.log(err, "error fetching favorites");
      }
    };

    if (!currentProfile?.id) {
      navigate("/");
    } else {
      fetchFavorites();
    }
  }, []);
  useEffect(() => {
    console.log(generalRecipes, "generalRecipes fav");
    console.log(personalRecipes, "generalRecipes fav");
  }, [generalRecipes, personalRecipes]);
  const handleRecipeClick = async (recipe) => {
    dispatch(setCurrentRecipeRedux(recipe));
    navigate(`/recipe-review`);
  };

  return (
    <>
      {mobile ? (
        <>
          <HeaderMobile />

          <div className="favorites-wrapper">
            <div
              className="title"
              style={{
                textAlign: languageDirection === "ltr" ? "left" : "right",
                margin: languageDirection === "ltr" ? "0 0 0 10%" : "0 10% 0 0",
              }}
            >
              {t("favorites")}
            </div>

            <div className="favorite-recipes">
              {favorites.length > 0 ? (
                <>
                  {favorites.map((recipe) => {
                    return (
                      <RecipeCardMobile
                        key={recipe.id}
                        recipe={recipe}
                        handleRecipeClick={handleRecipeClick}
                      />
                    );
                  })}
                </>
              ) : (
                <>
                  <div
                    className="no-favorites"
                    style={{ direction: languageDirection }}
                  >
                    {t("No Favorites Selected")}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <HeaderDesktop />
          <div
            className="favorites-wrapper desktop"
            style={{ direction: languageDirection }}
          >
            <div
              className="title"
              style={{
                textAlign: languageDirection === "ltr" ? "left" : "right",
                margin: languageDirection === "ltr" ? "0 0 0 10%" : "0 10% 0 0",
              }}
            >
              {t("favorites")}
            </div>
            <div className="favorite-recipes desktop">
              {favorites.length > 0 ? (
                <>
                  {favorites.map((recipe) => {
                    return (
                      <RecipeCardDesktop
                        key={recipe.id}
                        recipe={recipe}
                        handleRecipeClick={handleRecipeClick}
                      />
                    );
                  })}
                </>
              ) : (
                <>
                  <div
                    className="no-favorites"
                    style={{ direction: languageDirection }}
                  >
                    {" "}
                    {t("No Favorites Selected")}
                  </div>
                </>
              )}
            </div>
          </div>
          <FooterDesktop />
        </>
      )}

      {}
    </>
  );
};

export default Favorites;
