import { useMediaQuery } from "react-responsive";
import HeaderDesktop from "./header-desktop";
import HeaderMobile from "./header-mobile";
import "./styles/search-results.css";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSearchResultsRedux, setCurrentRecipeRedux } from "../redux/reducer";
import RecipeCardMobile from "./recipe-card-mobile";
import RecipeCardDesktop from "./recipe-card-desktop";
import FooterDesktop from "./footer-desktop";
import MobileFooter from "./footer-mobile";
import { useTranslation } from "react-i18next";
const SearchResults = () => {
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const mobile = useMediaQuery({ maxWidth: 800 });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchResults, setSearchResults] = useState([]);
  const searchResultsRedux = useSelector(
    (state) => state.mainSlice.searchResults
  );
  useEffect(() => {
    if (searchResultsRedux.length) {
      setSearchResults(searchResultsRedux);
    }
    return () => {
      // Dispatch action when component is unmounting
      dispatch(setSearchResultsRedux([]));
    };
  }, []);
  const handleRecipeClick = (recipe) => {
    dispatch(setCurrentRecipeRedux(recipe));
    navigate("/recipe-review");
  };
  return (
    <>
      {mobile ? <HeaderMobile /> : <HeaderDesktop />}

      <div className="search-results-container">
        <div className="search-results-wrapper">
          <h2 dir={languageDirection}>
            {searchResults.length
              ? `${t("Search Results:")}`
              : `${t("No Recipes Match Search Criteria")}`}
          </h2>

          {mobile ? (
            <>
              {/* mobile */}
              <div className="results-wrapper">
                {searchResults.length > 0 &&
                  searchResults.map((recipe) => {
                    return (
                      <RecipeCardMobile
                        recipe={recipe}
                        key={recipe.id}
                        handleRecipeClick={handleRecipeClick}
                      />
                    );
                  })}
              </div>
            </>
          ) : (
            <>
              {/*  desktop */}
              <div className="results-wrapper-desktop">
                {searchResults.length > 0 &&
                  searchResults.map((recipe) => {
                    return (
                      <RecipeCardDesktop
                        recipe={recipe}
                        key={recipe.id}
                        handleRecipeClick={handleRecipeClick}
                      />
                    );
                  })}
              </div>
            </>
          )}
        </div>
        <div className="buttons-wrapper">
          <button
            className="return"
            onClick={() => {
              navigate("/");
            }}
          >
            {t("back")}
          </button>
          <button
            className="search-again"
            onClick={() => {
              navigate("/search");
            }}
          >
            {t("new search")}
          </button>
        </div>
      </div>
      {!mobile && <FooterDesktop />}
    </>
  );
};

export default SearchResults;
