import "./styles/notification-popup.css";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  setNotificationPopupOpenRedux,
  setNotificationsRedux,
} from "../redux/reducer";
import newProductImage from "../images/icons/notification_icons/New-Product.png";
import holidayImage from "../images/icons/notification_icons/Holiday.png";
import saleImage from "../images/icons/notification_icons/Sale.png";
import reminderImage from "../images/icons/notification_icons/Reminder.png";
import updateImage from "../images/icons/notification_icons/Update.png";
import contentImage from "../images/icons/notification_icons/Content.png";
import cleanBocImage from "../images/icons/notification_icons/CleanBox.png";
import otherImage from "../images/icons/notification_icons/Other.png";

import bubble from "../images/redBubbles.png";
import bell from "../images/bell.png";
import present from "../images/pressent.png";
import { IoMdClose } from "react-icons/io";
import { IconContext } from "react-icons";
import articleService from "../services/articleService";
import { useTranslation } from "react-i18next";
const NotificationPopup = () => {
  const currentNotification = useSelector(
    (state) => state.mainSlice.currentNotification
  );
  const currentProfile = useSelector(
    (state) => state.mainSlice.currentChildProfile
  );
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const [notification, setNotification] = useState({});
  const [notificationIcon, setNotificationIcon] = useState(null);
  useEffect(() => {
    setNotification(currentNotification);
  }, [currentNotification]);
  useEffect(() => {
    if (notification.type) {
      switch (notification.type) {
        case "New Product":
          setNotificationIcon(newProductImage);
          break;
        case "Holiday":
          setNotificationIcon(holidayImage);
          break;
        case "Sale":
          setNotificationIcon(saleImage);
          break;
        case "Reminder":
          setNotificationIcon(reminderImage);
          break;
        case "Update":
          setNotificationIcon(updateImage);
          break;
        case "Content":
          setNotificationIcon(contentImage);
          break;
        case "Clean Box":
          setNotificationIcon(cleanBocImage);
          break;
        case "Other":
          setNotificationIcon(otherImage);
          break;
        default:
          setNotificationIcon(otherImage);
      }
    }
  }, [notification]);
  const dispatch = useDispatch();
  const handleNotification = async () => {
    try {
      const notificationViewed = await articleService.notificationViewed(
        currentProfile.id,
        notification.id
      );

      if (notificationViewed.message === "success") {
        dispatch(setNotificationsRedux(notificationViewed.notifications));
        dispatch(setNotificationPopupOpenRedux(false));
      } else {
        console.log(notificationViewed, "notificationViewed error");
      }
    } catch (err) {
      console.log(err, "error in handleNotification");
    }
  };
  return (
    <>
      <div className="notification-popup-wrapper">
        <div className="notification-popup-main">
          <div
            className="notification-popup-header"
            style={{
              flexDirection: i18n.language === "he" ? "row-reverse" : "row",
            }}
          >
            <img src={notificationIcon} alt="" />
            <span
              onClick={() => dispatch(setNotificationPopupOpenRedux(false))}
            >
              <IconContext.Provider value={{ className: "close-icon" }}>
                <IoMdClose
                  style={{
                    fontSize: "30px",
                    marginBottom: "10px",
                    color: "#206684",
                  }}
                />
              </IconContext.Provider>
            </span>
          </div>
          <div className="notification-popup-body">
            <div
              className="notification"
              style={{
                direction: languageDirection,
                textAlign: languageDirection === "ltr" ? "left" : "right",
              }}
            >
              {notification?.content}
            </div>
            <div className="read-notification">
              <button onClick={handleNotification}>{t("close")}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationPopup;
