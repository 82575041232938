import yellow from "../images/boxes/box_yellow.png";
import green from "../images/boxes/box_dark_green.png";
import darkBlue from "../images/boxes/box_dark_blue.png";
import fuchsia from "../images/boxes/box_fuchsia.png";
import lightBlue from "../images/boxes/box_light_blue.png";
import lightPink from "../images/boxes/box_light_pink.png";
import mediumBlue from "../images/boxes/box_medium_blue.png";
import mediumPink from "../images/boxes/box_medium_pink.png";
import purple from "../images/boxes/box_purple.png";
import red from "../images/boxes/box_red.png";

export const boxImages = {
  101: darkBlue,
  102: mediumBlue,
  103: purple,
  104: lightPink,
  105: mediumPink,
  106: lightBlue,
  107: green,
  108: yellow,
  109: red,
  110: fuchsia,
  111: lightBlue, // sky blue same image as light blue
};
